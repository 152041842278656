import { roleToName } from '@meterup/authorization';
import { z } from 'zod';

import { RoleName } from '../../../gql/graphql';
import { CreateCompanyUserInputSchema } from '../../../gql/zod-types';

function companyRequiredForRole(roleName: RoleName) {
  return z.string({
    required_error: `Company is required for ${roleToName(roleName)}.`,
  });
}

function networkRequiredForRole(roleName: RoleName) {
  return z.string({
    required_error: `Network is required for ${roleToName(roleName)}.`,
  });
}

export const OperatorRoleSchema = z.object({
  name: z.literal(RoleName.Operator),
  isNew: z.boolean().default(false),
});

export const CompanyAdminRoleSchema = z.object({
  name: z.literal(RoleName.CompanyGlobalAdmin),
  companySlug: companyRequiredForRole(RoleName.CompanyGlobalAdmin),
  isNew: z.boolean().default(false),
});

function schemaForRole<T extends RoleName>(roleName: T) {
  return z.object({
    name: z.literal(roleName),
    companySlug: companyRequiredForRole(roleName),
    networkUUID: networkRequiredForRole(roleName).nullish(),
    isNew: z.boolean().default(false),
  });
}

const CompanyNetworkAdminRoleSchema = schemaForRole(RoleName.CompanyNetworkAdmin);

const CompanyNetworkAdminRoleReadonlySchema = schemaForRole(RoleName.CompanyNetworkAdminReadOnly);

const CompanyStandardUserRoleSchema = schemaForRole(RoleName.CompanyStandardUser);

const CompanyGuestRoleSchema = schemaForRole(RoleName.CompanyGuest);

export const UpdateRoleAssignmentsSchema = z
  .discriminatedUnion('name', [
    OperatorRoleSchema,
    CompanyAdminRoleSchema,
    CompanyNetworkAdminRoleSchema,
    CompanyNetworkAdminRoleReadonlySchema,
    CompanyStandardUserRoleSchema,
    CompanyGuestRoleSchema,
  ])
  .refine(
    (val) =>
      !(
        val.name === RoleName.CompanyNetworkAdmin ||
        val.name === RoleName.CompanyStandardUser ||
        val.name === RoleName.CompanyGuest
      ) ||
      val.isNew ||
      val.networkUUID !== '',
    'Network is required for this role.',
  );
export type UpdateRoleAssignmentsSchemaType = z.infer<typeof UpdateRoleAssignmentsSchema>;

export const CompanyUserInputSchema = CreateCompanyUserInputSchema.extend({
  firstName: z.string().nonempty('First name is required.'),
  lastName: z.string().nonempty('Last name is required.'),
  email: z.string().email(),
});
export type CompanyUserInputSchemaType = z.infer<typeof CompanyUserInputSchema>;

type NetworkUUID = string;
export const RolesFormSchema = z
  .object({
    roleAssignments: z.array(UpdateRoleAssignmentsSchema),
  })
  .superRefine((val, ctx) => {
    const { roleAssignments } = val;
    const containsOperator: number[] = [];
    const containsCompanyAdmin: number[] = [];
    const groupedAssignments = new Map<
      NetworkUUID,
      { role: UpdateRoleAssignmentsSchemaType; index: number }[]
    >();
    roleAssignments.forEach((role, index) => {
      if (role.name === RoleName.Operator) {
        containsOperator.push(index);
        return;
      }
      if (role.name === RoleName.CompanyGlobalAdmin) {
        containsCompanyAdmin.push(index);
        return;
      }
      const { networkUUID } = role;
      if (!networkUUID) {
        return;
      }
      if (!groupedAssignments.has(networkUUID)) {
        groupedAssignments.set(networkUUID, []);
      }
      groupedAssignments.get(networkUUID)!.push({ index, role });
    });
    if (containsOperator.length > 0 && roleAssignments.length > 1) {
      containsOperator.forEach((index) => {
        ctx.addIssue({
          message: 'Operator role cannot be combined with other roles.',
          code: z.ZodIssueCode.custom,
          path: ['roleAssignments', `${index}`, 'name'],
        });
      });
      return;
    }
    if (containsCompanyAdmin.length > 0 && roleAssignments.length > 1) {
      containsCompanyAdmin.forEach((index) => {
        ctx.addIssue({
          message: 'Company admin role cannot be combined with other roles.',
          code: z.ZodIssueCode.custom,
          path: ['roleAssignments', `${index}`, 'name'],
        });
      });
      return;
    }
    for (const [networkUUID, assignments] of groupedAssignments) {
      if (assignments.length > 1) {
        assignments.forEach(({ index }) => {
          ctx.addIssue({
            message: `Multiple roles cannot be assigned to a user for the same network.`,
            code: z.ZodIssueCode.custom,
            path: ['roleAssignments', `${index}`, 'networkUUID'],
            params: {
              networkUUID,
            },
          });
        });
      }
    }
  });

export type FormSchemaType = z.infer<typeof RolesFormSchema>;
