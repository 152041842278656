import type { OverlayTriggerState } from '@meterup/atto';
import type { ClientError } from 'graphql-request';
import {
  Alert,
  Badge,
  Button,
  CompositeField,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  FieldContainer,
  HStack,
  Link,
  MultiComboBox,
  MultiComboBoxItem,
  MultiComboBoxSection,
  PrimaryField,
  PrimaryFieldComposite,
  PrimaryToggleField,
  SecondaryField,
  Select,
  SelectItem,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Textarea,
  TextInput,
  useDialogState,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { isPrivateAddress, isValidCIDR, notify, splitCIDR } from '@meterup/common';
import {
  getGraphQLError,
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { z } from 'zod';

import type {
  CreateRateLimitRuleInput,
  RateLimitRuleQuery,
  UpdateRateLimitRuleInput,
} from '../../gql/graphql';
import { MAX_PORT_NUMBER, paths } from '../../constants';
import { graphql } from '../../gql';
import { IpProtocol, PermissionType } from '../../gql/graphql';
import { CreateRateLimitRuleInputSchema } from '../../gql/zod-types';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../utils/withZodSchema';
import { PortRangeField } from '../Firewall/EditFirewallRuleDrawer';
import {
  type UplinkPhyInterface,
  displayIPProtocol,
  getPhyInterfaceLabel,
  protocolSupportsPorts,
  uplinkPhyInterfacesQuery,
} from '../Firewall/utils';
import {
  FieldProvider,
  MultiComboBoxFieldProvider,
  NumberFieldProvider,
} from '../Form/FieldProvider';
import { FormikConditional } from '../FormikConditional';
import { vlanHasStaticIP, vlansQuery } from '../NetworkWide/VLANs/utils';
import IsPermitted from '../permissions/IsPermitted';
import { rateLimitRulesForNetworkQuery } from './RateLimiting';

export const rateLimitRuleQuery = graphql(`
  query RateLimitRule($uuid: UUID!) {
    rateLimitRule(UUID: $uuid) {
      UUID
      name
      description
      rateLimitKbpsDownload
      rateLimitKbpsUpload
      isEnabled
      protocol
      srcMac
      srcPrefix
      srcPortRange {
        lower
        upper
      }
      dstMac
      dstPrefix
      dstPortRange {
        lower
        upper
      }
      isPerSource
      vlanBindings {
        __typename
        UUID
        name
      }
      phyInterfaceBindings {
        __typename
        UUID
        label
        portNumber
        virtualDevice {
          __typename
          UUID
          label
          deviceModel
        }
      }
    }
  }
`);

export const createRateLimitRuleMutation = graphql(`
  mutation CreateRateLimitRule($networkUUID: UUID!, $input: CreateRateLimitRuleInput!) {
    createRateLimitRule(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const updateRateLimitRuleMutation = graphql(`
  mutation UpdateRateLimitRule($uuid: UUID!, $input: UpdateRateLimitRuleInput!) {
    updateRateLimitRule(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteRateLimitRuleMutation = graphql(`
  mutation DeleteRateLimitRule($uuid: UUID!) {
    deleteRateLimitRule(UUID: $uuid) {
      UUID
    }
  }
`);

type RateLimitRule = RateLimitRuleQuery['rateLimitRule'];

enum RateLimitBindingKind {
  VLAN = 'vlan',
  PhyInterface = 'wan',
}

const RATE_LIMIT_MESSAGE = 'Please enter a positive number, or leave blank.';
const PREFIX_LENGTH_MESSAGE = 'Please enter a prefix length between 0 and 32.';
const PORT_RANGE_MESSAGE = `Please enter a port number between 1 and ${MAX_PORT_NUMBER}.`;

const rateLimitRuleFormSchema = CreateRateLimitRuleInputSchema.omit({
  srcMac: true,
  dstMac: true,
  srcPrefix: true,
  dstPrefix: true,
})
  .extend({
    rateLimitKbpsDownload: z
      .number({ invalid_type_error: RATE_LIMIT_MESSAGE })
      .positive({ message: RATE_LIMIT_MESSAGE })
      .nullish()
      .or(z.literal('')),
    rateLimitKbpsUpload: z
      .number({ invalid_type_error: RATE_LIMIT_MESSAGE })
      .positive({ message: RATE_LIMIT_MESSAGE })
      .nullish()
      .or(z.literal('')),
    bindingKind: z.nativeEnum(RateLimitBindingKind),
    srcIPAddress: z
      .string()
      .ip({ message: 'Please provide a valid IP address.' })
      .nullish()
      .or(z.literal('')),
    srcPrefixLength: z
      .number()
      .min(0, { message: PREFIX_LENGTH_MESSAGE })
      .max(32, { message: PREFIX_LENGTH_MESSAGE })
      .nullish()
      .or(z.literal('')),
    dstIPAddress: z
      .string()
      .ip({ message: 'Please provide a valid IP address.' })
      .nullish()
      .or(z.literal('')),
    dstPrefixLength: z
      .number()
      .min(0, { message: PREFIX_LENGTH_MESSAGE })
      .max(32, { message: PREFIX_LENGTH_MESSAGE })
      .nullish()
      .or(z.literal('')),
    srcPortRange: z
      .object({
        lower: z.number().min(1, { message: PORT_RANGE_MESSAGE }).max(MAX_PORT_NUMBER, {
          message: PORT_RANGE_MESSAGE,
        }),
        upper: z.number().min(1, { message: PORT_RANGE_MESSAGE }).max(MAX_PORT_NUMBER, {
          message: PORT_RANGE_MESSAGE,
        }),
      })
      .nullish(),
    dstPortRange: z
      .object({
        lower: z.number().min(1, { message: PORT_RANGE_MESSAGE }).max(MAX_PORT_NUMBER, {
          message: PORT_RANGE_MESSAGE,
        }),
        upper: z.number().min(1, { message: PORT_RANGE_MESSAGE }).max(MAX_PORT_NUMBER, {
          message: PORT_RANGE_MESSAGE,
        }),
      })
      .nullish(),
  })
  .superRefine(({ rateLimitKbpsDownload, rateLimitKbpsUpload }, ctx) => {
    if (
      (rateLimitKbpsDownload == null || typeof rateLimitKbpsDownload !== 'number') &&
      (rateLimitKbpsUpload == null || typeof rateLimitKbpsUpload !== 'number')
    ) {
      for (const path of ['rateLimitKbpsDownload', 'rateLimitKbpsUpload']) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please specify at least one rate limit direction.',
          path: [path],
        });
      }
    }
  })
  .refine(
    ({ bindingKind, srcIPAddress }) =>
      bindingKind === RateLimitBindingKind.VLAN || !srcIPAddress || !isPrivateAddress(srcIPAddress),
    {
      message: 'Source address for WAN bindings must not be a private IPv4 address.',
      path: ['srcIPAddress'],
    },
  )
  .refine(
    ({ srcIPAddress, srcPrefixLength }) =>
      (!srcIPAddress && !srcPrefixLength) ||
      (srcIPAddress &&
        srcPrefixLength != null &&
        typeof srcPrefixLength === 'number' &&
        isValidCIDR(srcIPAddress, srcPrefixLength)),
    {
      message: 'Invalid prefix length for IP address.',
      path: ['srcPrefixLength'],
    },
  )
  .refine(
    ({ dstIPAddress, dstPrefixLength }) =>
      (!dstIPAddress && !dstPrefixLength) ||
      (dstIPAddress &&
        dstPrefixLength != null &&
        typeof dstPrefixLength === 'number' &&
        isValidCIDR(dstIPAddress, dstPrefixLength)),
    {
      message: 'Invalid prefix length for IP address.',
      path: ['dstPrefixLength'],
    },
  )
  .refine(
    ({ bindingKind, boundVLANUUIDs }) =>
      bindingKind !== RateLimitBindingKind.VLAN || !!boundVLANUUIDs?.length,
    {
      message: 'Please select a VLAN.',
      path: ['boundVLANUUIDs'],
    },
  )
  .refine(
    ({ bindingKind, boundPhyInterfaceUUIDs }) =>
      bindingKind !== RateLimitBindingKind.PhyInterface || !!boundPhyInterfaceUUIDs?.length,
    {
      message: 'Please select a WAN.',
      path: ['boundPhyInterfaceUUIDs'],
    },
  );

const schemaValidator = withZodSchema(rateLimitRuleFormSchema);

export type RateLimitRuleFormValues = z.input<typeof rateLimitRuleFormSchema>;

function DeleteRateLimitRuleDialog({
  rule,
  state,
}: {
  rule: RateLimitRule;
  state: OverlayTriggerState;
}) {
  const network = useNetwork();
  const [error, setError] = useState<ClientError | null>(null);
  const closeDrawer = useCloseDrawerCallback();

  const deleteRule = useGraphQLMutation(deleteRateLimitRuleMutation);
  const { mutate } = deleteRule;
  const { close } = state;

  const queryClient = useQueryClient();

  const handleDelete = useCallback(() => {
    setError(null);

    mutate(
      { uuid: rule.UUID },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            makeQueryKey(rateLimitRulesForNetworkQuery, { networkUUID: network.UUID }),
          );
          setError(null);
          closeDrawer();
          close();
          notify('Rule deleted successfully', {
            variant: 'positive',
          });
        },
        onError: (err) => {
          setError(err);
        },
      },
    );
  }, [mutate, rule?.UUID, close, network.UUID, queryClient, closeDrawer]);

  const graphqlError = useMemo(() => (error ? getGraphQLError(error) : undefined), [error]);

  if (!rule) return null;

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader icon="trash-can" heading="Delete rate limiting rule" />
      <DialogContent gutter="all">
        <Alert
          icon="information"
          variant="neutral"
          copy={<>You're about to remove a rate limit rule from your Meter network.</>}
        />

        {error && (
          <Alert
            icon="warning"
            variant="negative"
            copy={
              <>
                There was an error deleting the firewall rule
                {graphqlError?.message && <>: {graphqlError.message}</>}.
              </>
            }
          />
        )}
        <SummaryList gutter="vertical">
          {!!rule.vlanBindings?.length && (
            <SummaryListRow>
              <SummaryListKey>Affected VLAN{rule.vlanBindings.length > 1 && 's'}</SummaryListKey>
              <SummaryListValue>
                <HStack spacing={space(6)} wrap="wrap">
                  {rule.vlanBindings.map((binding) => (
                    <Badge variant="neutral" size="small" icon="vlan" arrangement="leading-icon">
                      {binding.name}
                    </Badge>
                  ))}
                </HStack>
              </SummaryListValue>
            </SummaryListRow>
          )}
          {!!rule.phyInterfaceBindings?.length && (
            <SummaryListRow>
              <SummaryListKey>
                Affected WAN{rule.phyInterfaceBindings.length > 1 && 's'}
              </SummaryListKey>
              <SummaryListValue>
                <HStack spacing={space(6)} wrap="wrap">
                  {rule.phyInterfaceBindings.map((phyInterface) => (
                    <Badge variant="neutral" size="small" icon="globe" arrangement="leading-icon">
                      {getPhyInterfaceLabel(phyInterface)}
                    </Badge>
                  ))}
                </HStack>
              </SummaryListValue>
            </SummaryListRow>
          )}
        </SummaryList>
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button onClick={close} variant="secondary">
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="destructive">
              Delete
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

/* eslint-disable no-param-reassign */
function assignInput<T extends UpdateRateLimitRuleInput | CreateRateLimitRuleInput>(
  input: T,
  {
    isPerSource,
    protocol,
    srcIPAddress,
    srcPrefixLength,
    srcPortRange,
    dstIPAddress,
    dstPrefixLength,
    dstPortRange,
    boundVLANUUIDs,
    boundPhyInterfaceUUIDs,
    bindingKind,
  }: RateLimitRuleFormValues,
): T {
  switch (bindingKind) {
    case RateLimitBindingKind.VLAN:
      input.boundPhyInterfaceUUIDs = null;
      input.boundVLANUUIDs = boundVLANUUIDs;
      break;
    case RateLimitBindingKind.PhyInterface:
      input.boundVLANUUIDs = null;
      input.boundPhyInterfaceUUIDs = boundPhyInterfaceUUIDs;
      break;
  }

  // Per-source limiting is only available on VLANs
  if (bindingKind === RateLimitBindingKind.VLAN && isPerSource) {
    input.isPerSource = true;
    input.protocol = null;
    input.srcMac = null;
    input.srcPrefix = null;
    input.srcPrefix = null;
    input.dstMac = null;
    input.dstPrefix = null;
    input.dstPrefix = null;
  } else {
    input.isPerSource = false;

    if (srcIPAddress && srcPrefixLength != null && typeof srcPrefixLength === 'number') {
      input.srcPrefix = `${srcIPAddress}/${srcPrefixLength}`;
    }

    if (dstIPAddress && dstPrefixLength != null && typeof dstPrefixLength === 'number') {
      input.dstPrefix = `${dstIPAddress}/${dstPrefixLength}`;
    }

    input.protocol = protocol;

    if (protocol && protocolSupportsPorts(protocol)) {
      input.srcPortRange = srcPortRange;
      input.dstPortRange = dstPortRange;
    }
  }

  return input;
}
/* eslint-enable no-param-reassign */

function bindingKindForRateLimitRule(rule: RateLimitRule): RateLimitBindingKind | null {
  if (rule.vlanBindings?.length) return RateLimitBindingKind.VLAN;
  if (rule.phyInterfaceBindings?.length) return RateLimitBindingKind.PhyInterface;

  return null;
}

function BindingField() {
  const { values, setFieldValue } = useFormikContext<RateLimitRuleFormValues>();
  const network = useNetwork();
  const isOperator = useIsOperator();

  useEffect(() => {
    if (values.bindingKind === RateLimitBindingKind.PhyInterface) {
      setFieldValue('isPerSource', false);
    }
  }, [values.bindingKind, setFieldValue]);

  const vlans = useGraphQL(vlansQuery, { networkUUID: network.UUID }).data?.vlans;

  const vlanOptions = useMemo(
    () =>
      vlans
        ?.filter((vlan) => vlanHasStaticIP(vlan) && (isOperator || !vlan.isInternal))
        .sort((a, b) => a.name.localeCompare(b.name)) ?? [],
    [vlans, isOperator],
  );

  const uplinkPhyInterfaces = useGraphQL(uplinkPhyInterfacesQuery, { networkUUID: network.UUID })
    .data?.uplinkPhyInterfacesForNetwork;

  const groupedUplinkPhyInterfaces = useMemo(() => {
    const map = new Map<string, UplinkPhyInterface[]>();
    if (!uplinkPhyInterfaces) return map;

    for (const pi of uplinkPhyInterfaces) {
      let arr = map.get(pi.virtualDevice.label);
      if (!arr) {
        arr = [];
        map.set(pi.virtualDevice.label, arr);
      }

      arr.push(pi);
    }

    return map;
  }, [uplinkPhyInterfaces]);

  return (
    <PrimaryFieldComposite
      label="Bindings"
      fields={
        <>
          <FormikConditional<RateLimitRuleFormValues>
            condition={({ bindingKind }) => bindingKind === RateLimitBindingKind.VLAN}
          >
            <FieldContainer>
              <MultiComboBoxFieldProvider name="boundVLANUUIDs">
                <CompositeField
                  label="Bound VLANs"
                  element={
                    <MultiComboBox placeholder="Select VLANs">
                      {vlanOptions.map((vlan) => (
                        <MultiComboBoxItem key={vlan.UUID} textValue={vlan.name}>
                          {vlan.name}
                          {vlan.isInternal && (
                            <>
                              {' '}
                              <Badge size="small">Internal</Badge>
                            </>
                          )}
                        </MultiComboBoxItem>
                      ))}
                    </MultiComboBox>
                  }
                />
              </MultiComboBoxFieldProvider>
            </FieldContainer>
          </FormikConditional>
          <FormikConditional<RateLimitRuleFormValues>
            condition={({ bindingKind }) => bindingKind === RateLimitBindingKind.PhyInterface}
          >
            <FieldContainer>
              <MultiComboBoxFieldProvider name="boundPhyInterfaceUUIDs">
                <CompositeField
                  label="Bound WANs"
                  element={
                    <MultiComboBox placeholder="Select WANs">
                      {Array.from(groupedUplinkPhyInterfaces.entries())
                        .sort(([a], [b]) => a.localeCompare(b))
                        .map(([virtualDevice, phyInterfaces]) => (
                          <MultiComboBoxSection title={virtualDevice}>
                            {phyInterfaces.map((pi) => (
                              <MultiComboBoxItem
                                key={pi.UUID}
                                textValue={getPhyInterfaceLabel(pi, false)}
                              >
                                {getPhyInterfaceLabel(pi, false)}
                              </MultiComboBoxItem>
                            ))}
                          </MultiComboBoxSection>
                        ))}
                    </MultiComboBox>
                  }
                />
              </MultiComboBoxFieldProvider>
            </FieldContainer>
          </FormikConditional>
        </>
      }
      controls={
        <FieldProvider name="bindingKind">
          <CompositeField
            label="Binding"
            element={
              <Select>
                <SelectItem key={RateLimitBindingKind.VLAN}>VLAN</SelectItem>
                <SelectItem key={RateLimitBindingKind.PhyInterface}>WAN</SelectItem>
              </Select>
            }
          />
        </FieldProvider>
      }
    />
  );
}

export default function EditRateLimitRuleDrawer({ rule }: { rule?: RateLimitRule }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const closeDrawer = useCloseDrawerCallback();

  const { state } = useDialogState();

  const isPerSourceSupported = useNosFeatureEnabled(NosFeature.RATE_LIMITING_PER_SOURCE);

  const createRuleMutation = useGraphQLMutation(createRateLimitRuleMutation);
  const updateRuleMutation = useGraphQLMutation(updateRateLimitRuleMutation);

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (formValues: RateLimitRuleFormValues) => {
      const {
        isPerSource,
        protocol,
        srcIPAddress,
        srcPrefixLength,
        srcPortRange,
        dstIPAddress,
        dstPrefixLength,
        dstPortRange,
        boundVLANUUIDs,
        boundPhyInterfaceUUIDs,
        bindingKind,
        rateLimitKbpsDownload,
        rateLimitKbpsUpload,
        ...values
      } = formValues;
      if (rule) {
        const input: UpdateRateLimitRuleInput = {
          ...values,
          protocol: null,
          srcMac: null,
          srcPrefix: null,
          srcPortRange: null,
          dstMac: null,
          dstPrefix: null,
          dstPortRange: null,
          rateLimitKbpsDownload: rateLimitKbpsDownload || null,
          rateLimitKbpsUpload: rateLimitKbpsUpload || null,
        };

        assignInput(input, formValues);

        updateRuleMutation.mutate(
          {
            uuid: rule.UUID,
            input,
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(
                makeQueryKey(rateLimitRulesForNetworkQuery, { networkUUID: network.UUID }),
              );
              queryClient.invalidateQueries(makeQueryKey(rateLimitRuleQuery, { uuid: rule.UUID }));
              notify('Successfully updated rate limit rule', {
                variant: 'positive',
              });
            },
            onError: (err) => {
              notify(
                `There was a problem updating the rate limit rule${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      } else {
        const input: CreateRateLimitRuleInput = {
          ...values,
          protocol: null,
          srcMac: null,
          srcPrefix: null,
          srcPortRange: null,
          dstMac: null,
          dstPrefix: null,
          dstPortRange: null,
          rateLimitKbpsDownload: rateLimitKbpsDownload || null,
          rateLimitKbpsUpload: rateLimitKbpsUpload || null,
        };

        assignInput(input, formValues);

        createRuleMutation.mutate(
          {
            networkUUID: network.UUID,
            input,
          },
          {
            onSuccess: (result) => {
              queryClient.invalidateQueries(
                makeQueryKey(rateLimitRulesForNetworkQuery, { networkUUID: network.UUID }),
              );
              navigate(
                makeDrawerLink(window.location, paths.drawers.EditRateLimitRulePage, {
                  ruleUUID: result.createRateLimitRule.UUID,
                  companyName,
                  networkSlug: network.slug,
                }),
              );
              notify('Successfully created rate limit rule', {
                variant: 'positive',
              });
            },
            onError: (err) => {
              notify(
                `There was a problem creating the rate limit rule${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      }
    },
    [
      rule,
      queryClient,
      network.UUID,
      createRuleMutation,
      updateRuleMutation,
      network.slug,
      companyName,
      navigate,
    ],
  );

  const srcPrefixPieces = rule && rule.srcPrefix ? splitCIDR(rule.srcPrefix) : null;
  const dstPrefixPieces = rule && rule.dstPrefix ? splitCIDR(rule.dstPrefix) : null;

  return (
    <Drawer>
      <Formik<RateLimitRuleFormValues>
        initialValues={{
          bindingKind: rule
            ? bindingKindForRateLimitRule(rule) ?? RateLimitBindingKind.VLAN
            : RateLimitBindingKind.VLAN,
          name: rule?.name ?? '',
          description: rule?.description ?? '',
          isEnabled: rule?.isEnabled ?? true,
          isPerSource: rule?.isPerSource ?? false,
          protocol: rule?.protocol ?? IpProtocol.All,
          srcIPAddress: srcPrefixPieces?.[0] ?? '',
          srcPrefixLength: srcPrefixPieces?.[1] ?? '',
          srcPortRange: rule?.srcPortRange ?? {
            lower: 1,
            upper: MAX_PORT_NUMBER,
          },
          dstIPAddress: dstPrefixPieces?.[0] ?? '',
          dstPrefixLength: dstPrefixPieces?.[1] ?? '',
          dstPortRange: rule?.dstPortRange ?? {
            lower: 1,
            upper: MAX_PORT_NUMBER,
          },
          rateLimitKbpsDownload: rule?.rateLimitKbpsDownload ?? '',
          rateLimitKbpsUpload: rule?.rateLimitKbpsUpload ?? '',
          boundVLANUUIDs: rule?.vlanBindings?.map((v) => v.UUID) ?? [],
          boundPhyInterfaceUUIDs: rule?.phyInterfaceBindings?.map((pi) => pi.UUID) ?? [],
        }}
        validate={schemaValidator}
        onSubmit={handleSubmit}
      >
        <Form>
          <DrawerHeader
            icon="access-control"
            heading={`${rule ? 'Edit' : 'Add'} rate limit rule`}
            actions={
              <IsPermitted
                isPermitted={({ permissions, ldFlags, nosFlags }) =>
                  Boolean(
                    permissions.hasPermission(PermissionType.PermRateLimitWrite) &&
                      ldFlags['rate-limiting'] &&
                      nosFlags[NosFeature.COS2] &&
                      nosFlags[NosFeature.RATE_LIMITING],
                  )
                }
              >
                <DropdownMenu>
                  <DropdownMenuButton
                    variant="secondary"
                    icon="overflow-horizontal"
                    arrangement="hidden-label"
                  >
                    Actions
                  </DropdownMenuButton>
                  <DropdownMenuPopover align="end">
                    <DropdownMenuGroup>
                      <DropdownMenuItem icon="trash-can" onSelect={state.open}>
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuPopover>
                </DropdownMenu>
              </IsPermitted>
            }
            onClose={closeDrawer}
          />
          <DrawerContent>
            <FieldContainer>
              <FieldProvider name="isEnabled">
                <PrimaryToggleField label="Enable" />
              </FieldProvider>
            </FieldContainer>
            <FieldContainer>
              <FieldProvider name="name">
                <PrimaryField label="Name" element={<TextInput />} />
              </FieldProvider>
            </FieldContainer>
            <FieldContainer>
              <FieldProvider name="description">
                <PrimaryField optional label="Description" element={<Textarea />} />
              </FieldProvider>
            </FieldContainer>

            <FieldContainer>
              <PrimaryField label="Limit" element={null} />
              <NumberFieldProvider name="rateLimitKbpsDownload" defaultValue={null}>
                <SecondaryField
                  label="Download"
                  element={<TextInput suffix="Kbps" width="8rem" />}
                  optional
                />
              </NumberFieldProvider>
              <NumberFieldProvider name="rateLimitKbpsUpload" defaultValue={null}>
                <SecondaryField
                  label="Upload"
                  element={<TextInput suffix="Kbps" width="8rem" />}
                  optional
                />
              </NumberFieldProvider>
            </FieldContainer>

            <BindingField />

            <FormikConditional<RateLimitRuleFormValues>
              condition={(values) =>
                values.bindingKind === RateLimitBindingKind.VLAN && isPerSourceSupported
              }
            >
              <FieldContainer>
                <FieldProvider name="isPerSource">
                  <PrimaryToggleField
                    label="Limit per client"
                    description="If set, each client (source) will be limited to the specified rate. Otherwise, the maximum rate is shared between all clients."
                  />
                </FieldProvider>
              </FieldContainer>
            </FormikConditional>

            <FieldContainer>
              <FieldProvider name="protocol">
                <PrimaryField
                  label="Protocol"
                  element={
                    <Select width="100%">
                      {Object.values(IpProtocol).map((protocol) => (
                        <SelectItem key={protocol} textValue={displayIPProtocol(protocol)}>
                          {displayIPProtocol(protocol)}
                        </SelectItem>
                      ))}
                    </Select>
                  }
                />
              </FieldProvider>
            </FieldContainer>

            <FieldContainer>
              <FormikConditional<RateLimitRuleFormValues>
                condition={(values) => values.bindingKind === RateLimitBindingKind.VLAN}
              >
                <PrimaryField
                  label="Internal"
                  description="Local client subnet to limit traffic from."
                  element={null}
                  optional
                />
              </FormikConditional>
              <FormikConditional<RateLimitRuleFormValues>
                condition={(values) => values.bindingKind === RateLimitBindingKind.PhyInterface}
              >
                <PrimaryField
                  label="WAN source"
                  description={
                    <>
                      Source WAN subnet to limit traffic from, useful if WANs have multiple
                      addresses. If specified, must not be a{' '}
                      <Link
                        href="https://en.wikipedia.org/wiki/Private_network#Private_IPv4_addresses"
                        target="_blank"
                      >
                        private address
                      </Link>
                      .
                    </>
                  }
                  element={null}
                  optional
                />
              </FormikConditional>
              <FieldProvider name="srcIPAddress">
                <SecondaryField label="IP address" element={<TextInput width="120px" />} />
              </FieldProvider>
              <NumberFieldProvider name="srcPrefixLength" defaultValue={null}>
                <SecondaryField label="Prefix length" element={<TextInput width="40px" />} />
              </NumberFieldProvider>
              <PortRangeField name="srcPortRange" />
            </FieldContainer>

            <FieldContainer>
              <PrimaryField
                label="External"
                description="Remote destination subnet to limit traffic to."
                element={null}
                optional
              />
              <FieldProvider name="dstIPAddress">
                <SecondaryField label="IP address" element={<TextInput width="120px" />} />
              </FieldProvider>
              <NumberFieldProvider name="dstPrefixLength" defaultValue={null}>
                <SecondaryField label="Prefix length" element={<TextInput width="40px" />} />
              </NumberFieldProvider>
              <PortRangeField name="dstPortRange" />
            </FieldContainer>
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button onClick={closeDrawer} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Save
                </Button>
              </>
            }
          />
        </Form>
      </Formik>
      {rule && <DeleteRateLimitRuleDialog state={state} rule={rule} />}
    </Drawer>
  );
}
