import type { OverlayTriggerState } from '@meterup/atto';
import { Button, Dialog, DialogContent, DialogFooter, DialogHeader } from '@meterup/atto';

import type { LANPingProps } from './LANPingTest';
import LANPingTest from './LANPingTest';

type LANPingDialogProps = {
  state: OverlayTriggerState;
} & LANPingProps;

export default function LANPingTestDialog({ state, hostIp, hostName, vlan }: LANPingDialogProps) {
  return (
    <Dialog state={state} width="500px">
      <DialogHeader icon="ping" heading="Ping test" />
      <DialogContent gutter="all">
        <LANPingTest hostIp={hostIp} hostName={hostName} vlan={vlan} />
      </DialogContent>
      <DialogFooter
        actions={
          <Button variant="secondary" onClick={state.close}>
            Close
          </Button>
        }
      />
    </Dialog>
  );
}
