import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow } from '@meterup/common';

import AddUser from '../../../../../components/Users/AddUser';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/settings/org/users/add',
  title: 'Add user - Users - Org',
});

export default function CreateUserPage() {
  const { companyName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.UserDrawerPage),
  );

  return <AddUser companySlug={companyName} />;
}
