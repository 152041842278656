import type { OverlayTriggerState } from '@meterup/atto';
import {
  Body,
  Button,
  CopyBox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  EmptyState,
  FieldContainer,
  Heading,
  PrimaryField,
  space,
  TextInput,
  VStack,
} from '@meterup/atto';
import { useGraphQLMutation } from '@meterup/graphql';
import { Form, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { z } from 'zod';

import { graphql } from '../../gql';
import { withZodSchema } from '../../utils/withZodSchema';
import { FieldProvider } from '../Form/FieldProvider';

const SignConsoleChallengeMutation = graphql(`
  mutation SignConsoleChallengeMutation($serial: String!, $challenge: String!) {
    signConsoleLoginChallenge(serialNumber: $serial, challenge: $challenge) {
      response
    }
  }
`);

type ConsoleAccessDialogProps = {
  state: OverlayTriggerState;
  serialNumber: string;
};

const challengeFormSchema = z.object({
  challenge: z.string().nonempty({ message: 'Please input a challenge.' }),
});
export type ChallengeFormValues = z.input<typeof challengeFormSchema>;

// on load focus to textinput
export default function ConsoleAccessDialog({ state, serialNumber }: ConsoleAccessDialogProps) {
  const { mutate, isLoading, isError } = useGraphQLMutation(SignConsoleChallengeMutation);
  const [signedResult, setSignedResult] = useState<String | undefined>();

  useEffect(() => {
    if (state.isOpen === false) {
      setSignedResult(undefined);
    }
  }, [state.isOpen]);

  const handleSign = useCallback(
    (values: ChallengeFormValues) => {
      setSignedResult(undefined);
      mutate(
        { serial: serialNumber, challenge: values.challenge },
        {
          onSuccess: (data) => {
            setSignedResult(data.signConsoleLoginChallenge.response);
          },
          onError() {
            setSignedResult('Error issuing signing mutation');
          },
        },
      );
    },
    [serialNumber, mutate],
  );

  return (
    <Dialog state={state}>
      <DialogHeader icon="ping" heading="Console Access" />
      <DialogContent>
        {serialNumber == null ? (
          <EmptyState icon="warning" heading="No hardware device" />
        ) : (
          <VStack spacing={space(16)}>
            <Body as="p">
              Paste the challenge presented by the device. The device expires challenges
              periodically, so if this doesn't unlock console, then use the latest challenge from
              the device. The backend signes the challenge and responds with a signature. Copy and
              paste the signature to the device's console.
            </Body>
            <Body as="p">Device serial: {serialNumber}</Body>
            <Formik<ChallengeFormValues>
              initialValues={{
                challenge: '',
              }}
              validate={withZodSchema(challengeFormSchema)}
              onSubmit={handleSign}
            >
              <Form>
                <FieldContainer>
                  <FieldProvider name="challenge">
                    <PrimaryField label="Challenge" element={<TextInput />} />
                  </FieldProvider>
                </FieldContainer>
                <Button
                  type="submit"
                  arrangement="leading-icon"
                  variant="secondary"
                  size="large"
                  disabled={isLoading}
                >
                  Sign
                </Button>
              </Form>
            </Formik>
            {!isError && signedResult && (
              <VStack spacing={space(16)}>
                <Heading>Signature</Heading>
                <CopyBox
                  aria-label="Copy signature to clipboard"
                  relation="stacked"
                  value={signedResult}
                >
                  <Body family="monospace">{signedResult}</Body>
                </CopyBox>
              </VStack>
            )}
          </VStack>
        )}
      </DialogContent>
      <DialogFooter
        actions={
          <Button variant="secondary" onClick={state.close}>
            Close
          </Button>
        }
      />
    </Dialog>
  );
}
