/* eslint-disable react/no-unstable-nested-components */
import type { IconName } from '@meterup/atto';
import type { OnChangeFn, RowSelectionState, SortingState } from '@tanstack/table-core';
import {
  Badge,
  EmptyState,
  HStack,
  Icon,
  PaneContent,
  PaneHeader,
  space,
  Text,
} from '@meterup/atto';
import { AutoTable, isDefinedAndNotEmpty } from '@meterup/common';
import { capitalize } from 'lodash-es';
import { useCallback, useMemo, useRef, useState } from 'react';

import type { NetworkForFilter } from './hooks/useNetworksByFilter';
import type { NosFirmwareVersionsHookValues } from './hooks/useNosFirmwareVersions';
import type { NetworkByFilter } from './utils';
import { paths } from '../../constants';
import { CompanySupportTier, DeviceType } from '../../gql/graphql';
import { supportTierWeight } from '../../routes/pages/operator/companies/utils';
import { makeLink } from '../../utils/main_and_drawer_navigation';
import { formatTimestamp } from '../Devices/utils';
import { NoValue } from '../NoValue';
import { createColumnBuilder } from '../Table/createColumnBuilder';
import { createMultiRowSelector } from '../Table/createMultiRowSelector';
import { nosVersionToLabel, useNosFirmwareVersions } from './hooks/useNosFirmwareVersions';

const builder = createColumnBuilder<NetworkByFilter>();
const checkboxColumn = createMultiRowSelector<NetworkByFilter>();

function virtualDevicesToModelsMap(
  deviceType: DeviceType,
  virtualDevices: NetworkForFilter['virtualDevices'],
) {
  const modelsMap = new Map<string, number>();
  for (const device of virtualDevices) {
    if (!device.hardwareDevice) {
      continue;
    }
    const currDeviceType = device.hardwareDevice?.deviceType;
    const currDeviceModel = device.hardwareDevice?.deviceModel;
    if (currDeviceType === deviceType && currDeviceModel) {
      let count = modelsMap.get(currDeviceModel);
      if (count) {
        modelsMap.set(currDeviceModel, count + 1);
      } else {
        count = 1;
        modelsMap.set(currDeviceModel, count);
      }
    }
  }
  return modelsMap;
}

function countDevicesWithHardware(
  virtualDevices: NetworkForFilter['virtualDevices'],
  deviceType: DeviceType,
) {
  return (
    virtualDevices?.filter(
      (device) => device.hardwareDevice && device.hardwareDevice.deviceType === deviceType,
    ).length ?? 0
  );
}

type BuildDeviceTypeOpts = {
  deviceType: DeviceType;
  id: string;
  header: string;
  icon: IconName;
};
function buildDeviceModelCol({ deviceType, id, header, icon }: BuildDeviceTypeOpts) {
  return builder.data((row) => countDevicesWithHardware(row.virtualDevices, deviceType), {
    id,
    header,
    meta: {
      tooltip: {
        contents: `The connected ${header} and number of devices on the network with that model.`,
      },
    },
    cell: ({ row }) => {
      const modelsMap = virtualDevicesToModelsMap(deviceType, row?.virtualDevices);
      const models = Array.from(modelsMap.entries()).map(([deviceModel, count]) => ({
        deviceModel,
        count,
      }));
      if (models.length) {
        return (
          <HStack spacing={space(4)}>
            {models.map(({ deviceModel, count }) => (
              <Badge
                key={deviceModel}
                icon={icon}
                arrangement="leading-icon"
                size="small"
                ends="card"
                variant="neutral"
              >
                {deviceModel}: {count}
              </Badge>
            ))}
          </HStack>
        );
      }
      return <NoValue />;
    },
  });
}

export function NosVersionCell({
  value,
  nosVersionIdMap,
}: {
  value?: number | null;
  nosVersionIdMap: NosFirmwareVersionsHookValues['nosVersionIdMap'];
}) {
  if (value) {
    const nos = nosVersionIdMap[value];
    return (
      <Text family="monospace" size="12">
        {nosVersionToLabel(nos)}
      </Text>
    );
  }
  return <NoValue />;
}

function StringCell({ value }: { value?: string | null }) {
  if (value) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{value}</>;
  }
  return <NoValue />;
}

export function TimestampCell({ value }: { value?: string | null }) {
  if (value) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{formatTimestamp(value)}</>;
  }
  return <NoValue />;
}

export function NetworksPreviewList({
  networks,
  onRowMultiSelectionChange,
  multiSelectedRows,
}: {
  networks: NetworkByFilter[];
  onRowMultiSelectionChange?: OnChangeFn<RowSelectionState>;
  multiSelectedRows?: RowSelectionState;
}) {
  const tableContainerRef = useRef(null);
  const { nosVersionIdMap } = useNosFirmwareVersions();
  const [sortingState, setSortingState] = useState<SortingState>([
    { id: 'companyName', desc: false },
  ]);

  const linkTo = useCallback((row: NetworkByFilter) => {
    if (row?.companySlug) {
      return makeLink(paths.pages.IndividualNetworkRootPage, {
        networkSlug: row.slug,
        companyName: row.companySlug,
      });
    }
    return undefined;
  }, []);

  const columns = useMemo(
    () => [
      ...(onRowMultiSelectionChange && multiSelectedRows ? [builder.display(checkboxColumn)] : []),
      builder.data((network) => `${network.isActive ? 'Active' : 'Inactive'}`, {
        id: 'isActive',
        header: () => <Icon icon="question" size={space(16)} />,
        meta: {
          alignment: 'center',
          width: 48,
          tooltip: {
            contents: 'Active',
          },
        },
        cell: (network) => (
          <Badge
            arrangement="hidden-label"
            variant={network.row.isActive ? 'positive' : 'neutral'}
            icon={network.row.isActive ? 'checkmark' : 'cross'}
            size="small"
            ends="pill"
          >
            {network.row.isActive ? 'Active' : 'Inactive'}
          </Badge>
        ),
      }),
      builder.data(
        (network) =>
          `${network.isUpgradeSensitive ? 'Upgrade sensitive' : 'Not upgrade sensitive'}`,
        {
          id: 'isUpgradeSensitive',
          header: () => <Icon icon="upgrading" size={space(16)} />,
          meta: {
            alignment: 'center',
            width: 48,
            tooltip: {
              contents: 'Upgrade sensitive',
            },
          },
          cell: (network) => (
            <Badge
              arrangement="hidden-label"
              variant={network.row.isUpgradeSensitive ? 'positive' : 'neutral'}
              icon={network.row.isUpgradeSensitive ? 'checkmark' : 'cross'}
              size="small"
              ends="pill"
            >
              {network.row.isUpgradeSensitive ? 'Active' : 'Inactive'}
            </Badge>
          ),
        },
      ),
      builder.data((network) => network.company?.name, {
        id: 'companyName',
        header: 'Company',
        meta: {
          width: 200,
        },
        cell: StringCell,
      }),
      builder.data((network) => network.label, {
        id: 'label',
        header: 'Network',
        meta: {
          isLeading: true,
          width: 300,
        },
        cell: StringCell,
      }),
      builder.data((network) => network.lastUpgradedAt, {
        id: 'lastUpgradedAt',
        header: 'Last upgraded at',
        meta: {
          width: 300,
        },
        cell: TimestampCell,
      }),
      builder.data((network) => network.pinnedNOSVersionID, {
        id: 'pinnedNOSVersion',
        header: 'Current NOS version',
        meta: {
          width: 260,
        },
        cell: ({ value }) => <NosVersionCell value={value} nosVersionIdMap={nosVersionIdMap} />,
      }),
      builder.data((network) => network.pendingNOSVersionID, {
        id: 'pendingNOSVersion',
        header: 'Pending NOS version',
        meta: {
          width: 260,
        },
        cell: ({ value }) => <NosVersionCell value={value} nosVersionIdMap={nosVersionIdMap} />,
      }),
      builder.data((row) => supportTierWeight(row.company?.supportTier).toString(), {
        id: 'tier',
        header: 'Support tier',
        meta: {
          width: 128,
        },
        cell: ({ row }) => {
          if (isDefinedAndNotEmpty(row.company?.supportTier)) {
            let variant: 'positive' | 'brand' | 'attention' | 'negative' = 'positive';
            switch (row.company.supportTier) {
              case CompanySupportTier.Low:
                variant = 'positive';
                break;
              case CompanySupportTier.Medium:
                variant = 'brand';
                break;
              case CompanySupportTier.High:
                variant = 'attention';
                break;
              case CompanySupportTier.Critical:
                variant = 'negative';
                break;
            }
            return (
              <Badge ends="pill" variant={variant} size="small">
                {capitalize(row.company.supportTier)}
              </Badge>
            );
          }
          return <NoValue />;
        },
      }),
      builder.data((row) => row.mailingAddress?.squareFeet, {
        id: 'square-feet',
        header: 'Square feet',
        meta: {
          width: 128,
        },
        cell: ({ value }) => {
          if (isDefinedAndNotEmpty(value)) {
            return value;
          }
          return <NoValue />;
        },
      }),
      buildDeviceModelCol({
        deviceType: DeviceType.AccessPoint,
        id: 'accessPointsModels',
        header: 'AP models',
        icon: 'access-point',
      }),
      buildDeviceModelCol({
        deviceType: DeviceType.Switch,
        id: 'switchModels',
        header: 'Switch models',
        icon: 'switch',
      }),
      buildDeviceModelCol({
        deviceType: DeviceType.Controller,
        id: 'controllerModels',
        header: 'Controller models',
        icon: 'security-appliance',
      }),
      buildDeviceModelCol({
        deviceType: DeviceType.PowerDistributionUnit,
        id: 'pduModels',
        header: 'PDU models',
        icon: 'pdu',
      }),
    ],
    [nosVersionIdMap, multiSelectedRows, onRowMultiSelectionChange],
  );

  return networks?.length ? (
    <>
      <PaneHeader heading="Networks to be upgraded" />
      <PaneContent ref={tableContainerRef}>
        <AutoTable
          sortingState={sortingState}
          onChangeSortingState={setSortingState}
          onRowMultiSelectionChange={onRowMultiSelectionChange}
          enableMultiRowSelection
          multiSelectedRows={multiSelectedRows}
          isVirtual
          tableContainerRef={tableContainerRef}
          columns={columns}
          getLinkTo={linkTo}
          data={networks}
          getRowId={(r) => r.UUID}
        />
      </PaneContent>
    </>
  ) : (
    <EmptyState icon="filter" heading="Your filter returned no results" />
  );
}
