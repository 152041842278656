import type { OverlayTriggerState } from '@meterup/atto';
import {
  Alert,
  Body,
  Button,
  colors,
  CopyBox,
  darkThemeSelector,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  Icon,
  space,
  Stats,
  styled,
  Text,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import {
  getGraphQLError,
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';

import type {
  CreateDhcpStaticMappingInput,
  UpdateDhcpStaticMappingInput,
} from '../../../gql/graphql';
import type { NetworkClient } from '../../../hooks/networkClients/useNetworkClients';
import type { VLAN } from '../../NetworkWide/VLANs/utils';
import type { DHCPStaticMappingValues } from '../../NetworkWide/VLANs/VLANDetails/DHCPDetails';
import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeaturesEnabled } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../utils/withZodSchema';
import { rpcDisconnectClientMutation } from '../../Clients/utils';
import { TextField } from '../../Form/Fields';
import {
  createDHCPStaticMapping,
  deleteDHCPStaticMapping,
  updateDHCPStaticMapping,
  vlanQuery,
} from '../../NetworkWide/VLANs/utils';
import { dhcpStaticMappingValuesSchema } from '../../NetworkWide/VLANs/VLANDetails/DHCPDetails';
import IsPermitted from '../../permissions/IsPermitted';

type StaticMapping = NonNullable<VLAN['dhcpRule']>['staticMappings'][number];

const PinningMap = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: colors.bgNeutralLight,
  strokeAll: colors.strokeNeutralLight,
  borderRadius: '$10',

  [darkThemeSelector]: {
    background: colors.bgNeutralDark,
    strokeAll: colors.strokeNeutralDark,
  },
});

function UpsertStaticMappingDialog({
  state,
  client,
  dhcpRule,
  staticMapping,
}: {
  state: OverlayTriggerState;
  client: NetworkClient;
  dhcpRule: NonNullable<VLAN['dhcpRule']>;
  staticMapping: StaticMapping | undefined;
}) {
  const createStaticMapping = useGraphQLMutation(createDHCPStaticMapping);
  const updateStaticMapping = useGraphQLMutation(updateDHCPStaticMapping);

  const { close } = state;

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    ({ name, hostname, ...values }: DHCPStaticMappingValues) => {
      if (staticMapping?.UUID) {
        const input: UpdateDhcpStaticMappingInput = {
          ...values,
          name: name || null,
          hostname: hostname || null,
        };

        updateStaticMapping.mutate(
          { uuid: staticMapping.UUID, input },
          {
            onSuccess: () => {
              notify('Successfully pinned IP address.', {
                variant: 'positive',
              });

              if (client.connectedVLAN?.UUID) {
                // Should always be set at this point, just for types
                queryClient.invalidateQueries(
                  makeQueryKey(vlanQuery, { uuid: client.connectedVLAN?.UUID }),
                );
              }

              close();
            },
            onError: (err) => {
              notify(
                `There was a problem pinning this IP address${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      } else {
        const input: CreateDhcpStaticMappingInput = {
          ...values,
          name: name || null,
          hostname: hostname || null,
        };

        createStaticMapping.mutate(
          { ruleUUID: dhcpRule.UUID, input },
          {
            onSuccess: () => {
              notify('Successfully pinned IP address.', {
                variant: 'positive',
              });

              if (client.connectedVLAN?.UUID) {
                // Should always be set at this point, just for types
                queryClient.invalidateQueries(
                  makeQueryKey(vlanQuery, { uuid: client.connectedVLAN?.UUID }),
                );
              }

              close();
            },
            onError: (err) => {
              notify(
                `There was a problem pinning this IP address${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      }
    },
    [
      dhcpRule.UUID,
      staticMapping?.UUID,
      updateStaticMapping,
      createStaticMapping,
      close,
      queryClient,
      client.connectedVLAN?.UUID,
    ],
  );

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader icon="ip-address" heading="Reserve IP address" />
      <Formik<DHCPStaticMappingValues>
        initialValues={{
          name: staticMapping?.name ?? '',
          macAddress: client.macAddress,
          ipAddress: client.ip,
          hostname: '',
        }}
        validate={withZodSchema(dhcpStaticMappingValuesSchema)}
        onSubmit={handleSubmit}
      >
        <Form>
          <DialogContent gutter="all">
            <VStack spacing={space(16)}>
              <Alert
                icon="information"
                copy="This will pin the client's MAC address to the current IP address. When the DHCP lease is renewed, it will be issued the same IP address."
              />
              <PinningMap>
                <Stats
                  alignment="center"
                  stats={[
                    {
                      label: 'MAC address',
                      value: (
                        <Text family="monospace">
                          <CopyBox aria-label="Copy MAC address">{client.macAddress}</CopyBox>
                        </Text>
                      ),
                    },
                  ]}
                />
                <div style={{ margin: '-8px 0' }}>
                  <Icon
                    color={{ light: 'iconNeutralLight', dark: 'iconNeutralDark' }}
                    icon="arrow-down"
                    size={space(16)}
                  />
                </div>
                <Stats
                  alignment="center"
                  stats={[
                    {
                      label: 'IP address',
                      value: (
                        <Text family="monospace">
                          <CopyBox aria-label="Copy IP address">{client.ip}</CopyBox>
                        </Text>
                      ),
                    },
                  ]}
                />
              </PinningMap>
              <TextField
                name="name"
                label="Label"
                description="Descriptive name for pinned IP address."
                optional
              />
            </VStack>
          </DialogContent>
          <DialogFooter
            actions={
              <>
                <Button type="button" onClick={state.close} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Save
                </Button>
              </>
            }
          />
        </Form>
      </Formik>
    </Dialog>
  );
}

function DeleteStaticMappingDialog({
  state,
  client,
  staticMapping,
}: {
  state: OverlayTriggerState;
  client: NetworkClient;
  staticMapping: StaticMapping | undefined;
}) {
  const deleteStaticMapping = useGraphQLMutation(deleteDHCPStaticMapping);

  const { close } = state;

  const queryClient = useQueryClient();

  const handleDelete = useCallback(() => {
    if (!staticMapping?.UUID) return;

    deleteStaticMapping.mutate(
      { uuid: staticMapping.UUID },
      {
        onSuccess: () => {
          notify('Successfully deleted pinned IP address.', {
            variant: 'positive',
          });

          if (client.connectedVLAN?.UUID) {
            // Should always be set at this point, just for types
            queryClient.invalidateQueries(
              makeQueryKey(vlanQuery, { uuid: client.connectedVLAN?.UUID }),
            );
          }

          close();
        },
        onError: (err) => {
          notify(
            `There was a problem deleting this pinned IP address${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [staticMapping?.UUID, close, deleteStaticMapping, queryClient, client.connectedVLAN?.UUID]);

  if (!staticMapping) return null;

  return (
    <Dialog state={state} preset="narrow">
      <DialogHeader icon="ip-address" heading="Unreserve IP address" />
      <DialogContent gutter="all" spacing={space(12)}>
        <Alert
          icon="attention"
          variant="attention"
          copy="This client may receive a new IP address when it renews its DHCP lease, which may not be immediately."
        />
        <Body>
          Are you sure you want to unreserve this IP address? This action cannot be undone.
        </Body>
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button type="button" variant="secondary" onClick={state.close}>
              Cancel
            </Button>
            <Button type="button" variant="destructive" onClick={handleDelete}>
              Unreserve
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

export default function ClientActions({
  client,
  view,
}: {
  client: NetworkClient;
  view: 'detail' | 'drawer' | 'edit' | 'list';
}) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const navigate = useNavigate();

  const [isNOSEnabledRPCDisconnectClient] = useNosFeaturesEnabled([
    NosFeature.WOS_RPC_DISCONNECT_CLIENT,
  ]);

  const disconnectMutation = useGraphQLMutation(rpcDisconnectClientMutation);
  const doDisconnect = useCallback(() => {
    disconnectMutation.mutate(
      {
        mac: client.macAddress,
        serialNumber: client.apSerialNumber!,
        ssid: client.ssid!,
      },
      {
        onSuccess: () => {
          notify('Request sent to disconnect client.', {
            variant: 'positive',
          });
        },
        onError: (err) => {
          const gqlErr = getGraphQLError(err);
          notify(
            `There was an error disconnecting the client${
              gqlErr?.message ? `: ${gqlErr.message}.` : '.'
            }.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [disconnectMutation, client]);

  const connectedVLAN = useGraphQL(
    vlanQuery,
    { uuid: client.connectedVLAN?.UUID! },
    {
      enabled: !!client.connectedVLAN?.UUID,
    },
  ).data?.vlan;

  const clientStaticMapping = useMemo(
    () =>
      connectedVLAN?.dhcpRule?.staticMappings?.find(
        (staticMapping) => staticMapping.macAddress === client.macAddress,
      ),
    [client.macAddress, connectedVLAN],
  );

  const { state: upsertStaticMappingDialogState } = useDialogState();
  const { state: deleteStaticMappingDialogState } = useDialogState();

  return (
    <IsPermitted
      isPermitted={({ nosFlags, permissions }) =>
        nosFlags[NosFeature.WOS2] &&
        (permissions.hasPermission(PermissionType.PermNetworkDevicesWrite) ||
          permissions.hasPermission(PermissionType.PermDhcpDnsWrite) ||
          permissions.hasPermission(PermissionType.PermMacAddressAliasWrite))
      }
    >
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          icon="overflow-horizontal"
          arrangement="hidden-label"
        >
          Actions
        </DropdownMenuButton>
        <DropdownMenuPopover align="end">
          {view !== 'detail' && (
            <DropdownMenuGroup>
              <DropdownMenuItem
                onSelect={() =>
                  navigate(
                    makeLink(paths.pages.ClientInsightsPage, {
                      companyName,
                      networkSlug: network.slug,
                      macAddress: client.macAddress,
                    }),
                  )
                }
                icon="client"
              >
                View details
              </DropdownMenuItem>
            </DropdownMenuGroup>
          )}
          <IsPermitted
            isPermitted={({ permissions }) =>
              permissions.hasPermission(PermissionType.PermDhcpDnsWrite) ||
              permissions.hasPermission(PermissionType.PermMacAddressAliasWrite)
            }
          >
            <DropdownMenuGroup>
              <IsPermitted
                isPermitted={({ permissions }) =>
                  permissions.hasPermission(PermissionType.PermDhcpDnsWrite)
                }
              >
                {(!clientStaticMapping || clientStaticMapping.ipAddress !== client.ip) && (
                  <DropdownMenuItem
                    onSelect={upsertStaticMappingDialogState.open}
                    icon="ip-address"
                  >
                    Reserve IP address
                  </DropdownMenuItem>
                )}
                {clientStaticMapping && (
                  <DropdownMenuItem
                    onSelect={deleteStaticMappingDialogState.open}
                    icon="ip-address"
                  >
                    Unreserve IP address
                  </DropdownMenuItem>
                )}
              </IsPermitted>
              <IsPermitted
                isPermitted={({ permissions }) =>
                  permissions.hasPermission(PermissionType.PermMacAddressAliasWrite)
                }
              >
                <DropdownMenuItem
                  onSelect={() =>
                    navigate(
                      makeDrawerLink(window.location, paths.drawers.ClientRename2Page, {
                        companyName,
                        networkSlug: network.slug,
                        macAddress: client.macAddress,
                      }),
                    )
                  }
                  icon="pencil"
                >
                  Rename
                </DropdownMenuItem>
              </IsPermitted>
            </DropdownMenuGroup>
          </IsPermitted>
          <IsPermitted
            isPermitted={({ permissions }) =>
              permissions.hasPermission(PermissionType.PermMacAddressAliasWrite)
            }
          >
            {isNOSEnabledRPCDisconnectClient && (
              <DropdownMenuGroup>
                <DropdownMenuItem
                  disabled={disconnectMutation.isLoading}
                  onSelect={doDisconnect}
                  icon="power-cycle"
                >
                  Disconnect
                </DropdownMenuItem>
              </DropdownMenuGroup>
            )}
          </IsPermitted>
        </DropdownMenuPopover>
      </DropdownMenu>
      <IsPermitted
        isPermitted={({ permissions }) =>
          permissions.hasPermission(PermissionType.PermDhcpDnsWrite)
        }
      >
        {connectedVLAN?.dhcpRule && (
          <>
            <UpsertStaticMappingDialog
              state={upsertStaticMappingDialogState}
              client={client}
              dhcpRule={connectedVLAN.dhcpRule}
              staticMapping={clientStaticMapping}
            />
            <DeleteStaticMappingDialog
              state={deleteStaticMappingDialogState}
              client={client}
              staticMapping={clientStaticMapping}
            />
          </>
        )}
      </IsPermitted>
    </IsPermitted>
  );
}
