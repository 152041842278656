import { Button, EmptyState, sizing } from '@meterup/atto';
import { styled } from '@meterup/common';
import { useMemo } from 'react';

import type { UptimeByNetwork } from '../useUptimeStats';
import type { NetworksOverviewData } from '../utils/aggregateStatsForNetworks';
import { PermissionType } from '../../../gql/graphql';
import { useCompany } from '../../../hooks/useCompany';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import IsPermitted from '../../permissions/IsPermitted';
import { HubCard } from '../Units/HubCard';

const HubCardsContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax($320, 1fr))',
  gridTemplateRows: 'repeat(auto-fit, minmax(min-content, 0))',
  gap: '$16',
  width: '100%',
  height: '100%',
  overflow: 'auto',
  padding: sizing.squish,
});

export function HubCards({
  uptimeStats,
  networks,
  pagination,
}: {
  uptimeStats: UptimeByNetwork;
  networks: NetworksOverviewData['networks'];
  pagination: {
    startIndex: number;
    endIndex: number;
  };
}) {
  const companyName = useCurrentCompany();
  const company = useCompany(companyName);

  const { startIndex, endIndex } = pagination;
  const paginatedData = useMemo(
    () => ({
      ...networks,
      rows: networks.slice(startIndex, endIndex),
    }),
    [networks, startIndex, endIndex],
  );

  if (paginatedData.rows.length === 0) {
    return (
      <EmptyState
        icon="network-wide"
        heading="No networks"
        action={
          !company?.isCustomer && (
            <IsPermitted permissions={PermissionType.PermNetworkDevicesWriteRestricted}>
              <Button arrangement="leading-icon" icon="plus" internal>
                Add network
              </Button>
            </IsPermitted>
          )
        }
      />
    );
  }

  return (
    <HubCardsContainer>
      {paginatedData.rows.map((network, idx) => (
        <HubCard
          idx={idx}
          key={network.slug}
          network={network}
          company={companyName}
          uptimeStats={uptimeStats.get(network.uuid)}
        />
      ))}
    </HubCardsContainer>
  );
}
