import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  PrimaryField,
  PrimaryToggleField,
  Section,
  SectionContent,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Textarea,
  TextInput,
} from '@meterup/atto';
import {
  expectDefinedOrThrow,
  isDefinedAndNotEmpty,
  notify,
  ResourceNotFoundError,
} from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import * as z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import type {
  MutationCreateNosVersionArgs,
  MutationUpdateNosVersionArgs,
  NosVersionInput,
} from '../../gql/graphql';
import type { NosVersionByIDQueryResult } from './utils';
import { NosVersionInputSchema } from '../../gql/zod-types';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { formatTimestamp } from '../Devices/utils';
import { FieldProvider, NumberFieldProvider } from '../Form/FieldProvider';
import { FormikConditional } from '../FormikConditional';
import {
  CreateNOSVersionMutation,
  nosVersionByIDQuery,
  nosVersionsQuery,
  UpdateNOSVersionMutation,
} from './utils';

const validNosVersionInput = NosVersionInputSchema.omit({
  mc01Build: true,
  mw03Build: true,
  mw04Build: true,
  mw06Build: true,
  mw07Build: true,
  mw08Build: true,
  mw09Build: true,
  ms10Build: true,
  ms11Build: true,
  ms12Build: true,
  mp01Build: true,
}).extend({
  major: z.number().nonnegative().int().optional(),
  minor: z.number().nonnegative().int().optional(),
  patch: z.number().nonnegative().int().optional(),

  mcBuild: z.string().nullish(),
  mwBuild: z.string().nullish(),
  msBuild: z.string().nullish(),
  mpBuild: z.string().nullish(),
});

type ValidNOSVersionInput = z.infer<typeof validNosVersionInput>;

function NewDefaultNOSVersionAlert() {
  const { values, initialValues } = useFormikContext<ValidNOSVersionInput>();
  const nosVersions = useGraphQL(nosVersionsQuery).data?.nosVersions;
  expectDefinedOrThrow(nosVersions, new ResourceNotFoundError('Could not load NOS versions'));
  const defaultNosVersion = nosVersions.find((version) => version.isDefault);

  return (
    <FormikConditional<ValidNOSVersionInput>
      condition={() =>
        defaultNosVersion !== undefined && values.isDefault !== initialValues.isDefault
      }
    >
      <Alert
        variant="attention"
        icon="warning"
        copy={`This will mark the current default version (${defaultNosVersion!.version}) as not default, and all new networks will use this version moving forward.`}
      />
    </FormikConditional>
  );
}

function NOSVersionDetailDrawerContents({
  nosData,
}: {
  nosData?: NosVersionByIDQueryResult | null | undefined;
}) {
  const closeDrawer = useCloseDrawerCallback();
  const disableEdits = nosData?.isLocked;
  const mutation = useGraphQLMutation(UpdateNOSVersionMutation);
  const createMutation = useGraphQLMutation(CreateNOSVersionMutation);
  const queryClient = useQueryClient();

  const handleSubmit = ({
    msBuild,
    mwBuild,
    mcBuild,
    mpBuild,
    ...values
  }: ValidNOSVersionInput) => {
    const input: NosVersionInput = values;

    // Send build strings only if not previously locked
    if (!nosData?.isLocked) {
      input.mc01Build = mcBuild;
      input.mp01Build = mpBuild;

      // Use the same builds for all specific devices for now
      input.ms10Build = msBuild;
      input.ms11Build = msBuild;
      input.ms12Build = msBuild;

      input.mw03Build = mwBuild;
      input.mw04Build = mwBuild;
      input.mw06Build = mwBuild;
      input.mw07Build = mwBuild;
      input.mw08Build = mwBuild;
      input.mw09Build = mwBuild;
    }

    if (!nosData) {
      const params: MutationCreateNosVersionArgs = {
        input,
      };

      createMutation.mutate(params, {
        onSuccess: () => {
          notify(`Successfully created ${input.version}`, { variant: 'positive' });
          queryClient.invalidateQueries(makeQueryKey(nosVersionsQuery));
          closeDrawer();
        },
        onError: (err) => {
          const gqlErr = getGraphQLError(err);
          notify(
            `There was an error creating ${input.version}: ${gqlErr?.message ?? 'Unknown error'}`,
            {
              variant: 'negative',
            },
          );
        },
      });
    } else {
      const params: MutationUpdateNosVersionArgs = {
        id: nosData.id,
        input,
      };

      mutation.mutate(params, {
        onSuccess: () => {
          notify(`Successfully updated ${input.version}`, { variant: 'positive' });
          queryClient.invalidateQueries(makeQueryKey(nosVersionsQuery));
          closeDrawer();
        },
        onError: (err) => {
          const gqlErr = getGraphQLError(err);
          notify(
            `There was an error updating ${input.version}: ${gqlErr?.message ?? 'Unknown error'}`,
            {
              variant: 'negative',
            },
          );
        },
      });
    }
  };

  return (
    <Drawer>
      <Formik<ValidNOSVersionInput>
        validationSchema={toFormikValidationSchema(validNosVersionInput)}
        initialValues={{
          version: nosData?.version ?? '',
          mcBuild: nosData?.mc01Build ?? '',
          mwBuild: nosData?.mw07Build ?? '',
          msBuild: nosData?.ms10Build ?? '',
          mpBuild: nosData?.mp01Build ?? '',
          releaseNotes: nosData?.releaseNotes ?? '',
          isLocked: nosData?.isLocked ?? false,
          major: nosData?.major ?? 0,
          minor: nosData?.minor ?? 0,
          patch: nosData?.patch ?? 0,
          isDefault: nosData?.isDefault ?? false,
        }}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        <Form>
          <DrawerHeader
            heading={nosData ? `Update NOS version ID ${nosData.id}` : 'Add NOS version'}
            onClose={closeDrawer}
          />
          <DrawerContent>
            <FieldProvider name="version">
              <PrimaryField label="NOS version" element={<TextInput />} />
            </FieldProvider>
            <FieldProvider name="isDefault">
              <PrimaryToggleField label="Default" />
            </FieldProvider>
            <NewDefaultNOSVersionAlert />
            <FieldProvider name="mcBuild">
              <PrimaryField label="COS" element={<TextInput disabled={disableEdits} />} />
            </FieldProvider>
            <FieldProvider name="mwBuild">
              <PrimaryField
                label="WOS (MW07, MW08, MW09)"
                element={<TextInput disabled={disableEdits} />}
              />
            </FieldProvider>
            <FieldProvider name="msBuild">
              <PrimaryField
                label="SOS (MS10, MS11, MS12)"
                element={<TextInput disabled={disableEdits} />}
              />
            </FieldProvider>
            <FieldProvider name="mpBuild">
              <PrimaryField label="PDU (MP01)" element={<TextInput disabled={disableEdits} />} />
            </FieldProvider>
            <NumberFieldProvider name="major" defaultValue={0}>
              <PrimaryField label="Major" element={<TextInput disabled={disableEdits} />} />
            </NumberFieldProvider>
            <NumberFieldProvider name="minor" defaultValue={0}>
              <PrimaryField label="Minor" element={<TextInput disabled={disableEdits} />} />
            </NumberFieldProvider>
            <NumberFieldProvider name="patch" defaultValue={0}>
              <PrimaryField label="Patch" element={<TextInput disabled={disableEdits} />} />
            </NumberFieldProvider>
            <FieldProvider name="isLocked">
              <PrimaryToggleField
                label="Lock build names in this version"
                disabled={disableEdits}
              />
            </FieldProvider>

            <FieldProvider name="releaseNotes">
              <PrimaryField label="Release notes" element={<Textarea />} />
            </FieldProvider>
            {isDefinedAndNotEmpty(nosData?.updatedAt) && (
              <Section relation="standalone">
                <SectionContent>
                  <SummaryList>
                    <SummaryListRow>
                      <SummaryListKey>Last updated</SummaryListKey>
                      <SummaryListValue>{formatTimestamp(nosData.updatedAt)}</SummaryListValue>
                    </SummaryListRow>
                  </SummaryList>
                </SectionContent>
              </Section>
            )}
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button variant="secondary" type="button" onClick={closeDrawer}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  Save
                </Button>
              </>
            }
          />
        </Form>
      </Formik>
    </Drawer>
  );
}

export function UpdateNOSVersion({ nosID }: { nosID: number }) {
  const nos = useGraphQL(nosVersionByIDQuery, { id: nosID }).data;
  const nosData = nos?.nosVersions[0] as NosVersionByIDQueryResult;
  return <NOSVersionDetailDrawerContents nosData={nosData} />;
}

export default function NOSVersionDetailDrawer({ nosVersionID }: { nosVersionID: string }) {
  if (nosVersionID === 'create') {
    return <NOSVersionDetailDrawerContents />;
  }
  const nosID = parseInt(nosVersionID, 10);
  return <UpdateNOSVersion nosID={nosID} />;
}
