import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Alert,
  Badge,
  Body,
  Button,
  DeviceTarget,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuPopover,
  Section,
  SectionContent,
  SectionHeader,
  Small,
  space,
  useDialogState,
  VStack,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { ResourceNotFoundError } from '@meterup/common';
import { notify } from '@meterup/connect-ui/src/components/stolenFromFrontends/notify';
import { getGraphQLErrorMessageOrEmpty, makeQueryKey, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { useBulkNOSUpgrade } from '../../../../components/firmwareBulkUpgrader/hooks/useBulkNOSUpgrade';
import { BulkNOSUpgradesQuery } from '../../../../components/firmwareBulkUpgrader/hooks/useBulkNOSUpgrades';
import { paths } from '../../../../constants';
import { graphql } from '../../../../gql';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { Nav } from '../../../../nav';
import { makeLink } from '../../../../utils/main_and_drawer_navigation';

export const Meta: PagefileMetaFn = () => ({
  path: '/operator/firmware-bulk-upgrader/upgrades/:uuid',
  title: 'Upgrade - Firmware bulk upgrader - Operator',
});

const DeleteNOSFirmwareUpgradeMutation = graphql(`
  mutation DeleteNOSFirmwareUpgradeMutation($uuid: UUID!) {
    deleteBulkNOSUpgrade(UUID: $uuid) {
      UUID
    }
  }
`);

export default function FirmwareBulkUpgradeDetailsPage() {
  const isOperator = useIsOperator({ respectDemoMode: true });
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.FirmwareBulkUpgradeDetailsPage);

  if (!isOperator || !drawerParams?.uuid) {
    throw new ResourceNotFoundError('Page not found');
  }

  const closeDrawer = useCloseDrawerCallback();
  const bulkNosUpgrade = useBulkNOSUpgrade(drawerParams.uuid);

  const dialogProps = useDialogState();

  const queryClient = useQueryClient();
  const deleteMutation = useGraphQLMutation(DeleteNOSFirmwareUpgradeMutation);
  const handleDelete = useCallback(() => {
    if (deleteMutation.isLoading) return;

    deleteMutation.mutate(
      { uuid: drawerParams.uuid },
      {
        onSuccess: () => {
          notify('Successfully deleted bulk upgrade.', { variant: 'positive' });
          queryClient.invalidateQueries(
            makeQueryKey(BulkNOSUpgradesQuery, { filter: { limit: 100, offset: 0 } }),
          );
          closeDrawer();
        },
        onError: (err) => {
          notify(
            `There was a problem deleting this bulk upgrade${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [deleteMutation, closeDrawer, queryClient, drawerParams.uuid]);

  return (
    <Drawer>
      <DrawerHeader
        icon="upgrading"
        onClose={closeDrawer}
        heading="Bulk firmware upgrade"
        actions={
          <DropdownMenu>
            <DropdownMenuButton
              variant="secondary"
              icon="overflow-horizontal"
              arrangement="hidden-label"
            >
              Actions
            </DropdownMenuButton>

            <DropdownMenuPopover align="end">
              <DropdownMenuItem onSelect={dialogProps.openFromMenu} icon="trash-can">
                Delete
              </DropdownMenuItem>
            </DropdownMenuPopover>
          </DropdownMenu>
        }
      />
      <DrawerContent gutter="vertical">
        <Section relation="stacked">
          <SectionHeader heading="Scheduled networks" />
          <SectionContent gutter="all" spacing={space(12)}>
            {bulkNosUpgrade?.networks.map((network) => {
              if (network?.companySlug) {
                return (
                  <DeviceTarget
                    to={makeLink(paths.pages.IndividualNetworkRootPage, {
                      networkSlug: network.slug,
                      companyName: network.companySlug!,
                    })}
                    as={Link}
                    type="network-wide"
                    aria-label={`Go to ${network.label}`}
                    wrap
                  >
                    {network.company ? `${network.company?.name} - ` : ''}
                    {network.label}
                  </DeviceTarget>
                );
              }
              return (
                <Badge icon="network-wide" arrangement="leading-icon" variant="neutral">
                  <Small>
                    {network.company ? `${network.company?.name} - ` : ''}
                    {network.label}
                  </Small>
                </Badge>
              );
            })}
          </SectionContent>
        </Section>
        <Dialog state={dialogProps.state} preset="narrow">
          <DialogHeader heading="Delete power distribution unit" />
          <DialogContent gutter="all">
            <VStack spacing={space(12)}>
              <Alert
                icon="attention"
                variant="negative"
                heading="Deleting a bulk upgrade can impact the networks"
                copy="You will not be able to delete/cancel this bulk upgrade if any of the networks in the bulk upgrade are non-pending."
              />
              <Body>
                Are you sure you want to delete bulk upgrade? This action cannot be undone.
              </Body>
            </VStack>
          </DialogContent>
          <DialogFooter
            actions={
              <>
                <Button variant="secondary" onClick={dialogProps.state.close}>
                  Cancel
                </Button>
                <Button
                  type="button"
                  onClick={handleDelete}
                  variant="destructive"
                  disabled={deleteMutation.isLoading}
                  loading={deleteMutation.isLoading}
                >
                  Delete
                </Button>
              </>
            }
          />
        </Dialog>
      </DrawerContent>
    </Drawer>
  );
}
