import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  notify,
  ResourceNotFoundError,
} from '@meterup/common';
import { makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { z } from 'zod';

import { paths } from '../../../../constants';
import { graphql } from '../../../../gql';
import { type NotificationSettingsQueryQuery, AlertParameterType } from '../../../../gql/graphql';
import {
  CreateNotificationSettingInputSchema,
  NotificationSettingArgumentInputSchema,
  UpdateNotificationSettingInputSchema,
} from '../../../../gql/zod-types';
import { useIdentity } from '../../../../providers/IdentityDataProvider';
import { useNetworkForSettingsPath } from '../../../../routes/pages/settings/network/NetworkUtils';

export type NotificationSetting =
  NotificationSettingsQueryQuery['notificationSettingsForNetwork'][number];

export const notificationSettingsQuery = graphql(`
  query NotificationSettingsQuery($networkUUID: UUID!) {
    notificationSettingsForNetwork(networkUUID: $networkUUID) {
      UUID
      alertReceiverUUID
      isEnabled
      arguments {
        parameterUUID
        value
      }
      notificationDefinition {
        name
        displayName
        description
      }
    }
  }
`);

const notificationArgsRefinement = (
  args: NotificationAddSchema['input']['arguments'] | undefined | null,
  ctx: z.RefinementCtx,
) => {
  expectDefinedOrThrow(args, new ResourceNotFoundError('No arguments found'));

  args.forEach((arg, index) => {
    let schema;
    let result;
    switch (arg.type) {
      case AlertParameterType.Number:
        schema = z.number();
        result = schema.safeParse(arg.value);

        if (!result.success) {
          if (
            arg.required &&
            result.error?.issues?.some((issue) => issue.code === z.ZodIssueCode.invalid_type)
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Please supply a number value.',
              path: ['input', 'arguments', index, 'value'],
            });
          }
        }
        break;
      case AlertParameterType.String:
        schema = z.string().nonempty();
        result = schema.safeParse(arg.value);

        if (!result.success) {
          if (
            arg.required &&
            result.error?.issues?.some((issue) => issue.code === z.ZodIssueCode.too_small)
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Please supply a value.',
              path: ['input', 'arguments', index, 'value'],
            });
          }
        }
        break;
    }
  });
};

export const notificationAddSchema = z
  .object({
    networkUUID: z.string(),
    input: CreateNotificationSettingInputSchema.omit({ createdBy: true }).extend({
      alertReceiverUUID: z.string().nonempty({ message: 'Please supply a receiver.' }),
      notificationDefinitionName: z.string().nonempty({ message: 'Please supply a trigger.' }),
      arguments: z.array(
        NotificationSettingArgumentInputSchema.extend({ type: z.string(), required: z.boolean() }),
      ),
    }),
  })
  .omit({ networkUUID: true })
  .superRefine(({ input: { arguments: args } }, ctx) => {
    notificationArgsRefinement(args, ctx);
  });

export type NotificationAddSchema = z.infer<typeof notificationAddSchema>;

export const notificationUpdateSchema = z
  .object({
    UUID: z.string(),
    input: UpdateNotificationSettingInputSchema.omit({ updatedBy: true }).extend({
      alertReceiverUUID: z.string().nonempty({ message: 'Please supply a receiver.' }),
      notificationDefinitionName: z.string(),
      arguments: z.array(
        NotificationSettingArgumentInputSchema.extend({ type: z.string(), required: z.boolean() }),
      ),
    }),
  })
  .omit({ UUID: true })
  .superRefine(({ input: { arguments: args } }, ctx) => {
    notificationArgsRefinement(args, ctx);
  });

export type NotificationUpdateSchema = z.infer<typeof notificationUpdateSchema>;

export const createNotificationMutation = graphql(`
  mutation CreateNotificationSettingMutation(
    $networkUUID: UUID!
    $input: CreateNotificationSettingInput!
  ) {
    createNotificationSetting(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export function useNotificationSettings({ networkUUID }: { networkUUID: string }) {
  return checkDefinedOrThrow(
    useGraphQL(notificationSettingsQuery, { networkUUID }).data?.notificationSettingsForNetwork,
  );
}

const notificationDefinitionQuery = graphql(`
  query NotificationDefinitionsQuery {
    alertDefinitions {
      name
      displayName
      description
      parameters {
        uuid
        name
        description
        type
        required
      }
    }
  }
`);

export const deleteNotificationSettingMutation = graphql(`
  mutation deleteNotificationSettingMutation($UUID: UUID!) {
    deleteNotificationSetting(UUID: $UUID) {
      UUID
    }
  }
`);

export function useNotificationDefinitions() {
  return checkDefinedOrThrow(useGraphQL(notificationDefinitionQuery).data?.alertDefinitions);
}

export function useInvalidateNotificationSettings({ networkUUID }: { networkUUID: string }) {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries(makeQueryKey(notificationSettingsQuery, { networkUUID }));
  }, [networkUUID, queryClient]);
}

function useNotificationSettingOrNull({
  uuid,
  networkUUID,
}: {
  uuid: string;
  networkUUID: string;
}) {
  return (
    useGraphQL(notificationSettingsQuery, {
      networkUUID,
    }).data?.notificationSettingsForNetwork.find((setting) => setting.UUID === uuid) ?? null
  );
}

export function useNotificationSetting(props: { networkUUID: string; uuid: string }) {
  return checkDefinedOrThrow(
    useNotificationSettingOrNull(props),
    new ResourceNotFoundError('No notification setting found'),
  );
}

export const updateNotificationSettingMutation = graphql(`
  mutation updateNotificationSettingMutation(
    $UUID: UUID!
    $input: UpdateNotificationSettingInput!
  ) {
    updateNotificationSetting(UUID: $UUID, input: $input) {
      UUID
    }
  }
`);

export function useToggleEnabledNotificationSetting() {
  const mutation = useGraphQLMutation(updateNotificationSettingMutation);
  const identity = useIdentity();
  const network = useNetworkForSettingsPath({
    path: paths.pages.AlertsPage,
  });
  const invalidateSettings = useInvalidateNotificationSettings({ networkUUID: network.UUID });
  return function toggleSetting(notificationUUID: string, currentIsEnabled: boolean) {
    mutation.mutate(
      {
        UUID: notificationUUID,
        input: {
          isEnabled: !currentIsEnabled,
          updatedBy: identity?.id,
        },
      },
      {
        onSuccess() {
          notify('Enabled state updated', {
            variant: 'positive',
          });
          invalidateSettings();
        },
        onError() {
          notify('Failed to update enabled state ', {
            variant: 'negative',
          });
        },
      },
    );
  };
}
