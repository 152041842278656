import { Badge, Button, EmptyState, Icon, space, useDialogState } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { AutoTable } from '@meterup/common';
import { useCallback, useMemo } from 'react';

import type { FilterAndGroupOutput } from '../filtering/useFilterAndGroup';
import type { AggregatedNetworkInfo } from '../utils/aggregateStatsForNetworks';
import { paths } from '../../../constants';
import { useCompany } from '../../../hooks/useCompany';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { createColumnBuilder } from '../../Table/createColumnBuilder';

function ControllerBadgeCell({ row }: { row: AggregatedNetworkInfo }) {
  return (
    <Badge
      arrangement="hidden-label"
      size="small"
      ends="pill"
      icon={row.hasOnlineController ? 'checkmark' : 'cross'}
      variant={row.hasOnlineController ? 'positive' : 'neutral'}
    >
      {row.hasOnlineController ? 'Online' : 'Offline'}
    </Badge>
  );
}

const builder = createColumnBuilder<AggregatedNetworkInfo>();
const columns = [
  builder.data((s) => (s.hasOnlineController ? 'Online' : 'Offline'), {
    id: 'badge',
    header: () => <Icon icon="question" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 48,
      tooltip: {
        contents: 'Status',
      },
    },
    cell: ControllerBadgeCell,
    enableGlobalFilter: false,
  }),
  builder.data((s) => s.label, {
    id: 'name',
    header: 'Name',
    meta: {
      minWidth: 44,
      isLeading: true,
    },
  }),
  builder.data((s) => s.mailingAddress, {
    id: 'address',
    header: 'Address',
    meta: {
      minWidth: 44,
    },
  }),
  builder.data((s) => s.devices.controllers.total.toString(), {
    id: 'security-appliances',
    header: 'Security appliances',
    enableGlobalFilter: false,
    meta: {
      minWidth: 44,
      alignment: 'end',
    },
  }),
  builder.data((s) => s.devices.switches.total.toString(), {
    id: 'switches',
    header: 'Switches',
    enableGlobalFilter: false,
    meta: {
      minWidth: 44,
      alignment: 'end',
    },
  }),
  builder.data((s) => s.devices.aps.total.toString(), {
    id: 'access-points',
    header: 'Access points',
    enableGlobalFilter: false,
    meta: {
      minWidth: 44,
      alignment: 'end',
    },
  }),
];

export default function HubList({
  data,
  pagination,
}: {
  data: FilterAndGroupOutput;
  pagination: {
    startIndex: number;
    endIndex: number;
  };
}) {
  const companyName = useCurrentCompany();
  const company = useCompany(companyName);
  const dialogProps = useDialogState();
  const isOperator = useIsOperator();

  const linkTo = useCallback(
    (row: AggregatedNetworkInfo) =>
      row.isSandbox
        ? '#'
        : makeLink(paths.pages.IndividualNetworkRootPage, {
            companyName,
            networkSlug: row.slug,
          }),
    [companyName],
  );

  const { startIndex, endIndex } = pagination;
  const paginatedData = useMemo(
    () => ({
      ...data,
      rows: data.rows.slice(startIndex, endIndex),
    }),
    [data, startIndex, endIndex],
  );

  if (paginatedData.rows.length === 0) {
    return (
      <EmptyState
        icon="network-wide"
        heading="No networks"
        action={
          !company?.isCustomer &&
          isOperator && (
            <Button arrangement="leading-icon" icon="plus" internal>
              Add network
            </Button>
          )
        }
      />
    );
  }

  return (
    <AutoTable
      columns={columns}
      data={paginatedData.rows as any[]}
      getLinkTo={linkTo}
      onRowClick={dialogProps.state.close}
    />
  );
}
