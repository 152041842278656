import {
  Button,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  useDialogState,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import { PermissionType } from '@meterup/common/src/gql/graphql';
import {
  getGraphQLError,
  GraphQLErrorBoundary,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';

import { paths } from '../../../constants';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import {
  NosFeature,
  useNosFeatureEnabled,
  useNosFeaturesEnabled,
} from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import DeviceConfigEditorDialog from '../../../routes/pages/network/operators/device_config/DeviceConfigEditorDialog';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import { ACSDialog, ACSJobType } from '../../ACS/ACSDialog';
import ConsoleAccessDialog from '../../Devices/ConsoleAccess';
import { blinkLEDsMutation, refreshConfigMutation } from '../../Devices/utils';
import IsPermitted from '../../permissions/IsPermitted';
import { useIsPermitted } from '../../permissions/useIsPermitted';
import { AccessPointQuery } from '../../Wireless/utils';
import AccessPointDeleteDialog from './AccessPointDeleteDialog';
import { AccessPointRebootDialog } from './AccessPointRebootDialog';

type AccessPointActionsProps = {
  serialNumber: string;
  status: 'online' | 'offline';
  uuid: string;
  view: 'detail' | 'drawer' | 'edit' | 'list';
};

export function AccessPointActions({ serialNumber, status, uuid, view }: AccessPointActionsProps) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);
  const virtualDevice = useGraphQL(AccessPointQuery, { uuid, includeUptime }).data?.virtualDevice;
  const rebootDialogProps = useDialogState();
  const configDialogProps = useDialogState();
  const deleteDialogState = useDialogState();
  const acsDialogProps = useDialogState();
  const { state: consoleAccessDialogState } = useDialogState();
  const supportsConsoleAccess = useNosFeatureEnabled(NosFeature.WOSConsoleChallengeResponse);

  const openEdit = () => {
    navigate(
      makeDrawerLink(window.location, paths.drawers.AccessPointEditDrawerPage, {
        id: uuid,
        networkSlug: network.slug,
        companyName,
      }),
    );
  };

  const ledMutation = useGraphQLMutation(blinkLEDsMutation);
  const configMutation = useGraphQLMutation(refreshConfigMutation);
  const featureFlags = useFeatureFlags();
  const [isACSNOSEnabled] = useNosFeaturesEnabled([NosFeature.WOS_ACS]);
  const isACSEnabled = isACSNOSEnabled && featureFlags['wos-acs'];

  const flash = () => {
    ledMutation.mutate(
      {
        serialNumber,
      },
      {
        onSuccess: () => {
          notify('Request sent to flash LEDs on this access point', {
            variant: 'positive',
          });
        },
        onError: (err) => {
          const gqlErr = getGraphQLError(err);
          notify(
            `There was an error flashing LEDs on this access point${
              gqlErr?.message ? `: ${gqlErr.message}` : ''
            }`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  };

  const refreshConfig = () => {
    configMutation.mutate(
      {
        serialNumber,
      },
      {
        onSuccess: () => {
          notify('Request sent to access point to pull its config', {
            variant: 'positive',
          });
        },
        onError: (err) => {
          const gqlErr = getGraphQLError(err);
          notify(`There was an error${gqlErr?.message ? `: ${gqlErr.message}` : ''}`, {
            variant: 'negative',
          });
        },
      },
    );
  };

  const isOffline = !status || status === 'offline';

  const openDetails = () => {
    navigate(
      makeLink(paths.pages.AccessPointPage, {
        networkSlug: network.slug,
        companyName,
        uuid,
        tab: 'insights',
      }),
    );
  };

  const canEditAPRestricted = useIsPermitted({
    isPermitted({ permissions, nosFlags }) {
      return Boolean(
        permissions.hasPermission(PermissionType.PermNetworkDevicesWriteRestricted) &&
          nosFlags[NosFeature.WOS2],
      );
    },
  });

  return (
    <>
      <IsPermitted
        isPermitted={({ permissions, nosFlags }) =>
          Boolean(permissions.hasPermission(PermissionType.PermNetworkDevicesWrite)) &&
          nosFlags[NosFeature.WOS2]
        }
      >
        {view !== 'edit' && (
          <Button arrangement="leading-icon" icon="pencil" onClick={openEdit} variant="secondary">
            Edit
          </Button>
        )}
      </IsPermitted>
      <DropdownMenu>
        <DropdownMenuButton
          variant="secondary"
          icon="overflow-horizontal"
          arrangement="hidden-label"
        >
          Actions
        </DropdownMenuButton>

        <DropdownMenuPopover align="end">
          {view !== 'detail' && (
            <DropdownMenuGroup>
              <DropdownMenuItem icon="access-point" onSelect={openDetails}>
                View details
              </DropdownMenuItem>
            </DropdownMenuGroup>
          )}

          <DropdownMenuGroup>
            <IsPermitted
              anyPermissions={[
                PermissionType.PermNetworkDevicesWrite,
                PermissionType.PermHardwareDeviceRpcBlinkLeds,
              ]}
            >
              <DropdownMenuItem
                disabled={ledMutation.isLoading || isOffline}
                onSelect={flash}
                icon="flash-leds"
              >
                Flash LEDs
              </DropdownMenuItem>
            </IsPermitted>

            <IsPermitted permissions={PermissionType.PermNetworkDevicesWrite}>
              <DropdownMenuItem
                disabled={isOffline}
                onSelect={rebootDialogProps.state.open}
                icon="power-cycle"
              >
                Reboot
              </DropdownMenuItem>
            </IsPermitted>

            <IsPermitted permissions={PermissionType.PermNetworkDevicesWriteRestricted}>
              {isACSEnabled && (
                <DropdownMenuItem
                  disabled={isOffline}
                  onSelect={acsDialogProps.state.open}
                  icon="channel"
                  internal
                >
                  Run ACS
                </DropdownMenuItem>
              )}
            </IsPermitted>
          </DropdownMenuGroup>
          <IsPermitted
            anyPermissions={[
              PermissionType.PermHardwareDeviceRpcRefreshConfig,
              PermissionType.PermNetworkDevicesWriteRestricted,
              PermissionType.PermNetworkDevicesWrite,
            ]}
          >
            <DropdownMenuGroup>
              <IsPermitted
                anyPermissions={[
                  PermissionType.PermNetworkDevicesWrite,
                  PermissionType.PermHardwareDeviceRpcRefreshConfig,
                ]}
              >
                <DropdownMenuItem
                  disabled={configMutation.isLoading || isOffline}
                  onSelect={refreshConfig}
                  icon="arrows-rotate"
                >
                  Refresh config
                </DropdownMenuItem>
              </IsPermitted>

              <IsPermitted permissions={PermissionType.PermNetworkDevicesWriteRestricted}>
                <DropdownMenuItem onSelect={configDialogProps.state.open} icon="code" internal>
                  Show config
                </DropdownMenuItem>
                {supportsConsoleAccess && (
                  <DropdownMenuItem
                    disabled={!virtualDevice?.hardwareDevice?.serialNumber}
                    onSelect={consoleAccessDialogState.open}
                    icon="ethernet"
                    internal
                  >
                    Console access
                  </DropdownMenuItem>
                )}
              </IsPermitted>
            </DropdownMenuGroup>
          </IsPermitted>
          <IsPermitted permissions={PermissionType.PermNetworkDevicesWriteRestricted}>
            <DropdownMenuGroup>
              <DropdownMenuItem
                disabled={!!virtualDevice?.hardwareDevice}
                title={
                  virtualDevice?.hardwareDevice
                    ? 'Please unassign any hardware before deleting the virtual device'
                    : undefined
                }
                onSelect={deleteDialogState.state.open}
                icon="trash-can"
                internal
              >
                Delete
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </IsPermitted>
        </DropdownMenuPopover>
      </DropdownMenu>
      {canEditAPRestricted && virtualDevice && (
        <>
          <AccessPointDeleteDialog state={deleteDialogState.state} uuid={virtualDevice.UUID} />
          <ACSDialog
            state={acsDialogProps.state}
            label={virtualDevice.label}
            identifier={virtualDevice.UUID}
            jobType={ACSJobType.AccessPoint}
          />
        </>
      )}
      {canEditAPRestricted && virtualDevice && virtualDevice.hardwareDevice && (
        <>
          <AccessPointRebootDialog
            state={rebootDialogProps.state}
            serialNumber={virtualDevice.hardwareDevice.serialNumber}
            label={virtualDevice.label}
          />
          <IsPermitted permissions={PermissionType.PermNetworkDevicesWriteRestricted}>
            <ConsoleAccessDialog
              state={consoleAccessDialogState}
              serialNumber={virtualDevice.hardwareDevice.serialNumber}
            />
            <GraphQLErrorBoundary fallback={() => null}>
              <Suspense fallback={null}>
                <DeviceConfigEditorDialog
                  state={configDialogProps.state}
                  serialNumber={virtualDevice.hardwareDevice.serialNumber}
                />
              </Suspense>
            </GraphQLErrorBoundary>
          </IsPermitted>
        </>
      )}
    </>
  );
}
