import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader } from '@meterup/atto';
import { expectDefinedOrThrow, notify, ResourceNotFoundError } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { PermissionType } from '../../../gql/graphql';
import { UpdateSwitchProfileInputSchema as BaseSchema } from '../../../gql/zod-types';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../hooks/useNosFeatures';
import { styled } from '../../../stitches';
import { shouldShowConsoleToggle } from '../../../utils/devices';
import { HardwareDeviceField } from '../../Devices/Fields';
import { updateVirtualDeviceMutation } from '../../Devices/utils';
import IsPermitted from '../../permissions/IsPermitted';
import {
  STPBridgePriorityField,
  SwitchDescriptionField,
  SwitchIsConsoleEnabledField,
  SwitchNameField,
} from './Fields';
import SwitchActions from './SwitchActions';
import {
  type SwitchVirtualDevice,
  SwitchesQuery,
  SwitchQuery,
  UpdateSwitchProfileMutation,
} from './utils';

const UpdateSwitchProfileInputSchema = BaseSchema.extend({
  // Make this required (its nullish in the base schema)
  stpBridgePriority: z.number(),
  label: z.string(),
  description: z.string().nullish(),
  isConsoleEnabled: z.boolean(),
});
type ValidSwitchProfileParams = z.infer<typeof UpdateSwitchProfileInputSchema>;

const StyledForm = styled(Form, {
  display: 'contents',
  flexDirection: 'column',
  gap: '$8',
});

function SwitchConfig({ virtualDevice }: { virtualDevice: SwitchVirtualDevice }) {
  const network = useNetwork();
  const mutation = useGraphQLMutation(UpdateSwitchProfileMutation);
  const virtualDeviceMutation = useGraphQLMutation(updateVirtualDeviceMutation);
  const queryClient = useQueryClient();
  const closeDrawer = useCloseDrawerCallback();
  const showConsoleToggle = shouldShowConsoleToggle(
    useNosFeatureEnabled(NosFeature.SOSConsoleChallengeResponse),
  );

  const switchProfileUUID =
    // eslint-disable-next-line no-underscore-dangle
    virtualDevice.__typename === 'SwitchVirtualDevice' ? virtualDevice.switchProfile.UUID : '';
  const stpBridgePriority =
    // eslint-disable-next-line no-underscore-dangle
    virtualDevice.__typename === 'SwitchVirtualDevice'
      ? virtualDevice.switchProfile.stpBridgePriority
      : 32768;

  const handleSubmit = useCallback(
    (v: ValidSwitchProfileParams) => {
      mutation.mutate(
        { uuid: switchProfileUUID, input: { stpBridgePriority: v.stpBridgePriority } },
        {
          onSuccess: (response) => {
            notify('Successfully updated switch STP.', { variant: 'positive' });
            queryClient.invalidateQueries(
              makeQueryKey(SwitchQuery, { uuid: response.updateSwitchProfile.UUID }),
            );
            queryClient.invalidateQueries(
              makeQueryKey(SwitchesQuery, { networkUUID: network.UUID }),
            );
          },
          onError: (err) => {
            const gqlErr = getGraphQLError(err);
            notify(
              `There was an error updating STP on this switch${
                gqlErr?.message ? `: ${gqlErr.message}` : ''
              }.`,
              {
                variant: 'negative',
              },
            );
          },
        },
      );
      virtualDeviceMutation.mutate(
        {
          uuid: virtualDevice.UUID,
          input: {
            label: v.label,
            description: v.description,
            enableConsolePort: v.isConsoleEnabled,
          },
        },
        {
          onSuccess: (response) => {
            notify('Successfully updated switch.', { variant: 'positive' });
            queryClient.invalidateQueries(
              makeQueryKey(SwitchQuery, { uuid: response.updateVirtualDevice.UUID }),
            );
            queryClient.invalidateQueries(
              makeQueryKey(SwitchesQuery, { networkUUID: network.UUID }),
            );
          },
          onError: (err) => {
            const gqlErr = getGraphQLError(err);
            notify(
              `There was an error updating this switch${
                gqlErr?.message ? `: ${gqlErr.message}` : ''
              }.`,
              {
                variant: 'negative',
              },
            );
          },
        },
      );
    },
    [
      mutation,
      virtualDeviceMutation,
      network.UUID,
      queryClient,
      switchProfileUUID,
      virtualDevice.UUID,
    ],
  );

  return (
    <Formik<ValidSwitchProfileParams>
      validationSchema={toFormikValidationSchema(UpdateSwitchProfileInputSchema)}
      initialValues={{
        stpBridgePriority,
        label: virtualDevice.label,
        description: virtualDevice.description,
        isConsoleEnabled: virtualDevice.isConsoleEnabled,
      }}
      onSubmit={handleSubmit}
    >
      <StyledForm>
        <DrawerContent>
          <STPBridgePriorityField />
          <SwitchNameField />
          <SwitchDescriptionField />
          <IsPermitted
            isPermitted={({ permissions, nosFlags }) =>
              Boolean(
                permissions.hasPermission(PermissionType.PermNetworkDevicesWriteRestricted) &&
                  permissions.hasPermission(PermissionType.PermSwitchProfileWrite) &&
                  nosFlags[NosFeature.SOS],
              )
            }
            should404OnAccessDenied
          >
            <HardwareDeviceField
              virtualDevice={virtualDevice}
              onSuccess={async () => {
                queryClient.invalidateQueries(
                  makeQueryKey(SwitchesQuery, { networkUUID: network.UUID }),
                );
                return queryClient.invalidateQueries(
                  makeQueryKey(SwitchQuery, { uuid: virtualDevice.UUID }),
                );
              }}
              internal
            />
            {showConsoleToggle && <SwitchIsConsoleEnabledField />}
          </IsPermitted>
        </DrawerContent>
        <DrawerFooter
          actions={
            <>
              <Button type="button" onClick={closeDrawer} variant="secondary">
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </>
          }
        />
      </StyledForm>
    </Formik>
  );
}

export default function SwitchEdit({ uuid }: { uuid: string }) {
  const network = useNetwork();
  const closeDrawer = useCloseDrawerCallback();

  const virtualDevice = useGraphQL(SwitchQuery, { uuid }).data?.virtualDevice;
  expectDefinedOrThrow(virtualDevice, new ResourceNotFoundError('Switch not found'));
  if (virtualDevice.networkUUID !== network.UUID) {
    throw new ResourceNotFoundError('Switch not found');
  }

  return (
    <Drawer>
      <DrawerHeader
        icon="pencil"
        heading="Edit switch"
        actions={<SwitchActions view="edit" virtualDevice={virtualDevice} />}
        onClose={closeDrawer}
      />
      <SwitchConfig virtualDevice={virtualDevice} />
    </Drawer>
  );
}
