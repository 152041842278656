import {
  Alert,
  Button,
  CompositeField,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  FieldContainer,
  Link,
  PrimaryField,
  SecondaryField,
  Select,
  SelectItem,
  space,
  styled,
  Text,
  Textarea,
  TextInput,
  VStack,
} from '@meterup/atto';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  notify,
  ResourceNotFoundError,
} from '@meterup/common';
import { getGraphQLErrorMessageOrEmpty, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { Suspense, useCallback } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import type { UpdatePhyInterfaceInput } from '../../../../gql/graphql';
import type {
  SecurityAppliancePhyInterface,
  UpdateSecurityAppliancePhyInterfaceValues,
} from '../utils2';
import { paths } from '../../../../constants';
import { ClientAssignmentProtocol, MultiWanAlgorithm } from '../../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../../hooks/useNetworkFromPath';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../utils/withZodSchema';
import { updatePhyInterfaceMutation } from '../../../Devices/utils';
import { FieldProvider, ListFieldProvider, NumberFieldProvider } from '../../../Form/FieldProvider';
import { NumberField, TextareaField, TextField, ToggleField } from '../../../Form/Fields';
import { FormikConditional } from '../../../FormikConditional';
import { DrawerContentLoadingFallback } from '../../../Placeholders/DrawerLoadingFallback';
import { networkQuery, usePrimarySAPPProfileForNetwork } from '../../../Settings/Network/utils';
import { ForcedPortSpeedField } from '../../Switches/Fields';
import { portMaxSpeed } from '../../Switches/utils';
import {
  phyInterfacesForSecurityApplianceQuery,
  updateSecurityAppliancePhyInterfaceSchema,
} from '../utils2';
import { PortActions } from './PortActions';

const StyledForm = styled(Form, {
  display: 'contents',
  flexDirection: 'column',
  gap: '$8',
});

interface PortEditProps {
  phyInterface: SecurityAppliancePhyInterface;
}

function PortEdit({ phyInterface }: PortEditProps) {
  const companyName = useCurrentCompany();
  const currentNetwork = useNetwork();
  const sappProfile = usePrimarySAPPProfileForNetwork();

  const { network } = checkDefinedOrThrow(
    useGraphQL(networkQuery, { uuid: currentNetwork.UUID }).data,
  );

  const updatePhyInterface = useGraphQLMutation(updatePhyInterfaceMutation);

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    ({
      ipv4ClientAssignmentProtocol,
      ipv4ClientAddresses,
      ipv4ClientGateway,
      ipv4ClientPrefixLength,
      uplinkVLANID,
      uplinkPriority,
      ...values
    }: UpdateSecurityAppliancePhyInterfaceValues) => {
      const input: UpdatePhyInterfaceInput = {
        ...values,
        isTrunkPort: !values.isUplink,
        isBoundToAllVLANs: !values.isUplink,
        ipv4ClientAssignmentProtocol: null,
        ipv4ClientPrefixLength: null,
        ipv4ClientGateway: null,
        ipv4ClientAddresses: null,
        uplinkVLANID: null,
        uplinkPriority: null,
      };

      if (input.isUplink) {
        input.ipv4ClientAssignmentProtocol = ipv4ClientAssignmentProtocol;
        input.uplinkVLANID = uplinkVLANID;
        input.uplinkPriority = uplinkPriority;

        if (ipv4ClientAssignmentProtocol === ClientAssignmentProtocol.Static) {
          input.ipv4ClientAddresses = ipv4ClientAddresses;
          input.ipv4ClientGateway = ipv4ClientGateway;
          input.ipv4ClientPrefixLength = ipv4ClientPrefixLength;
        }
      }

      updatePhyInterface.mutate(
        { uuid: phyInterface.UUID, input },
        {
          onSuccess() {
            notify('Successfully updated port.', {
              variant: 'positive',
            });
            queryClient.invalidateQueries();
          },
          onError(err) {
            notify(`There was a problem updating this port${getGraphQLErrorMessageOrEmpty(err)}.`, {
              variant: 'negative',
            });
          },
        },
      );
    },
    [phyInterface.UUID, updatePhyInterface, queryClient],
  );

  return (
    <Formik<UpdateSecurityAppliancePhyInterfaceValues>
      initialValues={{
        label: phyInterface.label ?? '',
        description: phyInterface.description,
        forcedPortSpeedMbps: phyInterface.forcedPortSpeedMbps,
        isEnabled: phyInterface.isEnabled,
        isUplink: phyInterface.isUplink,
        ipv4ClientAssignmentProtocol:
          phyInterface.ipv4ClientAssignmentProtocol ?? ClientAssignmentProtocol.Dhcp,
        ipv4ClientGateway: phyInterface.ipv4ClientGateway ?? '',
        ipv4ClientPrefixLength: phyInterface.ipv4ClientPrefixLength ?? null,
        ipv4ClientAddresses: phyInterface.ipv4ClientAddresses,
        uplinkVLANID: phyInterface.uplinkVLANID,
        uplinkPriority: phyInterface.uplinkPriority,
      }}
      validate={withZodSchema(updateSecurityAppliancePhyInterfaceSchema)}
      onSubmit={handleSubmit}
    >
      <StyledForm>
        <DrawerContent>
          <ToggleField name="isEnabled" label="Enable" />
          <TextField
            name="label"
            label="Label"
            description="Overrides the port's default hardware label"
            optional
          />
          <TextareaField name="description" label="Description" optional />

          <ForcedPortSpeedField maxSpeed={portMaxSpeed(phyInterface)}>
            <FormikConditional<UpdateSecurityAppliancePhyInterfaceValues>
              condition={({ forcedPortSpeedMbps, isUplink }) =>
                forcedPortSpeedMbps !== phyInterface.forcedPortSpeedMbps && !!isUplink
              }
            >
              <Alert
                icon="attention"
                variant="attention"
                heading="Uplink speed change requires reboot"
                copy="Please reboot the security appliance after saving to apply this change."
                relation="stacked"
              />
            </FormikConditional>
          </ForcedPortSpeedField>

          <ToggleField name="isUplink" label="Uplink (WAN)" />

          {phyInterface.nativeVLAN && (
            <FormikConditional<UpdateSecurityAppliancePhyInterfaceValues>
              condition={({ isUplink }) => !isUplink}
            >
              <FieldContainer>
                <PrimaryField
                  label="Native VLAN"
                  element={
                    <Select width="100%" disabled value={phyInterface.nativeVLAN.UUID}>
                      <SelectItem key={phyInterface.nativeVLAN.UUID}>
                        {phyInterface.nativeVLAN.name}
                      </SelectItem>
                    </Select>
                  }
                />
              </FieldContainer>
            </FormikConditional>
          )}

          <FormikConditional<UpdateSecurityAppliancePhyInterfaceValues>
            condition={({ isUplink }) => !!isUplink}
          >
            <NumberField
              name="uplinkPriority"
              label="Uplink priority"
              description={
                <VStack spacing={space(8)}>
                  <span>
                    Uplinks with <Text weight="bold">lower</Text> priority will be favored if{' '}
                    <Link
                      as={ReactRouterLink}
                      to={makeLink(paths.pages.SettingsNetworkGeneralPage, {
                        companyName,
                        networkSlug: network.slug,
                      })}
                    >
                      network multi-WAN algorithm
                    </Link>{' '}
                    is first active.
                  </span>

                  {sappProfile.multiWANAlgorithm === MultiWanAlgorithm.RoundRobin && (
                    <Alert
                      heading="Round robin multi-WAN algorithm configured for network"
                      copy="Uplink priority will be ignored."
                      variant="attention"
                      type="inline"
                    />
                  )}
                </VStack>
              }
              defaultValue={null}
              optional
            />

            <FieldContainer>
              <PrimaryField
                label="IP configuration"
                element={null}
                controls={
                  <FieldProvider name="ipv4ClientAssignmentProtocol">
                    <CompositeField
                      label="Protocol"
                      element={
                        <Select placeholder="Select a protocol">
                          <SelectItem key={ClientAssignmentProtocol.Dhcp}>DHCP</SelectItem>
                          <SelectItem key={ClientAssignmentProtocol.Static}>Static</SelectItem>
                        </Select>
                      }
                    />
                  </FieldProvider>
                }
              />

              <FormikConditional<UpdateSecurityAppliancePhyInterfaceValues>
                condition={({ ipv4ClientAssignmentProtocol }) =>
                  ipv4ClientAssignmentProtocol === ClientAssignmentProtocol.Static
                }
              >
                <ListFieldProvider name="ipv4ClientAddresses">
                  <SecondaryField
                    label="IP addresses"
                    description="One IP addresses per line."
                    element={<Textarea />}
                  />
                </ListFieldProvider>

                <NumberFieldProvider name="ipv4ClientPrefixLength" defaultValue={null}>
                  <SecondaryField
                    label="IP address prefix length"
                    element={<TextInput width="40px" />}
                  />
                </NumberFieldProvider>

                <FieldProvider name="ipv4ClientGateway">
                  <SecondaryField label="Gateway address" element={<TextInput width="120px" />} />
                </FieldProvider>
              </FormikConditional>
            </FieldContainer>
          </FormikConditional>
          <FormikConditional<UpdateSecurityAppliancePhyInterfaceValues>
            condition={({ isUplink }) => !!isUplink}
          >
            <FieldContainer>
              <NumberField label="VLAN ID" name="uplinkVLANID" />
            </FieldContainer>
          </FormikConditional>
        </DrawerContent>
        <DrawerFooter
          actions={
            <>
              <Button type="button" onClick={useCloseDrawerCallback()} variant="secondary">
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </>
          }
        />
      </StyledForm>
    </Formik>
  );
}

interface PortEditDrawerProps {
  phyInterfaceUUID: string;
  virtualDeviceUUID: string;
}

export default function PortEditDrawer(props: PortEditDrawerProps) {
  const closeDrawer = useCloseDrawerCallback();

  const phyInterfaces = useGraphQL(phyInterfacesForSecurityApplianceQuery, {
    virtualDeviceUUID: props.virtualDeviceUUID,
  }).data?.phyInterfacesForVirtualDevice;
  expectDefinedOrThrow(phyInterfaces, new ResourceNotFoundError('Ports not found for device.'));

  const phyInterface = phyInterfaces.find((p) => p.UUID === props.phyInterfaceUUID);
  expectDefinedOrThrow(phyInterface, new ResourceNotFoundError('Port not found.'));

  return (
    <Drawer>
      <DrawerHeader
        onClose={closeDrawer}
        icon="pencil"
        heading={
          phyInterface.label
            ? `Edit port ${phyInterface.label}`
            : `Edit port ${phyInterface.portNumber}`
        }
        actions={
          <PortActions
            virtualDeviceUUID={props.virtualDeviceUUID}
            phyInterfaceUUID={props.phyInterfaceUUID}
            view="edit"
          />
        }
      />
      <Suspense fallback={<DrawerContentLoadingFallback />}>
        <PortEdit phyInterface={phyInterface} />
      </Suspense>
    </Drawer>
  );
}
