import type { FormikHelpers } from 'formik';
import {
  Body,
  Column,
  CopyBox,
  EmptyState,
  Pane,
  PaneContent,
  PaneHeader,
  Tab,
} from '@meterup/atto';
import {
  AutoTable,
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  ResourceNotFoundError,
} from '@meterup/common';
import { useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';

import type { CaptivePortal as TCaptivePortal, CaptivePortalInputSchemaType } from './schema';
import { paths } from '../../../constants';
import { type CapoActivityQueryQuery, PermissionType } from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature } from '../../../hooks/useNosFeatures';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { useNavigateBack, useNavigateHome, useNetworkWideCrumbs } from '../../../utils/routing';
import { isoStringToRelativeTime } from '../../../utils/time';
import { withZodSchema } from '../../../utils/withZodSchema';
import { getDurationHours } from '../../Insights/Network/utils';
import IsPermitted from '../../permissions/IsPermitted';
import { ReactRouterLink } from '../../ReactRouterLink';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import CaptivePortalFilters from './CaptivePortalFilters';
import CaptivePortalForm from './CaptivePortalForm';
import CaptivePortalPreview from './CaptivePortalPreview';
import { capoActivityQuery, updateCaptivePortalMutation } from './queries';
import { CaptivePortalInputSchema, captivePortalToInput } from './schema';
import { parseAllowedHostsInputToRecord, useHandleUpdate } from './utils';

type CaptivePortalEditProps = {
  captivePortal: TCaptivePortal;
};

type CaptivePortalClient = CapoActivityQueryQuery['captivePortalAuthorizedClients'][number];

const builder = createColumnBuilder<CaptivePortalClient>();

const columns = [
  builder.data((row) => row.timestamp, {
    id: 'timestamp',
    header: 'Time',
    cell: ({ value }) => <Body>{isoStringToRelativeTime(value)}</Body>,
  }),
  builder.data((row) => row.ipAddress, {
    id: 'ip-address',
    header: 'IP address',
    cell: ({ value }) => (
      <CopyBox aria-label="Copy IP address" value={value}>
        <Body family="monospace">{value}</Body>
      </CopyBox>
    ),
  }),
  builder.data((row) => row.macAddress, {
    id: 'mac-address',
    header: 'MAC address',
    cell: ({ value }) => (
      <CopyBox aria-label="Copy MAC address" value={value}>
        <Body family="monospace">{value}</Body>
      </CopyBox>
    ),
  }),
  builder.data((row) => (row.isAuthorized ? 'Authenticated' : 'Authenticating'), {
    id: 'authentication',
    header: 'Authentication',
    cell: ({ value }) => <Body>{value ? 'Authenticated' : 'Authenticating'}</Body>,
  }),
  builder.data((row) => row.rawUserAgent, {
    id: 'user-agent',
    header: 'User agent',
    cell: ({ value }) => (
      <CopyBox aria-label="Copy user agent" value={value}>
        <Body family="monospace">{value}</Body>
      </CopyBox>
    ),
  }),
];

function CAPOActivity() {
  const network = useNetwork();
  const [currentTimePeriodOrUndefined] = useSearchParamsState<string>('timePeriod', '24h');
  const currentTimePeriod = currentTimePeriodOrUndefined ?? '24h';
  const { data: capoStats } = useGraphQL(
    capoActivityQuery,
    {
      networkUUID: network.UUID,
      numHoursLookback: getDurationHours(currentTimePeriod),
    },
    { refetchInterval: 30 * 1000 },
  );

  expectDefinedOrThrow(
    capoStats?.captivePortalAuthorizedClients,
    new ResourceNotFoundError('No activity'),
  );

  return (
    <Column gutter="bottom" width="remaining">
      {capoStats.captivePortalAuthorizedClients.length ? (
        <AutoTable
          size="small"
          key="capoActivity"
          columns={columns}
          data={capoStats.captivePortalAuthorizedClients}
        />
      ) : (
        <EmptyState icon="log" heading="No recent activity" />
      )}
    </Column>
  );
}

function CAPOTabs() {
  const { tab } = checkDefinedOrThrow(Nav.useRegionParams('root', paths.pages.CaptivePortalPage));
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const navigate = useNavigate();

  const navigateToTab = (newTab: string) => {
    navigate(
      makeLink(paths.pages.CaptivePortalPage, {
        companyName,
        networkSlug: network.slug,
        tab: newTab,
      }),
    );
  };

  return (
    <>
      <IsPermitted permissions={PermissionType.PermCaptivePortalWrite}>
        <Tab
          icon="client"
          selected={tab === 'configure'}
          onClick={() => navigateToTab('configure')}
        >
          Configure
        </Tab>
      </IsPermitted>
      <IsPermitted
        allowOperatorAlways={false}
        isPermitted={({ nosFlags }) => nosFlags[NosFeature.CAPTIVE_PORTAL_AUTH_EVENTS]}
      >
        <Tab icon="log" selected={tab === 'activity'} onClick={() => navigateToTab('activity')}>
          Activity
        </Tab>
      </IsPermitted>
    </>
  );
}

export default function CaptivePortalEdit({ captivePortal }: CaptivePortalEditProps) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const networkWideCrumb = useNetworkWideCrumbs();
  const { tab } = checkDefinedOrThrow(Nav.useRegionParams('root', paths.pages.CaptivePortalPage));
  const updateCaptivePortal = useGraphQLMutation(updateCaptivePortalMutation);
  const initialValues = useMemo<CaptivePortalInputSchemaType>(
    () => captivePortalToInput(captivePortal),
    [captivePortal],
  );
  const handleMutation = useHandleUpdate({
    action: 'update',
    networkUUID: network.UUID,
  });
  const onSubmit = useCallback(
    (values: CaptivePortalInputSchemaType, form: FormikHelpers<CaptivePortalInputSchemaType>) => {
      const {
        callToAction,
        displaySettings,
        logoImageS3Key,
        redirectURL,
        termsAndConditions,
        vlanUUIDs,
        allowedHosts: rawAllowedHosts,
        authLifetimeSec,
        externalPortalURL,
        isExternal,
        isEnabled,
      } = values;
      const { allowedHosts } = parseAllowedHostsInputToRecord(rawAllowedHosts);
      handleMutation(
        updateCaptivePortal.mutateAsync({
          uuid: captivePortal.UUID,
          input: {
            callToAction,
            displaySettings,
            logoImageS3Key,
            redirectURL,
            termsAndConditions:
              !termsAndConditions || termsAndConditions === '' ? null : termsAndConditions,
            vlanUUIDs,
            ...(isExternal && externalPortalURL ? { externalPortalURL } : {}),
            ...{ allowedHosts },
            externalPortalURL,
            authLifetimeSec,
            name: 'default',
            isEnabled,
          },
        }),
        form,
      ).finally(() => {
        form.setSubmitting(false);
      });
    },
    [handleMutation, updateCaptivePortal, captivePortal.UUID],
  );

  return (
    <Formik<CaptivePortalInputSchemaType>
      validate={withZodSchema(CaptivePortalInputSchema)}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Form style={{ display: 'contents' }}>
        <Pane layoutMode="detailed">
          <PaneHeader
            back={back}
            home={home}
            crumbs={[
              ...networkWideCrumb,
              {
                type: 'page',
                page: {
                  as: ReactRouterLink,
                  to: makeLink(paths.pages.CaptivePortalsPage, {
                    companyName,
                    networkSlug: network.slug,
                  }),
                  label: 'Captive portals',
                },
              },
            ]}
            icon="captive-portal"
            heading="Captive portal"
            tabs={<CAPOTabs />}
            views={tab === 'activity' && <CaptivePortalFilters />}
          />
          <PaneContent>
            <IsPermitted permissions={PermissionType.PermCaptivePortalWrite}>
              {tab === 'configure' && <CaptivePortalPreview />}
            </IsPermitted>
            {tab === 'activity' && <CAPOActivity />}
          </PaneContent>
        </Pane>
        <IsPermitted permissions={PermissionType.PermCaptivePortalWrite}>
          {tab === 'configure' && (
            <CaptivePortalForm
              networkUUID={network.UUID}
              captivePortal={captivePortal && captivePortal}
            />
          )}
        </IsPermitted>
      </Form>
    </Formik>
  );
}
