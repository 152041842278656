import type { OverlayTriggerState } from '@meterup/atto';
import {
  Alert,
  Button,
  ComboBox,
  ComboBoxItem,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  FieldContainer,
  Label,
  PrimaryField,
  PrimaryToggleField,
  SecondaryField,
  SecondaryFieldFields,
  Select,
  SelectItem,
  Small,
  space,
  styled,
  Text,
  TextInput,
  ToggleInput,
  VStack,
} from '@meterup/atto';
import { SecondaryFieldLabel } from '@meterup/atto/src/controls/Field/common/FieldLabel';
import { IsOperator } from '@meterup/authorization';
import { notify } from '@meterup/common';
import {
  getGraphQLError,
  getGraphQLErrorMessageOrEmpty,
  GraphQLErrorBoundary,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import type { CreateNetworkInput, UpdateNetworkInput } from '../../../gql/graphql';
import type { Company } from '../../../hooks/useCompany';
import type { EditNetworkFormValues, Network } from './utils';
import { paths } from '../../../constants';
import { COUNTRIES } from '../../../countries';
import { useCompany } from '../../../hooks/useCompany';
import { networksForCompany, useNetworksForCompany } from '../../../hooks/useNetworksForCompany';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';
import { sluggify, ucfirst } from '../../../utils/strings';
import { withZodSchema } from '../../../utils/withZodSchema';
import { CopyableMonoOrNoValue } from '../../Devices/Insights';
import { FieldProvider } from '../../Form/FieldProvider';
import {
  createNetworkMutation,
  createNetworkOnboardingMutation,
  editNetworkFormValues,
  jobTrackersQuery,
  networkQuery,
  updateNetworkMutation,
} from './utils';

const AddressLineFieldContainer = styled('div', {
  [`& ${SecondaryFieldLabel}`]: {
    whiteSpace: 'nowrap',
  },
  [`& ${SecondaryFieldFields}`]: {
    flexShrink: 1,
    width: '20rem',
  },
});

function SlugField({ internal }: { internal?: boolean }) {
  const { values, setFieldValue, setFieldTouched, touched, initialValues } =
    useFormikContext<EditNetworkFormValues>();

  const [isEditable, setEditable] = useState(false);

  useEffect(() => {
    if ((!touched.slug || !isEditable) && initialValues.slug === '') {
      setFieldValue('slug', sluggify(values.label));
      setFieldTouched('slug', false);
    }
  }, [values.label, touched.slug, isEditable, initialValues.slug, setFieldValue, setFieldTouched]);

  return (
    <FieldContainer>
      <FieldProvider name="slug">
        <PrimaryField
          internal={internal}
          label={
            <>
              Slug <Small>(URL identifier)</Small>
            </>
          }
          description="A unique idendifier for the network used in dashboard URLs. Based on label if unset."
          element={<TextInput disabled={!isEditable} />}
          controls={
            <Label>
              <Small>Customize</Small>
              <ToggleInput selected={isEditable} onChange={setEditable} controlSize="small" />
            </Label>
          }
        />
      </FieldProvider>
    </FieldContainer>
  );
}

function MailingAddressField({
  company,
  network,
  internal,
}: {
  company?: Company;
  network?: Network;
  internal?: boolean;
}) {
  const [expandMailingAddress, setExpandMailingAddress] = useState<boolean>(() => !network);

  return (
    <FieldContainer>
      <PrimaryField
        description={
          company?.isCustomer
            ? 'For customer networks, these fields are synced from Salesforce data.'
            : undefined
        }
        internal={internal}
        label="Mailing address"
        element={null}
        controls={
          network ? (
            <Button
              icon={expandMailingAddress ? 'chevron-down' : 'chevron-right'}
              arrangement="hidden-label"
              onClick={() => {
                setExpandMailingAddress((prev) => !prev);
              }}
              variant="secondary"
            >
              {expandMailingAddress ? 'Collapse mailing address' : 'Expand mailing address'}
            </Button>
          ) : null
        }
      />
      {(!network || expandMailingAddress) && (
        <>
          <AddressLineFieldContainer>
            <FieldProvider name="mailingAddressInput.line1">
              <SecondaryField
                internal={internal}
                label="Line 1"
                element={<TextInput width="100%" disabled={company?.isCustomer} />}
              />
            </FieldProvider>
            <FieldProvider name="mailingAddressInput.line2">
              <SecondaryField
                internal={internal}
                label="Line 2"
                element={<TextInput width="100%" disabled={company?.isCustomer} />}
              />
            </FieldProvider>
          </AddressLineFieldContainer>

          <FieldProvider name="mailingAddressInput.city">
            <SecondaryField
              internal={internal}
              label="City"
              element={<TextInput disabled={company?.isCustomer} />}
            />
          </FieldProvider>
          <FieldProvider name="mailingAddressInput.subdivisionCode">
            <SecondaryField
              internal={internal}
              label="State"
              element={<TextInput disabled={company?.isCustomer} />}
            />
          </FieldProvider>
          <FieldProvider name="mailingAddressInput.postalCode">
            <SecondaryField
              internal={internal}
              label="Postal code"
              element={<TextInput disabled={company?.isCustomer} />}
            />
          </FieldProvider>
          <FieldProvider name="mailingAddressInput.countryISO2">
            <SecondaryField
              internal={internal}
              label="Country"
              element={
                <Select placeholder="Select a country" disabled={Boolean(company?.isCustomer)}>
                  {COUNTRIES.map(({ name, code }) => (
                    <SelectItem key={code}>{name}</SelectItem>
                  ))}
                </Select>
              }
            />
          </FieldProvider>
        </>
      )}
    </FieldContainer>
  );
}

const schemaValidator = withZodSchema(editNetworkFormValues);

export function ErrorAlert({ error, heading }: { error: Error; heading: string }) {
  const graphqlError = getGraphQLError(error);

  return (
    <Alert
      variant="negative"
      icon="warning"
      heading={heading}
      copy={graphqlError ? ucfirst(graphqlError.message) : null}
    />
  );
}

function JobTrackerField({ network }: { network: Network }) {
  const jobTrackers = useGraphQL(jobTrackersQuery).data?.jobTrackers ?? [];

  const networkHasJobData = !!network.onboarding?.jobData;

  return (
    <FieldContainer>
      <FieldProvider name="jobTrackerID">
        <PrimaryField
          label="Onboarding job"
          description="From Airtable"
          element={
            <ComboBox canClearValue={!networkHasJobData} disabled={networkHasJobData}>
              {jobTrackers.map((jobTracker) => (
                <ComboBoxItem key={jobTracker.id} textValue={jobTracker.jobID ?? undefined}>
                  {jobTracker.jobID ?? `(${jobTracker.id})`}
                </ComboBoxItem>
              ))}
            </ComboBox>
          }
          optional={!networkHasJobData}
        />
      </FieldProvider>
    </FieldContainer>
  );
}

export default function EditNetwork<C extends React.ElementType>({
  network,
  container,
  containerProps,
  actions,
  internal,
  showErrorsInline = false,
  onSuccess,
}: {
  network?: Network;
  container?: C;
  containerProps?: React.ComponentPropsWithoutRef<C>;
  actions: React.ReactNode;
  internal?: boolean;
  showErrorsInline?: boolean;
  onSuccess?: (resp: { UUID: string; slug: string }) => void;
}) {
  const company = useCompany(useCurrentCompany())!;
  const companySlug = company!.slug;
  const companyNetworks = useNetworksForCompany(companySlug);

  const createNetwork = useGraphQLMutation(createNetworkMutation);
  const updateNetwork = useGraphQLMutation(updateNetworkMutation);
  const createNetworkOnboarding = useGraphQLMutation(createNetworkOnboardingMutation);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const Container = container ?? 'div';

  const handleValidate = useCallback(
    (values: EditNetworkFormValues) => {
      const validation = schemaValidator(values);

      if (
        !validation.slug &&
        companyNetworks.some((n) => n.UUID !== network?.UUID && n.slug === values.slug)
      ) {
        validation.slug =
          'Slug is already used by another company network, please provide another.';
      }

      if (
        !validation.label &&
        companyNetworks.some((n) => n.UUID !== network?.UUID && n.label === values.label)
      ) {
        validation.label =
          'Label is already used by another company network, please provide another.';
      }

      return validation;
    },
    [companyNetworks, network?.UUID],
  );

  const networkHasJob = !!network?.onboarding?.jobData?.id;

  const handleSubmit = useCallback(
    ({ isTemplate, isUpgradeSensitive, jobTrackerID, ...values }: EditNetworkFormValues) => {
      if (network?.UUID) {
        const input: UpdateNetworkInput = {
          ...values,
          isUpgradeSensitive,
        };

        if (network?.isCustomer) {
          // Don't allow these fields to be set for customer networks
          delete input.mailingAddressInput;
          delete input.isActive;
        }

        if (jobTrackerID && !networkHasJob) {
          createNetworkOnboarding.mutate(
            { networkUUID: network.UUID, jobTrackerID },
            {
              onError(err) {
                notify(
                  `There was a problem updating network job${getGraphQLErrorMessageOrEmpty(err)}`,
                  {
                    variant: 'negative',
                  },
                );
              },
            },
          );
        }

        updateNetwork.mutate(
          {
            uuid: network.UUID,
            input,
          },
          {
            onSuccess: (resp) => {
              onSuccess?.(resp.updateNetwork);
              notify('Successfully updated network.', {
                variant: 'positive',
              });

              queryClient.invalidateQueries(makeQueryKey(networkQuery, { uuid: network?.UUID }));
              queryClient
                .invalidateQueries(makeQueryKey(networksForCompany, { companySlug }))
                .then(() => {
                  if (resp.updateNetwork.slug !== network?.slug) {
                    navigate(
                      makeLink(paths.pages.SettingsNetworkGeneralPage, {
                        companyName: companySlug,
                        networkSlug: resp.updateNetwork.slug,
                      }),
                    );
                  }
                });
            },
            onError: (err) => {
              notify(
                `There was a problem updating this network${getGraphQLErrorMessageOrEmpty(err)}`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      } else {
        const input: CreateNetworkInput = {
          ...values,
          companySlug,
          isTemplate,
        };

        createNetwork.mutate(
          {
            input,
          },
          {
            onSuccess: (resp) => {
              notify('Successfully created network.', {
                variant: 'positive',
              });
              onSuccess?.(resp.createNetwork);
            },
            onError: showErrorsInline
              ? undefined
              : (err) => {
                  notify(
                    `There was a problem creating this network${getGraphQLErrorMessageOrEmpty(err)}`,
                    {
                      variant: 'negative',
                    },
                  );
                },
          },
        );
      }
    },
    [
      showErrorsInline,
      createNetwork,
      createNetworkOnboarding,
      updateNetwork,
      network?.UUID,
      network?.isCustomer,
      network?.slug,
      networkHasJob,
      queryClient,
      companySlug,
      navigate,
      onSuccess,
    ],
  );

  return (
    <Formik<EditNetworkFormValues>
      initialValues={{
        label: network?.label ?? '',
        slug: network?.slug ?? '',
        isActive: network?.isActive ?? true,
        isTemplate: network?.isTemplate ?? false,
        isConfig1WosUpgradesEnabled: network?.isConfig1WosUpgradesEnabled ?? true,
        isUpgradeSensitive: network?.isUpgradeSensitive ?? false,
        mailingAddressInput: {
          line1: network?.mailingAddress?.line1 ?? '',
          line2: network?.mailingAddress?.line2 ?? '',
          city: network?.mailingAddress?.city ?? '',
          subdivisionCode: network?.mailingAddress?.subdivisionCode ?? '',
          postalCode: network?.mailingAddress?.postalCode ?? '',
          countryISO2: network?.mailingAddress?.countryISO2 ?? 'US',
        },
        jobTrackerID: network?.onboarding?.jobData?.id ?? null,
      }}
      validate={handleValidate}
      onSubmit={handleSubmit}
    >
      <Form>
        <Container {...containerProps}>
          <VStack spacing={space(12)}>
            <FieldContainer>
              <FieldProvider name="label">
                <PrimaryField internal={internal} label="Label" element={<TextInput />} />
              </FieldProvider>
            </FieldContainer>

            <SlugField internal={internal} />

            <MailingAddressField company={company} internal={internal} network={network} />

            {network && (
              <GraphQLErrorBoundary fallback={() => null}>
                <Suspense fallback={null}>
                  <JobTrackerField network={network} />
                </Suspense>
              </GraphQLErrorBoundary>
            )}

            <FieldContainer>
              <FieldProvider name="isActive">
                <PrimaryToggleField
                  disabled={company?.isCustomer}
                  internal={internal}
                  label="Active"
                  description="If unset, network will not appear in the location selector by default and will be assumed to be unused. For customer networks, this field is auto-synced from Salesforce data."
                />
              </FieldProvider>
            </FieldContainer>
            <FieldContainer>
              <FieldProvider name="isConfig1WosUpgradesEnabled">
                <PrimaryToggleField
                  internal={internal}
                  label="Config 1 WOS upgrades enabled"
                  description={
                    <>
                      If set, config 2 controllers will perform legacy behavior to enable upgrades
                      for config 1 APs. Requires a VLAN with ID{' '}
                      <Text family="monospace" size={12}>
                        2
                      </Text>
                      , IP <CopyableMonoOrNoValue value="10.102.0.2" label="VLAN IP" size={12} />{' '}
                      and DHCP enabled.
                    </>
                  }
                />
              </FieldProvider>
            </FieldContainer>
            <IsOperator>
              <FieldContainer>
                <FieldProvider name="isUpgradeSensitive">
                  <PrimaryToggleField
                    internal
                    label="Upgrade sensitive"
                    description={
                      <>
                        Able to be used as a filter tag for bulk upgrader
                        {/* TODO - depends on DASH-3401 */}
                        {/* <AttoLink
                            as={Link}
                            to={makeLink(window.location, paths.pages.FirmwareBulkUpgraderPage, {})}
                          >
                            bulk upgrader
                          </AttoLink> */}
                      </>
                    }
                  />
                </FieldProvider>
              </FieldContainer>
            </IsOperator>

            {showErrorsInline && createNetwork.isError && createNetwork.error && (
              <ErrorAlert
                error={createNetwork.error}
                heading="There was a problem creating this network"
              />
            )}
            {showErrorsInline && updateNetwork.isError && updateNetwork.error && (
              <ErrorAlert
                error={updateNetwork.error}
                heading="There was a problem creating this network"
              />
            )}
          </VStack>
        </Container>
        {actions}
      </Form>
    </Formik>
  );
}

type RespType = { UUID: string; slug: string };

export function CreateNetworkDialog({
  state,
  onSuccess,
}: {
  state: OverlayTriggerState;
  onSuccess?: (resp: RespType) => void;
}) {
  const { close } = state;
  const handleSuccess = useCallback(
    (resp: RespType) => {
      onSuccess?.(resp);
      close();
    },
    [close, onSuccess],
  );

  return (
    <Dialog state={state}>
      <DialogHeader icon="plus" heading="Add network" />
      <EditNetwork
        container={DialogContent}
        containerProps={{ gutter: 'all' }}
        showErrorsInline
        onSuccess={handleSuccess}
        actions={
          <DialogFooter
            actions={
              <>
                <Button type="button" variant="secondary" onClick={state.close}>
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Add network
                </Button>
              </>
            }
          />
        }
      />
    </Dialog>
  );
}
