import type { OverlayTriggerState } from 'react-stately';
import {
  Alert,
  Body,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  useDialogState,
} from '@meterup/atto';
import { notify, ResourceNotFoundError } from '@meterup/common';
import {
  getGraphQLError,
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { capitalize } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useIsActiveMatcher } from '../../../hooks/useIsActive';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import DeviceConfigEditorDialog from '../../../routes/pages/network/operators/device_config/DeviceConfigEditorDialog';
import { makeDrawerLink, makeLink } from '../../../utils/main_and_drawer_navigation';
import ConsoleAccessDialog from '../../Devices/ConsoleAccess';
import { blinkLEDsMutation, refreshConfigMutation } from '../../Devices/utils';
import { uplinkPhyInterfacesQuery } from '../../Firewall/utils';
import IsPermitted from '../../permissions/IsPermitted';
import { ReactRouterLink } from '../../ReactRouterLink';
import { copyUplinkPhyInterfacesMutation, Mono } from '../../Settings/Network/utils';
import SecurityApplianceDeleteDialog from './SecurityApplianceDeleteDialog';
import { SecurityApplianceRebootDialog } from './SecurityApplianceRebootDialog';
import useCurrentControllers from './useCurrentControllers';
import {
  ControllerQuery,
  ControllersForSecurityApplianceQuery,
  ControllersQuery,
  HAConfigForNetworkQuery,
  PhyInterfacesForVirtualDevice,
} from './utils';
import { phyInterfacesForSecurityApplianceQuery, SecurityApplianceDetailsTab } from './utils2';

type SecurityApplianceActionsProps = {
  virtualDeviceUUID: string;
  view: 'detail' | 'drawer' | 'edit' | 'list';
};

export function CopyNetworkUplinksDescription() {
  return (
    <>
      Copies the WAN configuration from the <Mono>wan0</Mono> and <Mono>wan1</Mono> sections of the
      config. Supports enabling DHCP on the uplink if <Mono>enable-dhcp-client</Mono> is set, or
      otherwise copying the <Mono>gateway</Mono> and <Mono>vlanid</Mono> fields, and the first item
      of the <Mono>ip-addresses</Mono> field.
    </>
  );
}

function CopyNetworkUplinksDialog({ state }: { state: OverlayTriggerState }) {
  const network = useNetwork();
  const copyUplinks = useGraphQLMutation(copyUplinkPhyInterfacesMutation, {
    useErrorBoundary: false,
  });

  const virtualDevices = useGraphQL(ControllersQuery, { networkUUID: network.UUID }).data
    ?.virtualDevicesForNetwork;

  const controllers = useMemo(() => virtualDevices ?? [], [virtualDevices]);

  const queryClient = useQueryClient();

  const { close } = state;

  const handleCopy = useCallback(() => {
    copyUplinks.mutate(
      { networkUUID: network.UUID },
      {
        onSuccess() {
          queryClient.invalidateQueries(
            makeQueryKey(ControllersForSecurityApplianceQuery, { networkUUID: network.UUID }),
          );
          queryClient.invalidateQueries(
            makeQueryKey(uplinkPhyInterfacesQuery, { networkUUID: network.UUID }),
          );
          for (const controller of controllers) {
            queryClient.invalidateQueries(
              makeQueryKey(phyInterfacesForSecurityApplianceQuery, {
                virtualDeviceUUID: controller.UUID,
              }),
            );
            queryClient.invalidateQueries(
              makeQueryKey(PhyInterfacesForVirtualDevice, { virtualDeviceUUID: controller.UUID }),
            );
          }
          close();
          notify('Uplinks copied successfully.', {
            variant: 'positive',
          });
        },
        // Error is shown inside the dialog below
      },
    );
  }, [copyUplinks, network.UUID, queryClient, controllers, close]);

  const graphqlError = copyUplinks.error ? getGraphQLError(copyUplinks.error) : undefined;

  return (
    <Dialog state={state}>
      <DialogHeader icon="globe" heading="Copy network uplinks from config 1" />
      <DialogContent gutter="all">
        <Body>
          <CopyNetworkUplinksDescription />
        </Body>

        {copyUplinks.isError && (
          <Alert
            variant="negative"
            icon="attention"
            heading="Error copying network uplinks"
            copy={graphqlError ? capitalize(graphqlError?.message) : undefined}
          />
        )}
      </DialogContent>
      <DialogFooter
        actions={
          <>
            <Button variant="secondary" onClick={state.close} size="medium">
              Cancel
            </Button>
            <Button variant="primary" onClick={handleCopy} size="medium">
              Copy
            </Button>
          </>
        }
      />
    </Dialog>
  );
}

export function AddSecurityApplianceButton() {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  return (
    <Button
      arrangement="leading-icon"
      icon="plus"
      as={ReactRouterLink}
      to={makeDrawerLink(window.location, paths.drawers.SecurityApplianceCreateDrawerPage, {
        companyName,
        networkSlug: network.slug,
      })}
      variant="secondary"
      internal
    >
      Add
    </Button>
  );
}

export function SecurityApplianceActions({
  virtualDeviceUUID,
  view,
}: SecurityApplianceActionsProps) {
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const navigate = useNavigate();
  const flashLEDs = useGraphQLMutation(blinkLEDsMutation);
  const refreshConfig = useGraphQLMutation(refreshConfigMutation);
  const configDialogState = useDialogState();
  const deleteDialogState = useDialogState();
  const { state: rebootDialogState } = useDialogState();
  const { state: copyNetworkUplinksDialogState } = useDialogState();
  const { state: consoleAccessDialogState } = useDialogState();
  const supportsConsoleAccess = useNosFeatureEnabled(NosFeature.COSConsoleChallengeResponse);

  const { virtualDevices } = useCurrentControllers();
  const highAvailabilityConfigQuery = useGraphQL(HAConfigForNetworkQuery, {
    networkUUID: network.UUID,
  });
  const highAvailabilityConfig = highAvailabilityConfigQuery.data?.network?.highAvailabilityConfig;

  const isPathActive = useIsActiveMatcher();

  const virtualDevice = useGraphQL(ControllerQuery, { uuid: virtualDeviceUUID }).data
    ?.virtualDevice;
  if (virtualDevice?.__typename !== 'ControllerVirtualDevice') {
    throw new ResourceNotFoundError('Security appliance not found.');
  }

  const { hardwareDevice } = virtualDevice;
  if (hardwareDevice && hardwareDevice.__typename !== 'ControllerHardwareDevice') {
    throw new ResourceNotFoundError('Security appliance not found.');
  }

  const isOffline = !hardwareDevice?.isConnectedToBackend;

  const serialNumber = hardwareDevice?.serialNumber;
  const { mutate: mutateFlashLEDs } = flashLEDs;
  const handleFlashLEDs = useCallback(() => {
    if (!serialNumber) return;

    mutateFlashLEDs(
      { serialNumber },
      {
        onSuccess() {
          notify('Request sent to flash LEDs on this security appliance.', {
            variant: 'positive',
          });
        },
        onError(err) {
          notify(
            `There was a problem sending the LED flash request${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [serialNumber, mutateFlashLEDs]);

  const { mutate: mutateRefreshConfig } = refreshConfig;
  const handleRefreshConfig = useCallback(() => {
    if (!serialNumber) return;

    mutateRefreshConfig(
      { serialNumber },
      {
        onSuccess() {
          notify('Request sent to security appliance to pull its config.', {
            variant: 'positive',
          });
        },
        onError(err) {
          notify(
            `There was a problem sending the config refresh request${getGraphQLErrorMessageOrEmpty(err)}.`,
            {
              variant: 'negative',
            },
          );
        },
      },
    );
  }, [serialNumber, mutateRefreshConfig]);

  const openDetails = () => {
    navigate(
      makeLink(paths.pages.SecurityApplianceDetailPage, {
        companyName,
        networkSlug: network.slug,
        uuid: virtualDeviceUUID,
        tab: SecurityApplianceDetailsTab.Insights,
      }),
    );
  };

  const navigateToHA = () => {
    navigate(
      makeDrawerLink(window.location, paths.drawers.HighAvailabilityEditDrawerPage, {
        virtualDeviceUUID,
        networkSlug: network.slug,
        companyName,
      }),
    );
  };

  return (
    <>
      <IsPermitted
        isPermitted={({ permissions, nosFlags }) =>
          Boolean(
            permissions.hasPermission(PermissionType.PermNetworkDevicesWrite) &&
              nosFlags[NosFeature.COS2],
          )
        }
      >
        {view !== 'edit' && (
          <>
            <IsPermitted
              isPermitted={({ permissions, nosFlags }) =>
                Boolean(
                  permissions.hasPermission(PermissionType.PermVirtualDeviceCreate) &&
                    permissions.hasPermission(PermissionType.PermNetworkDevicesReadRestricted) &&
                    nosFlags[NosFeature.COS2],
                )
              }
            >
              <AddSecurityApplianceButton />
            </IsPermitted>
            <IsPermitted
              isPermitted={({ permissions, nosFlags }) =>
                Boolean(
                  permissions.hasPermission(PermissionType.PermNetworkDevicesWrite) &&
                    nosFlags[NosFeature.COS2],
                )
              }
            >
              <Button
                arrangement="leading-icon"
                icon="pencil"
                as={ReactRouterLink}
                to={makeDrawerLink(window.location, paths.drawers.SecurityApplianceEditDrawerPage, {
                  virtualDeviceUUID,
                  networkSlug: network.slug,
                  companyName,
                })}
                variant="secondary"
              >
                Edit
              </Button>
            </IsPermitted>
          </>
        )}
      </IsPermitted>
      <IsPermitted
        isPermitted={({ permissions }) =>
          view !== 'detail' ||
          permissions.hasPermission(PermissionType.PermHighAvailabilityWrite) ||
          permissions.hasPermission(PermissionType.PermNetworkDevicesWrite) ||
          permissions.hasPermission(PermissionType.PermHardwareDeviceRpcBlinkLeds) ||
          permissions.hasPermission(PermissionType.PermNetworkDevicesWriteRestricted)
        }
      >
        <DropdownMenu>
          <DropdownMenuButton
            variant="secondary"
            icon="overflow-horizontal"
            arrangement="hidden-label"
          >
            Actions
          </DropdownMenuButton>
          <DropdownMenuPopover align="end">
            {view !== 'detail' &&
              !isPathActive({
                path: paths.pages.SecurityApplianceDetailPage,
                end: false,
              }) && (
                <DropdownMenuGroup>
                  <DropdownMenuItem icon="security-appliance" onSelect={openDetails}>
                    View details
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              )}

            <IsPermitted
              anyPermissions={[
                PermissionType.PermHighAvailabilityRead,
                PermissionType.PermHighAvailabilityWrite,
              ]}
            >
              <DropdownMenuGroup>
                <DropdownMenuItem
                  icon="security-appliance"
                  onSelect={navigateToHA}
                  disabled={virtualDevices.length < 2}
                >
                  {highAvailabilityConfig ? 'View' : 'Edit'} high availability
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </IsPermitted>

            <DropdownMenuGroup>
              <IsPermitted
                anyPermissions={[
                  PermissionType.PermNetworkDevicesWrite,
                  PermissionType.PermHardwareDeviceRpcBlinkLeds,
                ]}
              >
                <DropdownMenuItem
                  disabled={flashLEDs.isLoading || isOffline}
                  onSelect={handleFlashLEDs}
                  icon="flash-leds"
                >
                  Flash LEDs
                </DropdownMenuItem>
              </IsPermitted>

              <IsPermitted permissions={PermissionType.PermNetworkDevicesWrite}>
                <DropdownMenuItem
                  disabled={isOffline}
                  onSelect={rebootDialogState.open}
                  icon="power-cycle"
                >
                  Reboot
                </DropdownMenuItem>
              </IsPermitted>
            </DropdownMenuGroup>
            <IsPermitted
              anyPermissions={[
                PermissionType.PermHardwareDeviceRpcRefreshConfig,
                PermissionType.PermNetworkDevicesWriteRestricted,
                PermissionType.PermNetworkDevicesWrite,
              ]}
            >
              <DropdownMenuGroup>
                <IsPermitted
                  anyPermissions={[
                    PermissionType.PermNetworkDevicesWrite,
                    PermissionType.PermHardwareDeviceRpcRefreshConfig,
                  ]}
                >
                  <DropdownMenuItem
                    disabled={refreshConfig.isLoading || isOffline}
                    onSelect={handleRefreshConfig}
                    icon="arrows-rotate"
                    internal
                  >
                    Refresh config
                  </DropdownMenuItem>
                </IsPermitted>
                <IsPermitted permissions={PermissionType.PermNetworkDevicesWriteRestricted}>
                  <DropdownMenuItem onSelect={configDialogState.openFromMenu} icon="code" internal>
                    Show config
                  </DropdownMenuItem>
                  {supportsConsoleAccess && (
                    <DropdownMenuItem
                      disabled={!virtualDevice?.hardwareDevice?.serialNumber}
                      onSelect={consoleAccessDialogState.open}
                      icon="ethernet"
                      internal
                    >
                      Console access
                    </DropdownMenuItem>
                  )}
                </IsPermitted>
              </DropdownMenuGroup>
            </IsPermitted>
            <IsPermitted permissions={PermissionType.PermNetworkDevicesWriteRestricted}>
              <DropdownMenuGroup>
                <DropdownMenuItem
                  onSelect={copyNetworkUplinksDialogState.open}
                  icon="copy"
                  internal
                >
                  Copy uplinks from config 1
                </DropdownMenuItem>
              </DropdownMenuGroup>
              <DropdownMenuGroup>
                <DropdownMenuItem
                  disabled={!!virtualDevice?.hardwareDevice || !!virtualDevice.highAvailability}
                  icon="trash-can"
                  onSelect={deleteDialogState.openFromMenu}
                  title={
                    virtualDevice?.hardwareDevice
                      ? 'Please unassign any hardware and disable high availability before deleting the virtual device'
                      : undefined
                  }
                  internal
                >
                  Delete
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </IsPermitted>
          </DropdownMenuPopover>
        </DropdownMenu>
      </IsPermitted>
      <IsPermitted permissions={PermissionType.PermNetworkDevicesWriteRestricted}>
        <>
          {hardwareDevice?.serialNumber && (
            <DeviceConfigEditorDialog
              state={configDialogState.state}
              serialNumber={hardwareDevice.serialNumber}
            />
          )}
          {hardwareDevice?.serialNumber && (
            <ConsoleAccessDialog
              state={consoleAccessDialogState}
              serialNumber={hardwareDevice.serialNumber}
            />
          )}
        </>
      </IsPermitted>

      <IsPermitted
        isPermitted={({ permissions, nosFlags }) =>
          Boolean(
            permissions.hasPermission(PermissionType.PermNetworkDevicesWrite) &&
              nosFlags[NosFeature.COS2],
          )
        }
      >
        {virtualDevice && (
          <SecurityApplianceDeleteDialog state={deleteDialogState.state} uuid={virtualDeviceUUID} />
        )}
        {hardwareDevice && (
          <SecurityApplianceRebootDialog
            state={rebootDialogState}
            serialNumber={hardwareDevice.serialNumber}
            label={virtualDevice.label}
          />
        )}
        <CopyNetworkUplinksDialog state={copyNetworkUplinksDialogState} />
      </IsPermitted>
    </>
  );
}
