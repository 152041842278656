import React from 'react';

import PagefilesImport0 from "/vercel/path0/apps/dashboard/src/routes/pages/App.layout.tsx";
import PagefilesImport1 from "/vercel/path0/apps/dashboard/src/routes/pages/AppLogin.page.tsx";
import PagefilesImport2 from "/vercel/path0/apps/dashboard/src/routes/pages/CompanyRoot.page.tsx";
import PagefilesImport3 from "/vercel/path0/apps/dashboard/src/routes/pages/Guest.page.tsx";
import PagefilesImport4 from "/vercel/path0/apps/dashboard/src/routes/pages/NetworkRoot.page.tsx";
import PagefilesImport5 from "/vercel/path0/apps/dashboard/src/routes/pages/NotFound.page.tsx";
import PagefilesImport6 from "/vercel/path0/apps/dashboard/src/routes/pages/NotificationPreferences.page.tsx";
import PagefilesImport7 from "/vercel/path0/apps/dashboard/src/routes/pages/Providers.layout.tsx";
import PagefilesImport8 from "/vercel/path0/apps/dashboard/src/routes/pages/Root.page.tsx";
import PagefilesImport9 from "/vercel/path0/apps/dashboard/src/routes/pages/VirtualDeviceRoot.page.tsx";
import PagefilesImport10 from "/vercel/path0/apps/dashboard/src/routes/pages/autoVPN/AutoVPN.layout.tsx";
import PagefilesImport11 from "/vercel/path0/apps/dashboard/src/routes/pages/autoVPN/AutoVPNGroup.page.tsx";
import PagefilesImport12 from "/vercel/path0/apps/dashboard/src/routes/pages/autoVPN/AutoVPNGroups.page.tsx";
import PagefilesImport13 from "/vercel/path0/apps/dashboard/src/routes/pages/connect/Connect.layout.tsx";
import PagefilesImport14 from "/vercel/path0/apps/dashboard/src/routes/pages/hub/Hub.layout.tsx";
import PagefilesImport15 from "/vercel/path0/apps/dashboard/src/routes/pages/hub/HubCards.page.tsx";
import PagefilesImport16 from "/vercel/path0/apps/dashboard/src/routes/pages/hub/HubList.page.tsx";
import PagefilesImport17 from "/vercel/path0/apps/dashboard/src/routes/pages/hub/HubMap.page.tsx";
import PagefilesImport18 from "/vercel/path0/apps/dashboard/src/routes/pages/network/ControllerTabletJoinInstructions.page.tsx";
import PagefilesImport19 from "/vercel/path0/apps/dashboard/src/routes/pages/network/Network.layout.tsx";
import PagefilesImport20 from "/vercel/path0/apps/dashboard/src/routes/pages/network/PatchPanelLayout.page.tsx";
import PagefilesImport21 from "/vercel/path0/apps/dashboard/src/routes/pages/network/TabletJoinInstructions.page.tsx";
import PagefilesImport22 from "/vercel/path0/apps/dashboard/src/routes/pages/network/VirtualNetwork.layout.tsx";
import PagefilesImport23 from "/vercel/path0/apps/dashboard/src/routes/pages/network/VirtualNetworkRoot.page.tsx";
import PagefilesImport24 from "/vercel/path0/apps/dashboard/src/routes/pages/operator/Operator.layout.tsx";
import PagefilesImport25 from "/vercel/path0/apps/dashboard/src/routes/pages/settings/Settings.layout.tsx";
import PagefilesImport26 from "/vercel/path0/apps/dashboard/src/routes/pages/tunnel/Tunnel.layout.tsx";
import PagefilesImport27 from "/vercel/path0/apps/dashboard/src/routes/pages/connect/locations/ConnectDashboardAllLocations.page.tsx";
import PagefilesImport28 from "/vercel/path0/apps/dashboard/src/routes/pages/connect/locations/ConnectDashboardLocation.page.tsx";
import PagefilesImport29 from "/vercel/path0/apps/dashboard/src/routes/pages/network/design/Design.page.tsx";
import PagefilesImport30 from "/vercel/path0/apps/dashboard/src/routes/pages/network/design/DesignLegacy.page.tsx";
import PagefilesImport31 from "/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/Firewall.page.tsx";
import PagefilesImport32 from "/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/FirewallLegacy.page.tsx";
import PagefilesImport33 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/Hardware.page.tsx";
import PagefilesImport34 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/HardwareLegacy.page.tsx";
import PagefilesImport35 from "/vercel/path0/apps/dashboard/src/routes/pages/network/insights/Insights.page.tsx";
import PagefilesImport36 from "/vercel/path0/apps/dashboard/src/routes/pages/network/insights/InsightsLegacy.page.tsx";
import PagefilesImport37 from "/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/NetworkWide.page.tsx";
import PagefilesImport38 from "/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/NetworkWideLegacy.page.tsx";
import PagefilesImport39 from "/vercel/path0/apps/dashboard/src/routes/pages/network/onboarding/Onboarding.page.tsx";
import PagefilesImport40 from "/vercel/path0/apps/dashboard/src/routes/pages/network/operators/Operators.page.tsx";
import PagefilesImport41 from "/vercel/path0/apps/dashboard/src/routes/pages/network/operators/OperatorsLegacy.page.tsx";
import PagefilesImport42 from "/vercel/path0/apps/dashboard/src/routes/pages/network/secure_tunnels/SecureTunnels.page.tsx";
import PagefilesImport43 from "/vercel/path0/apps/dashboard/src/routes/pages/network/secure_tunnels/SecureTunnelsLegacy.page.tsx";
import PagefilesImport44 from "/vercel/path0/apps/dashboard/src/routes/pages/network/wireless/Wireless.page.tsx";
import PagefilesImport45 from "/vercel/path0/apps/dashboard/src/routes/pages/network/wireless/WirelessLegacy.page.tsx";
import PagefilesImport46 from "/vercel/path0/apps/dashboard/src/routes/pages/operator/companies/CompanyList.page.tsx";
import PagefilesImport47 from "/vercel/path0/apps/dashboard/src/routes/pages/operator/companies/CompanyNetworkList.page.tsx";
import PagefilesImport48 from "/vercel/path0/apps/dashboard/src/routes/pages/operator/companies/CompanyUsers.page.tsx";
import PagefilesImport49 from "/vercel/path0/apps/dashboard/src/routes/pages/operator/firmwareBulkUpgrader/AddFirmwareBulkUpgrade.page.tsx";
import PagefilesImport50 from "/vercel/path0/apps/dashboard/src/routes/pages/operator/firmwareBulkUpgrader/FirmwareBulkUpgrader.layout.tsx";
import PagefilesImport51 from "/vercel/path0/apps/dashboard/src/routes/pages/operator/firmwareBulkUpgrader/FirmwareBulkUpgradesList.page.tsx";
import PagefilesImport52 from "/vercel/path0/apps/dashboard/src/routes/pages/operator/nos/NOS.page.tsx";
import PagefilesImport53 from "/vercel/path0/apps/dashboard/src/routes/pages/operator/security-appliances/SecurityAppplianceList.page.tsx";
import PagefilesImport54 from "/vercel/path0/apps/dashboard/src/routes/pages/settings/network/General.page.tsx";
import PagefilesImport55 from "/vercel/path0/apps/dashboard/src/routes/pages/settings/network/ServiceDiscovery.page.tsx";
import PagefilesImport56 from "/vercel/path0/apps/dashboard/src/routes/pages/settings/org/Authentication.page.tsx";
import PagefilesImport57 from "/vercel/path0/apps/dashboard/src/routes/pages/settings/org/Documents.page.tsx";
import PagefilesImport58 from "/vercel/path0/apps/dashboard/src/routes/pages/settings/org/Users.page.tsx";
import PagefilesImport59 from "/vercel/path0/apps/dashboard/src/routes/pages/settings/user/Preferences.page.tsx";
import PagefilesImport60 from "/vercel/path0/apps/dashboard/src/routes/pages/tools/support/Support.layout.tsx";
import PagefilesImport61 from "/vercel/path0/apps/dashboard/src/routes/pages/tools/support/Support.page.tsx";
import PagefilesImport62 from "/vercel/path0/apps/dashboard/src/routes/pages/network/design/floor_plans/FloorPlan.page.tsx";
import PagefilesImport63 from "/vercel/path0/apps/dashboard/src/routes/pages/network/design/floor_plans/FloorPlans.page.tsx";
import PagefilesImport64 from "/vercel/path0/apps/dashboard/src/routes/pages/network/design/floor_plans/FloorPlansLegacy.page.tsx";
import PagefilesImport65 from "/vercel/path0/apps/dashboard/src/routes/pages/network/design/rack_elevation/RackElevations.page.tsx";
import PagefilesImport66 from "/vercel/path0/apps/dashboard/src/routes/pages/network/design/topology/Topology.page.tsx";
import PagefilesImport67 from "/vercel/path0/apps/dashboard/src/routes/pages/network/design/topology/Topology2.page.tsx";
import PagefilesImport68 from "/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/dns_security/DNSSecurity.page.tsx";
import PagefilesImport69 from "/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/multicast/Multicast.page.tsx";
import PagefilesImport70 from "/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/one_to_one_nat/OneToOneNAT.page.tsx";
import PagefilesImport71 from "/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/port_forwarding/NATPortForwardingRuleList.page.tsx";
import PagefilesImport72 from "/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/port_forwarding/PortForwarding.page.tsx";
import PagefilesImport73 from "/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/rate_limiting/RateLimiting.page.tsx";
import PagefilesImport74 from "/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/rules/Rules.page.tsx";
import PagefilesImport75 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/AccessPoints.page.tsx";
import PagefilesImport76 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/LegacyAccessPoints.page.tsx";
import PagefilesImport77 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/power_distrubution_units/PowerDistributionUnitDetail.page.tsx";
import PagefilesImport78 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/power_distrubution_units/PowerDistributionUnitList.page.tsx";
import PagefilesImport79 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/security_appliances/SecurityApplianceDetail.page.tsx";
import PagefilesImport80 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/security_appliances/SecurityApplianceEmptyState.page.tsx";
import PagefilesImport81 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/switches/LegacySwitchList.page.tsx";
import PagefilesImport82 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/switches/SwitchDetail.page.tsx";
import PagefilesImport83 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/switches/SwitchList.page.tsx";
import PagefilesImport84 from "/vercel/path0/apps/dashboard/src/routes/pages/network/insights/clients/ClientConnectionEvents.page.tsx";
import PagefilesImport85 from "/vercel/path0/apps/dashboard/src/routes/pages/network/insights/clients/ClientDetail.layout.tsx";
import PagefilesImport86 from "/vercel/path0/apps/dashboard/src/routes/pages/network/insights/clients/ClientDetail.page.tsx";
import PagefilesImport87 from "/vercel/path0/apps/dashboard/src/routes/pages/network/insights/clients/ClientInsights.page.tsx";
import PagefilesImport88 from "/vercel/path0/apps/dashboard/src/routes/pages/network/insights/clients/ClientsList.page.tsx";
import PagefilesImport89 from "/vercel/path0/apps/dashboard/src/routes/pages/network/insights/clients/ClientsList2.page.tsx";
import PagefilesImport90 from "/vercel/path0/apps/dashboard/src/routes/pages/network/insights/logs/ControllerLogs.layout.tsx";
import PagefilesImport91 from "/vercel/path0/apps/dashboard/src/routes/pages/network/insights/logs/EventLog.page.tsx";
import PagefilesImport92 from "/vercel/path0/apps/dashboard/src/routes/pages/network/insights/logs/EventsList.page.tsx";
import PagefilesImport93 from "/vercel/path0/apps/dashboard/src/routes/pages/network/insights/logs/NetworkLogs.layout.tsx";
import PagefilesImport94 from "/vercel/path0/apps/dashboard/src/routes/pages/network/insights/logs/NetworkMutationsList.page.tsx";
import PagefilesImport95 from "/vercel/path0/apps/dashboard/src/routes/pages/network/insights/logs/StatsList.page.tsx";
import PagefilesImport96 from "/vercel/path0/apps/dashboard/src/routes/pages/network/insights/network/InsightsNetwork.page.tsx";
import PagefilesImport97 from "/vercel/path0/apps/dashboard/src/routes/pages/network/insights/network/LegacyInsights.page.tsx";
import PagefilesImport98 from "/vercel/path0/apps/dashboard/src/routes/pages/network/insights/network/Overview.page.tsx";
import PagefilesImport99 from "/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/captive_portals/CaptivePortal.page.tsx";
import PagefilesImport100 from "/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/captive_portals/CaptivePortals.page.tsx";
import PagefilesImport101 from "/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/isps/ISP.page.tsx";
import PagefilesImport102 from "/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/isps/ISPEvents.page.tsx";
import PagefilesImport103 from "/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/isps/ISPInsights.page.tsx";
import PagefilesImport104 from "/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/isps/ISPs.page.tsx";
import PagefilesImport105 from "/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/isps/InternetAndWireless.page.tsx";
import PagefilesImport106 from "/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/isps/LegacyISPList.page.tsx";
import PagefilesImport107 from "/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/radius_profiles/RADIUSProfiles.page.tsx";
import PagefilesImport108 from "/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/routing/Routing.page.tsx";
import PagefilesImport109 from "/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/vlans/LegacyVLANList.page.tsx";
import PagefilesImport110 from "/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/vlans/VLANClientList.page.tsx";
import PagefilesImport111 from "/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/vlans/VLANDetail.layout.tsx";
import PagefilesImport112 from "/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/vlans/VLANDetail.page.tsx";
import PagefilesImport113 from "/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/vlans/VLANDetails.page.tsx";
import PagefilesImport114 from "/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/vlans/VLANList.page.tsx";
import PagefilesImport115 from "/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/vlans/VLANSSIDList.page.tsx";
import PagefilesImport116 from "/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/vlans/VLANStaticIPList.page.tsx";
import PagefilesImport117 from "/vercel/path0/apps/dashboard/src/routes/pages/network/operators/device_config/DeviceConfig.layout.tsx";
import PagefilesImport118 from "/vercel/path0/apps/dashboard/src/routes/pages/network/operators/device_config/DeviceConfigHistory.page.tsx";
import PagefilesImport119 from "/vercel/path0/apps/dashboard/src/routes/pages/network/operators/device_config/DeviceConfigHistoryVersion.page.tsx";
import PagefilesImport120 from "/vercel/path0/apps/dashboard/src/routes/pages/network/operators/device_config/DeviceConfigOverrides.page.tsx";
import PagefilesImport121 from "/vercel/path0/apps/dashboard/src/routes/pages/network/secure_tunnels/ipsec/IPSecTunnels.page.tsx";
import PagefilesImport122 from "/vercel/path0/apps/dashboard/src/routes/pages/network/secure_tunnels/vpns/ClientVPN.page.tsx";
import PagefilesImport123 from "/vercel/path0/apps/dashboard/src/routes/pages/network/wireless/hotspot20/Hotspot20s.page.tsx";
import PagefilesImport124 from "/vercel/path0/apps/dashboard/src/routes/pages/network/wireless/radio_profiles/RadioProfiles.page.tsx";
import PagefilesImport125 from "/vercel/path0/apps/dashboard/src/routes/pages/network/wireless/ssids/LegacySSIDList.page.tsx";
import PagefilesImport126 from "/vercel/path0/apps/dashboard/src/routes/pages/network/wireless/ssids/SSIDDetails.page.tsx";
import PagefilesImport127 from "/vercel/path0/apps/dashboard/src/routes/pages/network/wireless/ssids/SSIDs.page.tsx";
import PagefilesImport128 from "/vercel/path0/apps/dashboard/src/routes/pages/settings/network/notifications/Alerts.page.tsx";
import PagefilesImport129 from "/vercel/path0/apps/dashboard/src/routes/pages/settings/network/notifications/Notifications.page.tsx";
import PagefilesImport130 from "/vercel/path0/apps/dashboard/src/routes/pages/settings/network/notifications/Reports.page.tsx";
import PagefilesImport131 from "/vercel/path0/apps/dashboard/src/routes/pages/settings/org/integrations/Integrations.page.tsx";
import PagefilesImport132 from "/vercel/path0/apps/dashboard/src/routes/pages/settings/org/integrations/Receivers.page.tsx";
import PagefilesImport133 from "/vercel/path0/apps/dashboard/src/routes/pages/settings/org/integrations/Services.page.tsx";
import PagefilesImport134 from "/vercel/path0/apps/dashboard/src/routes/pages/settings/org/integrations/Webhooks.page.tsx";
import PagefilesImport135 from "/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/dns_security/legacy/DNSSecurity.layout.tsx";
import PagefilesImport136 from "/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/dns_security/legacy/DNSSecurity.page.tsx";
import PagefilesImport137 from "/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/dns_security/legacy/DNSSecurityCategories.page.tsx";
import PagefilesImport138 from "/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/dns_security/legacy/DNSSecurityDomains.page.tsx";
import PagefilesImport139 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/bssids/AccessPointsBSSIDs.page.tsx";
import PagefilesImport140 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/detail/AccessPoint.page.tsx";
import PagefilesImport141 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/detail/AccessPointClientsListDetail.layout.tsx";
import PagefilesImport142 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/detail/AccessPointClientsListDetail.page.tsx";
import PagefilesImport143 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/detail/LegacyAccessPointDetail.layout.tsx";
import PagefilesImport144 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/detail/LegacyAccessPointDetail.page.tsx";
import PagefilesImport145 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/insights/AccessPointsInsights.page.tsx";
import PagefilesImport146 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/list/AccessPointsList.page.tsx";
import PagefilesImport147 from "/vercel/path0/apps/dashboard/src/routes/pages/network/secure_tunnels/vpns/legacy/VPNList.page.tsx";

const routes = [
{path: "*",element: React.createElement(PagefilesImport5)},
{path: "/",element: React.createElement(PagefilesImport8)},
{path: "/app-login",element: React.createElement(PagefilesImport1)},
{path: "/network/:networkUUID",element: React.createElement(PagefilesImport4)},
{path: "/org/:companyName",element: React.createElement(PagefilesImport2)},
{path: "/org/:companyName/controller/:controllerName/insights/clients/:macAddress",element: React.createElement(PagefilesImport86)},
{path: "/org/:companyName/guest",element: React.createElement(PagefilesImport3)},
{element: React.createElement(PagefilesImport7)},
{path: "/org/:companyName/controller/:controllerName/join/:ssid",element: React.createElement(PagefilesImport18)},
{path: "/org/:companyName/network/:networkSlug/join/:ssid",element: React.createElement(PagefilesImport21)},
{path: "/virtual-device/:virtualDeviceUUID",element: React.createElement(PagefilesImport9)},
{children: [{element: React.createElement(PagefilesImport26)},
{path: "/org/:companyName/support",children: [{index: true,element: React.createElement(PagefilesImport61)},],element: React.createElement(PagefilesImport60)},
{path: "/org/:companyName/auto-vpn",children: [{path: "/org/:companyName/auto-vpn/groups",element: React.createElement(PagefilesImport12)},
{path: "/org/:companyName/auto-vpn/groups/:groupUUID/:activeTab",element: React.createElement(PagefilesImport11)},],element: React.createElement(PagefilesImport10)},
{children: [{path: "/org/:companyName/connect/locations",element: React.createElement(PagefilesImport27)},
{path: "/org/:companyName/connect/locations/:companyLocationSID",element: React.createElement(PagefilesImport28)},],element: React.createElement(PagefilesImport13)},
{path: "/org/:companyName/hub",children: [{path: "/org/:companyName/hub/cards",element: React.createElement(PagefilesImport15)},
{path: "/org/:companyName/hub/list",element: React.createElement(PagefilesImport16)},
{path: "/org/:companyName/hub/map",element: React.createElement(PagefilesImport17)},],element: React.createElement(PagefilesImport14)},
{children: [{path: "/operator/companies",element: React.createElement(PagefilesImport46)},
{path: "/operator/companies/:slug/networks",element: React.createElement(PagefilesImport47)},
{path: "/operator/companies/:slug/users",element: React.createElement(PagefilesImport48)},
{path: "/operator/nos/:tab",element: React.createElement(PagefilesImport52)},
{path: "/operator/security-appliances",element: React.createElement(PagefilesImport53)},
{path: "/operator/firmware-bulk-upgrader",children: [{path: "/operator/firmware-bulk-upgrader/add",element: React.createElement(PagefilesImport49)},
{path: "/operator/firmware-bulk-upgrader/upgrades",element: React.createElement(PagefilesImport51)},],element: React.createElement(PagefilesImport50)},],element: React.createElement(PagefilesImport24)},
{path: "/org/:companyName/settings",children: [{path: "/org/:companyName/settings/network/:networkSlug/general",element: React.createElement(PagefilesImport54)},
{path: "/org/:companyName/settings/network/:networkSlug/notifications",element: React.createElement(PagefilesImport129)},
{path: "/org/:companyName/settings/network/:networkSlug/notifications/alerts",element: React.createElement(PagefilesImport128)},
{path: "/org/:companyName/settings/network/:networkSlug/notifications/reports",element: React.createElement(PagefilesImport130)},
{path: "/org/:companyName/settings/network/:networkSlug/service-discovery",element: React.createElement(PagefilesImport55)},
{path: "/org/:companyName/settings/org/authentication",element: React.createElement(PagefilesImport56)},
{path: "/org/:companyName/settings/org/documents",element: React.createElement(PagefilesImport57)},
{path: "/org/:companyName/settings/org/integrations",element: React.createElement(PagefilesImport131)},
{path: "/org/:companyName/settings/org/integrations/receivers",element: React.createElement(PagefilesImport132)},
{path: "/org/:companyName/settings/org/integrations/services",element: React.createElement(PagefilesImport133)},
{path: "/org/:companyName/settings/org/integrations/webhooks",element: React.createElement(PagefilesImport134)},
{path: "/org/:companyName/settings/org/users",element: React.createElement(PagefilesImport58)},
{path: "/org/:companyName/settings/user/preferences",element: React.createElement(PagefilesImport59)},],element: React.createElement(PagefilesImport25)},
{path: "/org/:companyName/controller/:controllerName",children: [{path: "/org/:companyName/controller/:controllerName/clients/:macAddress",element: React.createElement(PagefilesImport85)},
{path: "/org/:companyName/controller/:controllerName/design",element: React.createElement(PagefilesImport30)},
{path: "/org/:companyName/controller/:controllerName/design/floor-plans",element: React.createElement(PagefilesImport64)},
{path: "/org/:companyName/controller/:controllerName/design/topology",element: React.createElement(PagefilesImport66)},
{path: "/org/:companyName/controller/:controllerName/firewall",element: React.createElement(PagefilesImport32)},
{path: "/org/:companyName/controller/:controllerName/firewall/dns-security",element: React.createElement(PagefilesImport136)},
{path: "/org/:companyName/controller/:controllerName/firewall/port-forwarding",element: React.createElement(PagefilesImport71)},
{path: "/org/:companyName/controller/:controllerName/hardware",element: React.createElement(PagefilesImport34)},
{path: "/org/:companyName/controller/:controllerName/hardware/access-points",element: React.createElement(PagefilesImport76)},
{path: "/org/:companyName/controller/:controllerName/hardware/switches",element: React.createElement(PagefilesImport81)},
{path: "/org/:companyName/controller/:controllerName/insights",element: React.createElement(PagefilesImport36)},
{path: "/org/:companyName/controller/:controllerName/insights/clients",element: React.createElement(PagefilesImport88)},
{path: "/org/:companyName/controller/:controllerName/internet-wifi",element: React.createElement(PagefilesImport105)},
{path: "/org/:companyName/controller/:controllerName/network-wide",element: React.createElement(PagefilesImport38)},
{path: "/org/:companyName/controller/:controllerName/network-wide/isps",element: React.createElement(PagefilesImport106)},
{path: "/org/:companyName/controller/:controllerName/network-wide/vlans",element: React.createElement(PagefilesImport109)},
{path: "/org/:companyName/controller/:controllerName/operators",element: React.createElement(PagefilesImport41)},
{path: "/org/:companyName/controller/:controllerName/operators/notification-preferences",element: React.createElement(PagefilesImport6)},
{path: "/org/:companyName/controller/:controllerName/secure-tunnels",element: React.createElement(PagefilesImport43)},
{path: "/org/:companyName/controller/:controllerName/wireless",element: React.createElement(PagefilesImport45)},
{index: true,element: React.createElement(PagefilesImport98)},
{path: "/org/:companyName/controller/:controllerName/secure-tunnels/vpn",element: React.createElement(PagefilesImport147)},
{path: "/org/:companyName/controller/:controllerName/wireless/ssids",element: React.createElement(PagefilesImport125)},
{path: "/org/:companyName/controller/:controllerName/devices/:deviceName",children: [{index: true,element: React.createElement(PagefilesImport144)},],element: React.createElement(PagefilesImport143)},
{path: "/org/:companyName/controller/:controllerName/insights/logs",children: [{path: "/org/:companyName/controller/:controllerName/insights/logs/events",element: React.createElement(PagefilesImport92)},],element: React.createElement(PagefilesImport90)},
{children: [{path: "/org/:companyName/controller/:controllerName/dns-security/categories",element: React.createElement(PagefilesImport137)},
{path: "/org/:companyName/controller/:controllerName/dns-security/domains",element: React.createElement(PagefilesImport138)},],element: React.createElement(PagefilesImport135)},
{path: "/org/:companyName/controller/:controllerName/vlans/:vlanName",children: [{path: "/org/:companyName/controller/:controllerName/vlans/:vlanName/clients",element: React.createElement(PagefilesImport110)},
{index: true,element: React.createElement(PagefilesImport112)},
{path: "/org/:companyName/controller/:controllerName/vlans/:vlanName/ssids",element: React.createElement(PagefilesImport115)},
{path: "/org/:companyName/controller/:controllerName/vlans/:vlanName/static",element: React.createElement(PagefilesImport116)},],element: React.createElement(PagefilesImport111)},],element: React.createElement(PagefilesImport19)},
{path: "/org/:companyName/network/:networkSlug",children: [{path: "/org/:companyName/network/:networkSlug/patch-panel-layout",element: React.createElement(PagefilesImport20)},
{index: true,element: React.createElement(PagefilesImport23)},
{path: "/org/:companyName/network/:networkSlug/config/:serialNumber/history/compare",element: React.createElement(PagefilesImport119)},
{path: "/org/:companyName/network/:networkSlug/design",element: React.createElement(PagefilesImport29)},
{path: "/org/:companyName/network/:networkSlug/design/floor-plans",element: React.createElement(PagefilesImport63)},
{path: "/org/:companyName/network/:networkSlug/design/floor-plans/:floorPlanUUID",element: React.createElement(PagefilesImport62)},
{path: "/org/:companyName/network/:networkSlug/design/rack-elevations",element: React.createElement(PagefilesImport65)},
{path: "/org/:companyName/network/:networkSlug/design/topology",element: React.createElement(PagefilesImport67)},
{path: "/org/:companyName/network/:networkSlug/firewall",element: React.createElement(PagefilesImport31)},
{path: "/org/:companyName/network/:networkSlug/firewall/dns-security/:tab",element: React.createElement(PagefilesImport68)},
{path: "/org/:companyName/network/:networkSlug/firewall/multicast",element: React.createElement(PagefilesImport69)},
{path: "/org/:companyName/network/:networkSlug/firewall/one-to-one-nat",element: React.createElement(PagefilesImport70)},
{path: "/org/:companyName/network/:networkSlug/firewall/port-forwarding",element: React.createElement(PagefilesImport72)},
{path: "/org/:companyName/network/:networkSlug/firewall/rate-limiting",element: React.createElement(PagefilesImport73)},
{path: "/org/:companyName/network/:networkSlug/firewall/rules/:tab",element: React.createElement(PagefilesImport74)},
{path: "/org/:companyName/network/:networkSlug/hardware",element: React.createElement(PagefilesImport33)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points",element: React.createElement(PagefilesImport75)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/:uuid/:tab",element: React.createElement(PagefilesImport140)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/bssids",element: React.createElement(PagefilesImport139)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/insights",element: React.createElement(PagefilesImport145)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/list",element: React.createElement(PagefilesImport146)},
{path: "/org/:companyName/network/:networkSlug/hardware/power-distribution-units/:tab",element: React.createElement(PagefilesImport78)},
{path: "/org/:companyName/network/:networkSlug/hardware/power-distribution-units/:uuid/:tab",element: React.createElement(PagefilesImport77)},
{path: "/org/:companyName/network/:networkSlug/hardware/security-appliances",element: React.createElement(PagefilesImport80)},
{path: "/org/:companyName/network/:networkSlug/hardware/security-appliances/:uuid/:tab",element: React.createElement(PagefilesImport79)},
{path: "/org/:companyName/network/:networkSlug/hardware/switches/:tab",element: React.createElement(PagefilesImport83)},
{path: "/org/:companyName/network/:networkSlug/hardware/switches/:uuid/:tab",element: React.createElement(PagefilesImport82)},
{path: "/org/:companyName/network/:networkSlug/insights",element: React.createElement(PagefilesImport35)},
{path: "/org/:companyName/network/:networkSlug/insights/clients",element: React.createElement(PagefilesImport89)},
{path: "/org/:companyName/network/:networkSlug/insights/clients/:macAddress/connection-events",element: React.createElement(PagefilesImport84)},
{path: "/org/:companyName/network/:networkSlug/insights/clients/:macAddress/insights",element: React.createElement(PagefilesImport87)},
{path: "/org/:companyName/network/:networkSlug/insights/network",element: React.createElement(PagefilesImport96)},
{path: "/org/:companyName/network/:networkSlug/network-wide",element: React.createElement(PagefilesImport37)},
{path: "/org/:companyName/network/:networkSlug/network-wide/captive-portals",element: React.createElement(PagefilesImport100)},
{path: "/org/:companyName/network/:networkSlug/network-wide/captive-portals/:tab",element: React.createElement(PagefilesImport99)},
{path: "/org/:companyName/network/:networkSlug/network-wide/isps",element: React.createElement(PagefilesImport104)},
{path: "/org/:companyName/network/:networkSlug/network-wide/isps/:isp",element: React.createElement(PagefilesImport101)},
{path: "/org/:companyName/network/:networkSlug/network-wide/isps/:uuid/events",element: React.createElement(PagefilesImport102)},
{path: "/org/:companyName/network/:networkSlug/network-wide/isps/:uuid/insights",element: React.createElement(PagefilesImport103)},
{path: "/org/:companyName/network/:networkSlug/network-wide/radius-profiles",element: React.createElement(PagefilesImport107)},
{path: "/org/:companyName/network/:networkSlug/network-wide/routing/:tab",element: React.createElement(PagefilesImport108)},
{path: "/org/:companyName/network/:networkSlug/network-wide/vlans",element: React.createElement(PagefilesImport114)},
{path: "/org/:companyName/network/:networkSlug/network-wide/vlans/:uuid/:tab",element: React.createElement(PagefilesImport113)},
{path: "/org/:companyName/network/:networkSlug/onboarding",element: React.createElement(PagefilesImport39)},
{path: "/org/:companyName/network/:networkSlug/operators",element: React.createElement(PagefilesImport40)},
{path: "/org/:companyName/network/:networkSlug/operators/network/:tab",element: React.createElement(PagefilesImport97)},
{path: "/org/:companyName/network/:networkSlug/secure-tunnels",element: React.createElement(PagefilesImport42)},
{path: "/org/:companyName/network/:networkSlug/secure-tunnels/ipsec",element: React.createElement(PagefilesImport121)},
{path: "/org/:companyName/network/:networkSlug/secure-tunnels/vpn",element: React.createElement(PagefilesImport122)},
{path: "/org/:companyName/network/:networkSlug/wireless",element: React.createElement(PagefilesImport44)},
{path: "/org/:companyName/network/:networkSlug/wireless/hotspot2.0",element: React.createElement(PagefilesImport123)},
{path: "/org/:companyName/network/:networkSlug/wireless/radio-profiles",element: React.createElement(PagefilesImport124)},
{path: "/org/:companyName/network/:networkSlug/wireless/ssids",element: React.createElement(PagefilesImport127)},
{path: "/org/:companyName/network/:networkSlug/wireless/ssids/:uuid",element: React.createElement(PagefilesImport126)},
{path: "/org/:companyName/network/:networkSlug/devices/:uuid",children: [{index: true,element: React.createElement(PagefilesImport142)},],element: React.createElement(PagefilesImport141)},
{path: "/org/:companyName/network/:networkSlug/operators/config/:serialNumber",children: [{path: "/org/:companyName/network/:networkSlug/operators/config/:serialNumber/history",element: React.createElement(PagefilesImport118)},
{path: "/org/:companyName/network/:networkSlug/operators/config/:serialNumber/overrides",element: React.createElement(PagefilesImport120)},],element: React.createElement(PagefilesImport117)},
{path: "/org/:companyName/network/:networkSlug/insights/logs",children: [{index: true,element: React.createElement(PagefilesImport91)},
{path: "/org/:companyName/network/:networkSlug/insights/logs/mutations",element: React.createElement(PagefilesImport94)},
{path: "/org/:companyName/network/:networkSlug/insights/logs/stats",element: React.createElement(PagefilesImport95)},],element: React.createElement(PagefilesImport93)},],element: React.createElement(PagefilesImport22)},],element: React.createElement(PagefilesImport0)},
];

export default routes;