import { getRealm, Realm } from './realm';

export const deviceHasMismatchedRealm = (
  d: undefined | null | { isDev?: boolean | undefined },
): boolean => {
  const realm = getRealm();
  if (!d) {
    return false;
  }
  if (d.isDev == null) {
    return false;
  }
  if (d.isDev && (realm === Realm.STAGING || realm === Realm.LOCAL)) {
    return false;
  }
  return !(!d.isDev && realm === Realm.PRODUCTION);
};

// should UI show console toggle for enabling console in device config
export const shouldShowConsoleToggle = (nosSupportsChallegeResponse: boolean): boolean => {
  const realm = getRealm();
  // always show in dev/local realm
  if (realm !== Realm.PRODUCTION) {
    return true;
  }
  // in prod, show the toggle depending on NOS feature flag
  return !nosSupportsChallegeResponse;
};
