import { capitalize } from 'lodash-es';
import { DateTime } from 'luxon';
import { z } from 'zod';

import type { NetworksByFilterQueryQuery, NetworksFilter } from '../../gql/graphql';
import {
  CompanySupportTier as CustomerSupportTierFilterEnum,
  DeviceModel,
} from '../../gql/graphql';

export type NetworkByFilter = NetworksByFilterQueryQuery['networks'][number];

export enum TrueFalse {
  true = 'true',
  false = 'false',
}

export enum LtGt {
  lt = 'lt',
  gt = 'gt',
}

export enum RuleVariable {
  Active = 'active',
  CompanySupportTier = 'companySupportTier',
  CurrentFirmwareVersion = 'nosVersionID',
  LastUpgradedAt = 'lastUpgradedAt',
  PendingUpgrade = 'pendingUpgrade',
  NetworkHWModel = 'hardwareModel',
  SquareFootage = 'mailingAddressSquareFeet',
  UpgradeSensitive = 'upgradeSensitivity',
}

export function ruleVarToLabel(ruleVar: RuleVariable): string {
  switch (ruleVar) {
    case RuleVariable.UpgradeSensitive:
      return 'Upgrade sensitive';
    case RuleVariable.CompanySupportTier:
      return 'Company support tier';
    case RuleVariable.SquareFootage:
      return 'Square feet';
    case RuleVariable.NetworkHWModel:
      return 'Hardware model(s)';
    case RuleVariable.CurrentFirmwareVersion:
      return 'Current firmware version(s)';
    case RuleVariable.Active:
      return 'Active';
    case RuleVariable.PendingUpgrade:
      return 'Pending upgrade';
    case RuleVariable.LastUpgradedAt:
      return 'Last upgraded at';
    default:
      return capitalize(ruleVar);
  }
}

export const conditionFieldsForRuleVariable = {
  [RuleVariable.UpgradeSensitive]: {
    variable: RuleVariable.UpgradeSensitive,
    eq: false,
  },
  [RuleVariable.CompanySupportTier]: {
    variable: RuleVariable.CompanySupportTier,
    not: TrueFalse.false,
    in: undefined,
  },
  [RuleVariable.SquareFootage]: {
    variable: RuleVariable.SquareFootage,
    ltGtOperator: undefined,
    value: undefined,
  },
  [RuleVariable.NetworkHWModel]: {
    variable: RuleVariable.NetworkHWModel,
    not: TrueFalse.false,
    in: undefined,
  },
  [RuleVariable.CurrentFirmwareVersion]: {
    variable: RuleVariable.CurrentFirmwareVersion,
    not: TrueFalse.false,
    in: undefined,
  },
  [RuleVariable.Active]: {
    variable: RuleVariable.Active,
    eq: true,
  },
  [RuleVariable.PendingUpgrade]: {
    variable: RuleVariable.PendingUpgrade,
    eq: true,
  },
  [RuleVariable.LastUpgradedAt]: {
    variable: RuleVariable.LastUpgradedAt,
    ltGtOperator: undefined,
    value: undefined,
  },
};

export const ruleVariableOptions = Object.entries(RuleVariable).map(([key, value]) => ({
  label: key,
  value,
}));

export const customerTierOptions = Object.entries(CustomerSupportTierFilterEnum).map(
  ([key, value]) => ({
    label: key,
    value,
  }),
);

export const trueFalseVals = [
  { label: 'true', value: 'true' },
  { label: 'false', value: 'false' },
];

// value is as mapped to 'not' field
export const isIsNotVals = [
  { label: 'is', value: 'false' },
  { label: 'is not', value: 'true' },
];

export const ltGtVals = [
  { label: 'less than', value: 'lt' },
  { label: 'greater than', value: 'gt' },
];

export const deviceModelOptions = Object.entries(DeviceModel).map(([key, value]) => ({
  label: key,
  value,
}));

// value is as mapped to 'not' field
export const containsNotContainsVals = [
  { label: 'contains', value: 'false' },
  { label: 'does not contain', value: 'true' },
];

export const typeDiscriminatorSchema = z.discriminatedUnion('variable', [
  z.object({
    variable: z.literal(RuleVariable.UpgradeSensitive),
    eq: z.boolean(),
  }),
  z.object({
    variable: z.literal(RuleVariable.CompanySupportTier),
    not: z.nativeEnum(TrueFalse),
    in: z.array(z.nativeEnum(CustomerSupportTierFilterEnum)),
  }),
  z.object({
    variable: z.literal(RuleVariable.SquareFootage),
    ltGtOperator: z.nativeEnum(LtGt),
    value: z.number(),
  }),
  z.object({
    variable: z.literal(RuleVariable.NetworkHWModel),
    not: z.nativeEnum(TrueFalse),
    in: z.array(z.nativeEnum(DeviceModel)).nonempty({
      message: 'Please provide at least one hardware model.',
    }),
  }),
  z.object({
    variable: z.literal(RuleVariable.CurrentFirmwareVersion),
    not: z.nativeEnum(TrueFalse),
    in: z.array(z.string()).nonempty({
      message: 'Please provide at least one NOS firmware version.',
    }),
  }),
  z.object({
    variable: z.literal(RuleVariable.Active),
    eq: z.boolean(),
  }),
  z.object({
    variable: z.literal(RuleVariable.PendingUpgrade),
    eq: z.boolean(),
  }),
  z.object({
    variable: z.literal(RuleVariable.LastUpgradedAt),
    value: z.string().nonempty(),
    ltGtOperator: z.nativeEnum(LtGt),
  }),
]);

export const ruleSchema = z
  .object({
    id: z.string(),
  })
  .and(typeDiscriminatorSchema);

export type Rule = z.infer<typeof ruleSchema>;

export const createBulkUpgradeInputSchema = z.object({
  rules: z.array(ruleSchema),
});

export type RulesInput = z.infer<typeof createBulkUpgradeInputSchema>;

export function fieldsToNetworkFilter(fields: RulesInput): NetworksFilter {
  const filter = fields.rules?.reduce((acc, curr) => {
    const { id, variable, ...restFields } = curr;
    const newFields: Record<string, any> = {};
    if ('not' in restFields) {
      Object.keys(restFields).forEach((key) => {
        if (key === 'not') {
          newFields[key] = restFields[key] === 'true';
        }
      });
    }
    if ('in' in restFields) {
      newFields.in = restFields.in;
    }
    if ('eq' in restFields) {
      newFields.eq = restFields.eq;
    }
    if ('value' in restFields) {
      if ('ltGtOperator' in restFields) {
        if (Number.isNaN(restFields.value)) {
          // If it's not a number, it's a date
          newFields[restFields.ltGtOperator] = DateTime.fromJSDate(
            new Date(restFields.value as string),
          ).toISO();
        } else {
          newFields[restFields.ltGtOperator] = Number(restFields.value);
        }
      }
    }
    return { ...acc, [curr.variable]: { ...newFields } };
  }, {});
  return filter;
}
