import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Button,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  EmptyState,
  Pane,
  PaneContent,
  PaneHeader,
  Tooltip,
  useDialogState,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { useGraphQL } from '@meterup/graphql';
import { Link } from 'react-router-dom';

import IsPermitted from '../../../../../components/permissions/IsPermitted';
import { ReactRouterLink } from '../../../../../components/ReactRouterLink';
import { paths } from '../../../../../constants';
import { PermissionType } from '../../../../../gql/graphql';
import { useActiveControllerForNetwork } from '../../../../../hooks/useActiveControllerForNetwork';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { NosFeature } from '../../../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { makeDrawerLink, makeLink } from '../../../../../utils/main_and_drawer_navigation';
import {
  useNavigateBack,
  useNavigateHome,
  useSecureTunnelsCrumbs,
} from '../../../../../utils/routing';
import { IPSecTunnelsQuery } from '../../../../drawers/network/secure_tunnels/ipsec/utils';
import CopyIPSecTunnelDialog from './CopyIPSecTunnelDialog';
import { IPSecTunnelsList } from './IPSecTunnelsList';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/network/:networkSlug/secure-tunnels/ipsec',
  layout: 'VirtualNetworkLayout',
  title: 'IPSec - Secure tunnels',
});

function IPSecTunnels() {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const isOperator = useIsOperator();
  const back = useNavigateBack();
  const home = useNavigateHome();
  const secureTunnelsCrumb = useSecureTunnelsCrumbs();
  const activeController = useActiveControllerForNetwork(network);
  const controllerName = activeController?.hardwareDevice?.serialNumber;
  const { slug: networkSlug, UUID: networkUUID } = useNetwork();
  const ipSecTunnels =
    useGraphQL(IPSecTunnelsQuery, { networkUUID })?.data?.ipSecTunnelsForNetwork || [];

  const { state: copyState } = useDialogState();

  const copyItem = (
    <DropdownMenuItem onClick={copyState.open} icon="copy" disabled={!controllerName} internal>
      Copy IPSec tunnels from config 1
    </DropdownMenuItem>
  );

  return (
    <Pane layoutMode="detailed">
      <PaneHeader
        back={back}
        home={home}
        crumbs={[
          ...secureTunnelsCrumb,
          {
            type: 'page',
            page: {
              as: ReactRouterLink,
              to: makeLink(paths.pages.IPSecTunnelsPage, { companyName, networkSlug }),
              selected: true,
              label: 'IPSec',
            },
          },
        ]}
        icon="ipsec-tunnel"
        heading="IPSec"
        actions={
          <IsPermitted permissions={PermissionType.PermIpsecTunnelWrite}>
            <Button
              condense
              size="small"
              as={Link}
              to={makeDrawerLink(window.location, paths.drawers.CreateIPSecTunnelPage, {
                companyName,
                networkSlug,
              })}
              arrangement="leading-icon"
              icon="plus"
              variant="secondary"
            >
              Add IPSec tunnel
            </Button>
            {isOperator && controllerName && (
              <>
                <DropdownMenu>
                  <DropdownMenuButton
                    variant="secondary"
                    icon="overflow-horizontal"
                    arrangement="hidden-label"
                  >
                    Actions
                  </DropdownMenuButton>
                  <DropdownMenuPopover align="end">
                    <DropdownMenuGroup>
                      {controllerName ? (
                        copyItem
                      ) : (
                        <Tooltip contents="Could not find controller for network">
                          {copyItem}
                        </Tooltip>
                      )}
                    </DropdownMenuGroup>
                  </DropdownMenuPopover>
                </DropdownMenu>
                <CopyIPSecTunnelDialog state={copyState} controllerName={controllerName} />
              </>
            )}
          </IsPermitted>
        }
      />
      <PaneContent>
        {ipSecTunnels.length ? (
          <IPSecTunnelsList ipSecTunnels={ipSecTunnels} />
        ) : (
          <EmptyState
            icon="ipsec-tunnel"
            heading="You have no IPSec tunnels"
            action={
              <IsPermitted permissions={PermissionType.PermIpsecTunnelWrite}>
                <Button
                  as={Link}
                  to={makeDrawerLink(window.location, paths.drawers.CreateIPSecTunnelPage, {
                    companyName,
                    networkSlug,
                  })}
                  arrangement="leading-icon"
                  icon="plus"
                >
                  Add IPSec tunnel
                </Button>
              </IsPermitted>
            }
          />
        )}
      </PaneContent>
    </Pane>
  );
}

export default function IPSecTunnelsPage() {
  return (
    <IsPermitted
      isPermitted={({ permissions, ldFlags, nosFlags }) =>
        Boolean(
          permissions.hasPermission(PermissionType.PermIpsecTunnelRead) &&
            ldFlags['ipsec-tunnels'] &&
            nosFlags[NosFeature.IPSEC_TUNNELS] &&
            nosFlags[NosFeature.COS2],
        )
      }
      should404OnAccessDenied
    >
      <IPSecTunnels />
    </IsPermitted>
  );
}
