import {
  Badge,
  Button,
  EmptyState,
  HStack,
  Link as AttoLink,
  Pane,
  PaneContent,
  PaneHeader,
  Small,
  space,
  ToggleInput,
  Tooltip,
} from '@meterup/atto';
import { AutoTable } from '@meterup/common';
import { Link } from 'react-router-dom';

import type { AlertReceiver } from '../../../Organization/Integrations/utils';
import type { NotificationSetting } from '../utils';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';
import {
  useCurrentCompany,
  useCurrentCompany2,
} from '../../../../../providers/CurrentCompanyProvider';
import { useNetworkForSettingsPath } from '../../../../../routes/pages/settings/network/NetworkUtils';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';
import { LocationSwitcher } from '../../../../LocationSwitcher';
import { createColumnBuilder } from '../../../../Table/createColumnBuilder';
import { useAlertReceivers } from '../../../Organization/Integrations/utils';
import { useNotificationSettings, useToggleEnabledNotificationSetting } from '../utils';

function ReceiversWithTooltip({ receiver }: { receiver: AlertReceiver | undefined }) {
  if (!receiver) return null;
  const NUM_SHOWN = 2;
  return (
    <Tooltip
      contents={
        <HStack align="center" spacing={space(4)} wrap="wrap">
          {receiver.targets.slice(0, NUM_SHOWN).map((target) => (
            <Badge key={target.UUID} size="small" variant="neutral">
              {target.__typename === 'AlertTargetEmail'
                ? target.emailAddress
                : target.label ?? target.url}
            </Badge>
          ))}
          {receiver.targets.length > NUM_SHOWN && (
            <Small>
              and{' '}
              <AttoLink>
                {receiver.targets.length - NUM_SHOWN}{' '}
                {`${receiver.targets.length - NUM_SHOWN > 1 ? 'others' : 'other'}`}...
              </AttoLink>
            </Small>
          )}
        </HStack>
      }
    >
      <Badge arrangement="leading-icon" icon="tag" size="small" variant="brand">
        {receiver.label}
      </Badge>
    </Tooltip>
  );
}

const builder = createColumnBuilder<NotificationSetting>();

function createColumns({
  receivers,
  toggleEnabled,
}: {
  receivers: AlertReceiver[];
  toggleEnabled: (notificationUUID: string, currentIsEnabled: boolean) => void;
}) {
  return [
    builder.display({
      id: 'enable',
      header: 'Enabled',
      meta: {
        width: space(68),
        alignment: 'center',
        hideSortIcon: true,
      },
      cell: ({ row }) => (
        <ToggleInput
          onChange={() => toggleEnabled(row.UUID, row.isEnabled)}
          selected={row.isEnabled ?? false}
          controlSize="small"
        />
      ),
    }),
    builder.data((row) => row.notificationDefinition?.displayName, {
      id: 'trigger',
      header: 'Trigger',
      meta: {
        isLeading: true,
      },
    }),
    builder.display({
      id: 'receiver',
      header: 'Receiver',
      cell: ({ row }) => {
        const receiver = receivers.find((r) => r.UUID === row.alertReceiverUUID);
        return <ReceiversWithTooltip receiver={receiver} />;
      },
    }),
  ];
}

export default function Alerts() {
  const companyName = useCurrentCompany();
  const network = useNetworkForSettingsPath({
    path: paths.pages.SettingsNetworkNotificationsPage,
  });
  const settings = useNotificationSettings({ networkUUID: network.UUID });
  const company = useCurrentCompany2();
  const receivers = useAlertReceivers({ companyUUID: company.uuid });
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.AlertEditPage);
  const toggleEnabled = useToggleEnabledNotificationSetting();

  const columns = createColumns({ receivers, toggleEnabled });

  return (
    <Pane>
      <PaneHeader
        icon="bell"
        heading="Notifications"
        actions={
          <Button
            as={Link}
            to={makeDrawerLink(window.location, paths.drawers.AlertsAddPage, {
              companyName,
              networkSlug: network.slug,
            })}
            arrangement="leading-icon"
            icon="plus"
            variant="secondary"
          >
            Add alert
          </Button>
        }
        views={
          <LocationSwitcher
            networkSlug={network.slug}
            shouldNotChangePages
            routePathsNotToChange={[paths.pages.AlertsPage]}
          />
        }
      />
      <PaneContent spacing={space(0)}>
        {settings.length > 0 ? (
          <AutoTable
            key="alerts-table"
            columns={columns}
            data={settings}
            isRowSelected={(row) => row.UUID === drawerParams?.uuid}
            getLinkTo={(row) =>
              makeDrawerLink(window.location, paths.drawers.AlertEditPage, {
                companyName,
                networkSlug: network.slug,
                uuid: row.UUID,
              })
            }
          />
        ) : (
          <EmptyState
            icon="attention"
            heading="No alerts"
            action={
              <Button
                as={Link}
                to={makeDrawerLink(window.location, paths.drawers.AlertsAddPage, {
                  companyName,
                  networkSlug: network.slug,
                })}
                arrangement="leading-icon"
                icon="plus"
                variant="secondary"
              >
                Add alert
              </Button>
            }
          />
        )}
      </PaneContent>
    </Pane>
  );
}
