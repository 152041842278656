import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader } from '@meterup/atto';
import { expectDefinedOrThrow, notify, ResourceNotFoundError } from '@meterup/common';
import { getGraphQLError, makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { PermissionType } from '../../../gql/graphql';
import { UpdateVirtualDeviceInputSchema as BaseSchema } from '../../../gql/zod-types';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../hooks/useNosFeatures';
import { styled } from '../../../stitches';
import { shouldShowConsoleToggle } from '../../../utils/devices';
import { HardwareDeviceField } from '../../Devices/Fields';
import { updateVirtualDeviceMutation } from '../../Devices/utils';
import IsPermitted from '../../permissions/IsPermitted';
import {
  PowerDistributionUnitDescriptionField,
  PowerDistributionUnitIsConsoleEnabledField,
  PowerDistributionUnitNameField,
} from './Fields';
import PowerDistributionUnitActions from './PowerDistributionUnitActions';
import { PowerDistributionUnitQuery, PowerDistributionUnitsQuery } from './utils';

type PowerDistributionUnitEditProps = {
  uuid: string;
};

const UpdatePowerDistributionUnitInputSchema = BaseSchema.extend({
  // Make this required (its nullish in the base schema)
  label: z.string(),
  description: z.string().nullish(),
  isConsoleEnabled: z.boolean(),
});
type ValidPowerUnitParams = z.infer<typeof UpdatePowerDistributionUnitInputSchema>;

const StyledForm = styled(Form, {
  display: 'contents',
  flexDirection: 'column',
  gap: '$8',
});

function PowerDistributionUnitConfig({ uuid }: PowerDistributionUnitEditProps) {
  const network = useNetwork();
  const virtualDeviceMutation = useGraphQLMutation(updateVirtualDeviceMutation);
  const queryClient = useQueryClient();
  const virtualDevice = useGraphQL(PowerDistributionUnitQuery, { uuid }).data?.virtualDevice;
  const closeDrawer = useCloseDrawerCallback();
  expectDefinedOrThrow(virtualDevice, new ResourceNotFoundError('PowerDistributionUnit not found'));
  if (virtualDevice.networkUUID !== network.UUID) {
    throw new ResourceNotFoundError('PowerDistributionUnit not found on this network');
  }
  const showConsoleToggle = shouldShowConsoleToggle(
    useNosFeatureEnabled(NosFeature.POSConsoleChallengeResponse),
  );

  const handleSubmit = useCallback(
    (v: ValidPowerUnitParams) => {
      virtualDeviceMutation.mutate(
        {
          uuid: virtualDevice.UUID,
          input: {
            label: v.label,
            description: v.description,
            enableConsolePort: v.isConsoleEnabled,
          },
        },
        {
          onSuccess: (response) => {
            notify('Successfully updated Power distribution unit.', { variant: 'positive' });
            queryClient.invalidateQueries(
              makeQueryKey(PowerDistributionUnitQuery, { uuid: response.updateVirtualDevice.UUID }),
            );
            queryClient.invalidateQueries(
              makeQueryKey(PowerDistributionUnitsQuery, { networkUUID: network.UUID }),
            );
          },
          onError: (err) => {
            const gqlErr = getGraphQLError(err);
            notify(
              `There was an error updating this Power distribution unit${
                gqlErr?.message ? `: ${gqlErr.message}` : ''
              }.`,
              {
                variant: 'negative',
              },
            );
          },
        },
      );
    },
    [virtualDeviceMutation, network.UUID, queryClient, virtualDevice.UUID],
  );

  return (
    <Formik<ValidPowerUnitParams>
      validationSchema={toFormikValidationSchema(UpdatePowerDistributionUnitInputSchema)}
      initialValues={{
        label: virtualDevice.label,
        description: virtualDevice.description,
        isConsoleEnabled: virtualDevice.isConsoleEnabled,
      }}
      onSubmit={handleSubmit}
    >
      <StyledForm>
        <DrawerContent>
          <PowerDistributionUnitNameField />
          <PowerDistributionUnitDescriptionField />
          <IsPermitted
            isPermitted={({ permissions, nosFlags }) =>
              Boolean(
                permissions.hasPermission(PermissionType.PermNetworkDevicesWriteRestricted) &&
                  nosFlags[NosFeature.POS],
              )
            }
          >
            <HardwareDeviceField
              virtualDevice={virtualDevice}
              onSuccess={async () => {
                queryClient.invalidateQueries(
                  makeQueryKey(PowerDistributionUnitsQuery, { networkUUID: network.UUID }),
                );
                return queryClient.invalidateQueries(
                  makeQueryKey(PowerDistributionUnitQuery, { uuid }),
                );
              }}
              internal
            />
            {showConsoleToggle && <PowerDistributionUnitIsConsoleEnabledField />}
          </IsPermitted>
        </DrawerContent>
        <DrawerFooter
          actions={
            <>
              <Button type="button" onClick={closeDrawer} variant="secondary">
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </>
          }
        />
      </StyledForm>
    </Formik>
  );
}

export default function PowerDistributionUnitEdit({ uuid }: PowerDistributionUnitEditProps) {
  const virtualDevice = useGraphQL(PowerDistributionUnitQuery, { uuid }).data?.virtualDevice;

  return (
    <Drawer>
      <DrawerHeader
        icon="pdu"
        heading="Edit power distribution unit"
        onClose={useCloseDrawerCallback()}
        actions={
          <PowerDistributionUnitActions
            serialNumber={
              virtualDevice && virtualDevice.hardwareDevice
                ? virtualDevice.hardwareDevice.serialNumber
                : ''
            }
            uuid={uuid}
            view="edit"
          />
        }
      />
      <PowerDistributionUnitConfig uuid={uuid} />
    </Drawer>
  );
}
