import { Drawer, DrawerContent, DrawerHeader } from '@meterup/atto';
import { notify } from '@meterup/common';
import { useGraphQLMutation } from '@meterup/graphql';
import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';

import type { CompanyUserInputSchemaType } from './utils/schema';
import { paths } from '../../constants';
import { CompanyMembershipRole } from '../../gql/graphql';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { makeDrawerLink } from '../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../utils/withZodSchema';
import EditUserForm from './EditUserForm';
import FormFooter from './FormFooter';
import { createCompanyUser, useCompanyUsers, useInvalidateCompanyUsers } from './utils/queries';
import { CompanyUserInputSchema } from './utils/schema';

export default function AddUser({ companySlug }: { companySlug: string }) {
  const navigate = useNavigate();
  const initialValues: CompanyUserInputSchemaType = {
    email: '',
    firstName: '',
    companyMembershipRole: CompanyMembershipRole.Admin,
    lastName: '',
  };
  const createUserMutation = useGraphQLMutation(createCompanyUser);
  const companyUsers = useCompanyUsers(companySlug);
  const invalidateUsers = useInvalidateCompanyUsers(companySlug);
  const handleSubmit = useCallback(
    (values: CompanyUserInputSchemaType) => {
      const { email, firstName, lastName, companyMembershipRole } = values;
      const cu = companyUsers?.data?.companyUsers ?? [];
      const isExistingUser = cu.some((u) => u.user.email === email);
      if (isExistingUser) {
        notify('User already exists', { variant: 'negative' });
        return;
      }

      createUserMutation.mutate(
        {
          companySlug,
          input: {
            email,
            firstName,
            lastName,
            companyMembershipRole,
          },
        },
        {
          onSuccess(resp) {
            invalidateUsers();
            notify('Successfully created user', { variant: 'positive' });
            navigate(
              makeDrawerLink(window.location, paths.drawers.UserDrawerPage, {
                companyName: companySlug,
                uuid: resp.createCompanyUser.UUID,
              }),
            );
          },
          onError() {
            notify('Failed to created user', { variant: 'negative' });
          },
        },
      );
    },
    [companyUsers?.data?.companyUsers, createUserMutation, companySlug, invalidateUsers, navigate],
  );
  const onClose = useCloseDrawerCallback();

  return (
    <Drawer>
      <DrawerHeader icon="plus" heading="Add user" onClose={onClose} />
      <Formik<CompanyUserInputSchemaType>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={withZodSchema(CompanyUserInputSchema)}
      >
        <Form>
          <DrawerContent>
            <EditUserForm emailIsEditable />
          </DrawerContent>
          <FormFooter />
        </Form>
      </Formik>
    </Drawer>
  );
}
