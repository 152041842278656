import { useRoles } from '@meterup/authorization';
import { isDefinedAndNotEmpty } from '@meterup/common';
import { useZendesk } from '@meterup/react-use-zendesk';
import * as Sentry from '@sentry/react';
import { useEffect, useMemo } from 'react';

import { RoleName } from '../gql/graphql';
import { useNetworkOrNull } from '../hooks/useNetworkFromPath';
import { useCurrentCompanyOrDefault } from '../providers/CurrentCompanyProvider';
import { useDefaultNetwork } from '../providers/DefaultNetworkProvider';
import { useIdentity } from '../providers/IdentityDataProvider';

export function zendeskFeedbackURL(email: string, subject?: string): string {
  const baseUrl = 'https://meterup.zendesk.com/hc/en-us/requests/new';
  const queryParams = new URLSearchParams();
  queryParams.set('ticket_form_id', '10061369368589');
  queryParams.set('utm_campaign', 'Support Ticket Form');
  queryParams.set('utm_source', email);
  queryParams.set('utm_medium', 'dashboard');
  queryParams.set('tf_anonymous_requester_email', email);
  if (isDefinedAndNotEmpty(subject)) {
    queryParams.set('tf_subject', subject);
  }
  return `${baseUrl}?${queryParams.toString()}`;
}

export enum ZendeskField {
  Name = '27378118501005',
  Email = '27378120265613',
  Role = '26895040085773',
  DeviceType = '26895049519629',
}

enum ZendeskValueRole {
  Technical = 'technical',
  NonTechnical = 'non-technical',
}

// Using types for exhaustiveness checking
// eslint-disable-next-line consistent-return
function isTechnicalRole(role: RoleName): boolean {
  switch (role) {
    case RoleName.Operator:
    case RoleName.CompanyNetworkAdmin:
    case RoleName.CompanyGlobalAdmin:
      return true;
    case RoleName.CompanyNetworkAdminReadOnly:
    case RoleName.CompanyGuest:
    case RoleName.CompanyStandardUser:
      return false;
  }
}

export function usePrefillZendeskFields() {
  const currentNetwork = useNetworkOrNull();
  const { defaultNetwork } = useDefaultNetwork();
  const companySlug = useCurrentCompanyOrDefault();
  const identity = useIdentity();
  const roles = useRoles();
  const { setConversationFields } = useZendesk();
  useEffect(() => {
    setConversationFields([
      {
        id: ZendeskField.Name,
        value: [identity.first_name, identity.last_name].filter(Boolean).join(' '),
      },
      {
        id: ZendeskField.Email,
        value: identity.username,
      },
    ]);
  }, [identity, setConversationFields]);
  const network = useMemo(() => currentNetwork ?? defaultNetwork, [currentNetwork, defaultNetwork]);
  useEffect(() => {
    const isTechnical = roles.data?.roles.some(
      (roleAssignment) =>
        roleAssignment.name === RoleName.Operator ||
        (((network?.UUID && roleAssignment.networkUUID === network.UUID) ||
          (!roleAssignment.networkUUID && roleAssignment.companySlug === companySlug)) &&
          isTechnicalRole(roleAssignment.name)),
    );
    setConversationFields([
      {
        id: ZendeskField.Role,
        value: isTechnical ? ZendeskValueRole.Technical : ZendeskValueRole.NonTechnical,
      },
    ]);
  }, [companySlug, network?.UUID, roles, setConversationFields]);
}

export async function createZendeskTicket({
  identity,
  subject,
  description,
  severity,
  networkType,
  locationName,
  issueType,
  deviceMacAddress,
  issueLocation,
}: {
  subject: string;
  description: string;
  severity: string;
  networkType: string;
  issueType: string;
  deviceMacAddress: string;
  issueLocation: string;
  locationName: string;
  identity: { first_name: string; last_name: string; username: string };
}) {
  const ticketData = {
    request: {
      subject,
      comment: {
        body: description,
      },
      requester: {
        name: `${identity.first_name} ${identity.last_name}`,
        email: identity.username,
      },
      custom_fields: [
        { id: '11396470000269', value: severity },
        { id: '21569456788237', value: networkType },
        { id: '21569583576845', value: locationName },
        { id: '26895008761741', value: issueType },
        { id: '21569591800461', value: deviceMacAddress },
        { id: '21569556481677', value: issueLocation },
      ],
    },
  };

  const token = btoa(`${import.meta.env.ZENDESK_EMAIL}/token:${import.meta.env.ZENDESK_API_KEY}`);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${token}`,
    },
    body: JSON.stringify(ticketData),
  };

  const response = await fetch('https://meterup.zendesk.com/api/v2/requests.json', requestOptions);
  if (!response.ok) {
    Sentry.captureMessage(`Failed to submit a Zendesk ticket}`, {
      level: 'error', // specify the level of the message, e.g., 'info', 'warning', 'error'
      tags: {
        module: 'Zendesk',
      },
      extra: {
        ticketData,
      },
    });
    throw new Error(
      `Error reaching out to Meter Support. Please contact us at https://meter.com/support. HTTP Status: ${response.status}`,
    );
  }
  await response.json();
}
