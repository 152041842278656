import {
  Alert,
  Button,
  CompositeField,
  HStack,
  PrimaryField,
  Select,
  SelectItem,
  space,
  VStack,
} from '@meterup/atto';
import { notify } from '@meterup/common';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';

import type { UplinkPhyInterface } from '../../Firewall/utils';
import type { Network, UpdateNetworkSAPPProfileFormValues } from './utils';
import { MultiWanAlgorithm } from '../../../gql/graphql';
import { withZodSchema } from '../../../utils/withZodSchema';
import { uplinkPhyInterfacesQuery } from '../../Firewall/utils';
import { FieldProvider } from '../../Form/FieldProvider';
import {
  networkQuery,
  updateNetworkSAPPProfileFormValues,
  updateSAPPProfileMutation,
  usePrimarySAPPProfileForNetworkSettings,
} from './utils';

function hasWANActivity(phyInterface: UplinkPhyInterface): boolean {
  return Boolean(phyInterface.hasWANActivity || phyInterface.uplinkExternalAddresses?.length);
}

export default function NetworkMultiWANAlgorithm({ network }: { network: Network }) {
  const queryClient = useQueryClient();
  const updateNetworkSAPPProfile = useGraphQLMutation(updateSAPPProfileMutation);
  const sappProfile = usePrimarySAPPProfileForNetworkSettings();

  const handleSubmit = useCallback(
    ({ multiWANAlgorithm }: UpdateNetworkSAPPProfileFormValues) => {
      updateNetworkSAPPProfile.mutate(
        { networkUUID: network.UUID, input: { multiWANAlgorithm } },
        {
          onSuccess() {
            notify('Successfully updated network multi-WAN settings.', {
              variant: 'positive',
            });
            queryClient.invalidateQueries(makeQueryKey(networkQuery, { uuid: network.UUID }));
          },
          onError(err) {
            notify(
              `There was a problem updating network multi-WAN settings${getGraphQLErrorMessageOrEmpty(err)}.`,
              {
                variant: 'positive',
              },
            );
          },
        },
      );
    },
    [updateNetworkSAPPProfile, network.UUID, queryClient],
  );

  const uplinks = useGraphQL(uplinkPhyInterfacesQuery, { networkUUID: network.UUID }).data
    ?.uplinkPhyInterfacesForNetwork;

  const hasMultipleWANs = useMemo(() => {
    if (!uplinks?.length) return false;
    const set = new Set<string>();

    for (const uplink of uplinks) {
      if (!hasWANActivity(uplink)) continue;

      if (set.has(uplink.virtualDevice.UUID)) return true;

      set.add(uplink.virtualDevice.UUID);
    }

    return false;
  }, [uplinks]);

  return (
    <Formik<UpdateNetworkSAPPProfileFormValues>
      initialValues={{
        multiWANAlgorithm: sappProfile.multiWANAlgorithm ?? MultiWanAlgorithm.FirstActive,
      }}
      validate={withZodSchema(updateNetworkSAPPProfileFormValues)}
      onSubmit={handleSubmit}
    >
      <Form>
        <PrimaryField
          label="Multi-WAN algorithm"
          internal
          description={
            <VStack spacing={space(12)}>
              <ul>
                <li>
                  <b>First active</b>: The first port (lowest uplink priority, then lowest port
                  number) with an active WAN connection will be preferred for all traffic, failing
                  over to the subsequent WAN if it loses connectivity.
                </li>
                <li>
                  <b>Round robin</b>: If multiple WANs are available, traffic will be spread among
                  them evenly.
                </li>
              </ul>
              {!hasMultipleWANs && (
                <Alert
                  icon="information"
                  heading="This setting will not take effect without multiple WAN uplinks active for a single security appliance."
                  variant="attention"
                  type="inline"
                />
              )}
            </VStack>
          }
          element={
            <HStack justify="between" align="end" spacing={space(8)}>
              <FieldProvider name="multiWANAlgorithm">
                <CompositeField
                  label="Multi-WAN algorithm"
                  element={
                    <Select>
                      <SelectItem key={MultiWanAlgorithm.FirstActive} textValue="First active">
                        First active
                      </SelectItem>
                      <SelectItem key={MultiWanAlgorithm.RoundRobin} textValue="Round robin">
                        Round robin
                      </SelectItem>
                    </Select>
                  }
                />
              </FieldProvider>

              <Button type="submit" variant="secondary">
                Save
              </Button>
            </HStack>
          }
        />
      </Form>
    </Formik>
  );
}
