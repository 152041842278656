import { Text } from '@meterup/atto';
import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import React from 'react';
import { z } from 'zod';

import type { UpdateNetworkNetworkQuery } from '../../../gql/graphql';
import { graphql } from '../../../gql';
import {
  CreateMailingAddressInputSchema,
  CreateNetworkInputSchema,
  UpdateSecurityApplianceProfileInputSchema,
} from '../../../gql/zod-types';
import useCurrentControllers, {
  useCurrentControllersForSettings,
} from '../../Hardware/SecurityAppliance/useCurrentControllers';
import { isPrimaryController } from '../../Hardware/SecurityAppliance/utils2';

export const networkQuery = graphql(`
  query UpdateNetworkNetwork($uuid: UUID!) {
    network(UUID: $uuid) {
      UUID
      slug
      label
      isActive
      isCustomer
      isTemplate
      isConfig1WosUpgradesEnabled
      isUpgradeSensitive
      onboarding {
        UUID
        jobData {
          id
          jobID
        }
      }
      mailingAddress {
        UUID
        line1
        line2
        city
        subdivisionCode
        postalCode
        countryISO2
        timezone
      }
    }
  }
`);

export const createNetworkMutation = graphql(`
  mutation CreateNetwork($input: CreateNetworkInput!) {
    createNetwork(input: $input) {
      UUID
      slug
    }
  }
`);

export const updateNetworkMutation = graphql(`
  mutation UpdateNetwork($uuid: UUID!, $input: UpdateNetworkInput!) {
    updateNetwork(UUID: $uuid, input: $input) {
      UUID
      slug
    }
  }
`);

export const updateSAPPProfileMutation = graphql(`
  mutation UpdateSAPPProfilesMutation(
    $networkUUID: UUID!
    $input: UpdateSecurityApplianceProfileInput!
  ) {
    updateSecurityApplianceProfilesForNetwork(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

// Fetches the SAPP profile for the primary controller in the network
export function usePrimarySAPPProfileForNetworkSettings() {
  const controllers = useCurrentControllersForSettings();
  if (controllers.virtualDevices.length === 0) {
    throw new ResourceNotFoundError('No controllers found');
  }
  const primaryController =
    controllers.virtualDevices.find((controller) => isPrimaryController(controller)) ??
    controllers.virtualDevices[0];

  return checkDefinedOrThrow(
    primaryController.securityApplianceProfile,
    new ResourceNotFoundError('No security appliance profile found'),
  );
}

// TODO: Consolidate this function (AP)
export function usePrimarySAPPProfileForNetwork() {
  const controllers = useCurrentControllers();
  if (controllers.virtualDevices.length === 0) {
    throw new ResourceNotFoundError('No controllers found');
  }
  const primaryController =
    controllers.virtualDevices.find((controller) => isPrimaryController(controller)) ??
    controllers.virtualDevices[0];

  return checkDefinedOrThrow(
    primaryController.securityApplianceProfile,
    new ResourceNotFoundError('No security appliance profile found'),
  );
}

export const deleteNetworkMutation = graphql(`
  mutation DeleteNetwork($uuid: UUID!) {
    deleteNetwork(UUID: $uuid) {
      UUID
    }
  }
`);

export const jobTrackersQuery = graphql(`
  query JobTrackers {
    jobTrackers {
      id
      jobID
      totalSqFt
      fullAddress
      locationCode
    }
  }
`);

export const createNetworkOnboardingMutation = graphql(`
  mutation CreateNetworkOnboarding($networkUUID: UUID!, $jobTrackerID: String!) {
    createNetworkOnboarding(networkUUID: $networkUUID, jobTrackerID: $jobTrackerID) {
      UUID
    }
  }
`);

export const maintenanceWindowQuery = graphql(`
  query MaintenanceWindowForNetwork($networkUUID: UUID!) {
    maintenanceWindowForNetwork(networkUUID: $networkUUID) {
      recurrence
      timezone
      isOptedOut
      startHourOfDayLocal
      endHourOfDayLocal
      startDayOfWeekLocal
      endDayOfWeekLocal
    }
  }
`);

export const updateMaintenanceWindowMutation = graphql(`
  mutation UpdateMaintenanceWindow($networkUUID: UUID!, $input: UpdateMaintenanceWindowInput!) {
    updateMaintenanceWindowForNetwork(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

const SLUG_REGEX = /^[-a-z0-9]*$/;

export const editMailingAddressValues = CreateMailingAddressInputSchema.extend({
  line1: z.string().nonempty({ message: 'Please provide a street address.' }),
  line2: z.string().nullish(),
  city: z.string().nonempty({ message: 'Please provide a city. ' }),
  subdivisionCode: z.string().nonempty({ message: 'Please provide a state. ' }),
  postalCode: z.string().nonempty({ message: 'Please provide a postal code. ' }),
  countryISO2: z.string().nonempty({ message: 'Please select a country. ' }),
});

export type EditMailingAddressVaue = z.input<typeof editMailingAddressValues>;

export const editNetworkFormValues = CreateNetworkInputSchema.extend({
  slug: z
    .string()
    .min(3, { message: 'Please provide a slug at least 3 characters long.' })
    .refine((slug) => SLUG_REGEX.test(slug), {
      message: 'Slug must contain only lowercase letters, numbers, and hyphens.',
    }),
  label: z.string().nonempty({ message: 'Please provide a label.' }),
  mailingAddressInput: editMailingAddressValues,
  isConfig1WosUpgradesEnabled: z.boolean(),
  isUpgradeSensitive: z.boolean().nullish(),
  jobTrackerID: z.string().nullish(),
});

export type EditNetworkFormValues = z.input<typeof editNetworkFormValues>;

export const updateNetworkSAPPProfileFormValues = UpdateSecurityApplianceProfileInputSchema.pick({
  multiWANAlgorithm: true,
});

export type UpdateNetworkSAPPProfileFormValues = z.input<typeof updateNetworkSAPPProfileFormValues>;

export type Network = UpdateNetworkNetworkQuery['network'];

export const copyMultiWANConfigMutation = graphql(`
  mutation CopyMultiWANFromConfig1($networkUUID: UUID!) {
    copyNetworkMultiWANAlgorithmFromConfig1ToConfig2(networkUUID: $networkUUID) {
      UUID
    }
  }
`);

export const copyFirewallRulesMutation = graphql(`
  mutation CopyFirewallRulesFromConfig1($networkUUID: UUID!) {
    copyFirewallRulesFromConfig1ToConfig2(networkUUID: $networkUUID) {
      UUID
    }
  }
`);

export const copyPortForwardingRulesMutation = graphql(`
  mutation CopyPortForwardingRulesFromConfig1($networkUUID: UUID!) {
    copyPortForwardingRulesFromConfig1ToConfig2(networkUUID: $networkUUID) {
      UUID
    }
  }
`);

export const copyUplinkPhyInterfacesMutation = graphql(`
  mutation CopyUplinkPhyInterfacesFromConfig1($networkUUID: UUID!) {
    copyUplinkPhyInterfacesFromConfig1ToConfig2(networkUUID: $networkUUID) {
      UUID
    }
  }
`);

export function Mono({ children }: { children: React.ReactNode }) {
  return <Text family="monospace">{children}</Text>;
}
