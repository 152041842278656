import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow } from '@meterup/common';
import React from 'react';

import UserDrawer from '../../../../../components/Users/UserDrawer';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/settings/org/users/:uuid',
  title: 'User - Users - Org',
});

export default function UserDrawerPage() {
  const { companyName, uuid } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.UserDrawerPage),
  );

  return <UserDrawer companySlug={companyName} uuid={uuid} />;
}
