import type { EmptyObject, Simplify } from 'type-fest';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';

import { paths } from '../../../constants';
import {
  type ControllersForSecurityApplianceQueryQuery,
  HighAvailabilityControllerRole,
  PermissionType,
} from '../../../gql/graphql';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { useNetworkForSettingsPath } from '../../../routes/pages/settings/network/NetworkUtils';
import { ControllersForSecurityApplianceQuery } from './utils';

type Query = ControllersForSecurityApplianceQueryQuery;
export type VirtualDevices = Query['virtualDevicesForNetwork'];
export type VirtualDevice = VirtualDevices[number];
export type HardwareDevice = VirtualDevice['hardwareDevice'];

export type ControllerHardwareDevice = Simplify<
  Extract<HardwareDevice, { __typename: 'ControllerHardwareDevice' }>
>;

type ControllerVD = Simplify<
  Exclude<Extract<VirtualDevice, { __typename: 'ControllerVirtualDevice' }>, EmptyObject>
>;

export type ControllerVirtualDevice = Simplify<
  Omit<ControllerVD, 'hardwareDevice'> & {
    hardwareDevice?: ControllerHardwareDevice | null;
  }
>;
export type PhyInterface = ControllerVirtualDevice['phyInterfaces'][0];

function isControllerVD(virtualDevice: VirtualDevice): virtualDevice is ControllerVirtualDevice {
  return virtualDevice.__typename === 'ControllerVirtualDevice';
}

function isControllerHD(
  hardwareDevice: HardwareDevice,
): hardwareDevice is ControllerHardwareDevice {
  return hardwareDevice?.__typename === 'ControllerHardwareDevice';
}

function filterController(virtualDevice?: VirtualDevice): virtualDevice is ControllerVirtualDevice {
  if (!virtualDevice) {
    return false;
  }
  if (!isControllerVD(virtualDevice)) {
    return false;
  }
  const { hardwareDevice } = virtualDevice;
  if (hardwareDevice && !isControllerHD(hardwareDevice)) {
    return false;
  }
  return true;
}

function selectControllers(result?: Query): ControllerVirtualDevice[] {
  if (!result) return [];
  return result.virtualDevicesForNetwork.filter(filterController);
}

const orderedHARoles = [
  HighAvailabilityControllerRole.Primary,
  HighAvailabilityControllerRole.Backup,
];

export default function useCurrentControllers() {
  const network = useNetwork();
  const { hasPermission } = usePermissions();
  const virtualDevicesResponse = useGraphQL(ControllersForSecurityApplianceQuery, {
    networkUUID: network.UUID,
    includeIsDev: hasPermission(PermissionType.PermNetworkDevicesReadRestricted),
  });
  const virtualDevices = useMemo(
    () =>
      selectControllers(virtualDevicesResponse.data).sort((a, b) => {
        if (a.highAvailability && b.highAvailability) {
          return (
            orderedHARoles.indexOf(a.highAvailability.role) -
            orderedHARoles.indexOf(b.highAvailability.role)
          );
        }

        return a.label.localeCompare(b.label);
      }),
    [virtualDevicesResponse.data],
  );
  return { virtualDevices, virtualDevicesResponse };
}

// TODO: Consolidate this function (AP)
export function useCurrentControllersForSettings() {
  const network = useNetworkForSettingsPath({
    path: paths.pages.SettingsNetworkGeneralPage,
  });
  const { hasPermission } = usePermissions();
  const virtualDevicesResponse = useGraphQL(ControllersForSecurityApplianceQuery, {
    networkUUID: network.UUID,
    includeIsDev: hasPermission(PermissionType.PermNetworkDevicesReadRestricted),
  });
  const virtualDevices = useMemo(
    () =>
      selectControllers(virtualDevicesResponse.data).sort((a, b) => {
        if (a.highAvailability && b.highAvailability) {
          return (
            orderedHARoles.indexOf(a.highAvailability.role) -
            orderedHARoles.indexOf(b.highAvailability.role)
          );
        }

        return a.label.localeCompare(b.label);
      }),
    [virtualDevicesResponse.data],
  );
  return { virtualDevices, virtualDevicesResponse };
}
