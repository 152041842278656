import type { ClientError } from 'graphql-request';
import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  space,
  VStack,
} from '@meterup/atto';
import { isDefinedAndNotEmpty, notify } from '@meterup/common';
import { SsidDynamicVlanMode } from '@meterup/common/src/gql/graphql';
import { getGraphQLError, makeQueryKey, useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import type { CreateSsidInput, UpdateSsidInput } from '../../../gql/graphql';
import type { SSIDsQueryResult, ValidCreateSSIDParams } from './SSIDsUtils';
import { paths } from '../../../constants';
import { SsidEncryptionProtocol, SsidPasswordRotationCadence } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeaturesEnabled } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../utils/withZodSchema';
import { vlansQuery } from '../../NetworkWide/VLANs/utils';
import {
  AccessPointsField,
  BandsField,
  ClientIsolationField,
  ClientSteeringField,
  Enable80211axField,
  Enable80211kField,
  Enable80211vField,
  EnabledField,
  FastRoamField,
  FrameProtectionField,
  PasswordField,
  RadiusChangeOfAuthorizationField,
  SSIDField,
  VLANField,
} from './SSIDFields';
import {
  createSSIDInputSchema,
  CreateSSIDMutation,
  enterpriseEncryptionProtocol,
  SSIDsQuery,
  UpdateSSIDMutation,
} from './SSIDsUtils';

export default function SSIDForm({ ssid }: { ssid?: SSIDsQueryResult }) {
  const closeDrawer = useCloseDrawerCallback();
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const navigate = useNavigate();
  const timezone = network.mailingAddress?.timezone;
  const [errorText, setErrorText] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const createSSIDMutation = useGraphQLMutation(CreateSSIDMutation);
  const updateSSIDMutation = useGraphQLMutation(UpdateSSIDMutation);

  const [
    isNOSEnabled80211ax,
    isNOSEnabled80211r,
    isNOSEnabled80211w,
    isNOSEnabledClientAssociationSteering,
    isNOSEnabledClientIsolation,
    isNOSEnabledWPA3PSK,
    isNOSEnabledWPA3Enterprise,
    isNOSEnabledDynamicVLAN,
    isNOSEnabledOpenMACAuthRadius,
    isNOSEnabledRadiusChangeOfAuthorization,
    isNOSEnabled80211k,
    isNOSEnabled80211v,
  ] = useNosFeaturesEnabled([
    NosFeature.WOS_80211AX,
    NosFeature.WOS_80211R,
    NosFeature.WOS_80211W,
    NosFeature.WOS_CLIENT_ASSOCIATION_STEERING,
    NosFeature.WOS_CLIENT_ISOLATION,
    NosFeature.WOS_WPA3_PSK,
    NosFeature.WOS_WPA3_ENTERPRISE,
    NosFeature.WOS_DYNAMIC_VLAN,
    NosFeature.WOS_OPEN_MAC_AUTH_RADIUS,
    NosFeature.WOS_RADIUS_CHANGE_OF_AUTHORIZATION,
    NosFeature.WOS_80211k,
    NosFeature.WOS_80211v,
  ]);

  const vlans = useGraphQL(vlansQuery, { networkUUID: network.UUID }).data?.vlans;

  const allowedVLANs = useMemo(
    () => vlans?.filter((v) => !v.isInternal).sort((a, b) => a.name.localeCompare(b.name)) ?? [],
    [vlans],
  );
  const defaultVLAN = allowedVLANs.find((v) => v.isDefault);

  const successNavigation = useCallback(
    (uuid: string) =>
      navigate(
        makeDrawerLink(window.location, paths.drawers.SSIDDrawerPage, {
          uuid,
          companyName,
          networkSlug: network.slug,
        }),
      ),
    [navigate, companyName, network.slug],
  );

  const handleSubmit = useCallback(
    ({
      dynamicVLANEnabled,
      hasPassword,
      passwordRotationEnabled,
      frameProtectionEnabled,
      ...inputValues
    }: ValidCreateSSIDParams) => {
      setErrorText(null);

      const input: CreateSsidInput = inputValues;

      // Remove empty string placeholder vlan UUID
      if (!input.vlanUUID) {
        delete input.vlanUUID;
      }

      if (!isNOSEnabled80211ax) {
        delete input.is80211axEnabled;
      }
      if (!isNOSEnabled80211r) {
        delete input.isRoaming80211rEnabled;
      }
      if (!isNOSEnabled80211k) {
        delete input.isRoaming80211kEnabled;
        delete input.clientSteeringPostassociationSteeringIsEnabled;
      } else if (!input.isRoaming80211kEnabled) {
        input.clientSteeringPostassociationSteeringIsEnabled = false;
      }
      if (!isNOSEnabled80211v) {
        delete input.isRoaming80211vEnabled;
      }
      if (!isNOSEnabledClientAssociationSteering) {
        delete input.clientSteeringAssociationSteeringIsEnabled;
      }
      if (!isNOSEnabledDynamicVLAN) {
        delete input.dynamicVLANMode;
      } else {
        if (!dynamicVLANEnabled) {
          input.dynamicVLANMode = null;
        }
        if (dynamicVLANEnabled && input.dynamicVLANMode === SsidDynamicVlanMode.Required) {
          input.vlanUUID = null;
        }
      }
      if (input.encryptionProtocol === SsidEncryptionProtocol.OpenMacAuthRadius) {
        input.isRoaming80211rEnabled = false;
      }

      if (!hasPassword) {
        delete input.encryptionProtocol;
        delete input.password;
        delete input.primaryEncryption8021XUUID;
        delete input.secondaryEncryption8021XUUID;
      }

      if (input.isEnabledForAllAccessPoints) {
        delete input.enabledAccessPointVirtualDeviceUUIDs;
      }

      if (enterpriseEncryptionProtocol(input.encryptionProtocol)) {
        input.password = null;

        if (input.secondaryEncryption8021XUUID === '') {
          input.secondaryEncryption8021XUUID = null;
        }
      } else if (input.encryptionProtocol === SsidEncryptionProtocol.Wpa2) {
        input.primaryEncryption8021XUUID = null;
        input.secondaryEncryption8021XUUID = null;

        if (passwordRotationEnabled) {
          if (input.passwordRotationCadence === SsidPasswordRotationCadence.Daily) {
            delete input.passwordRotationDayOfWeekLocal;
            delete input.passwordRotationDayOfMonthLocal;
          } else if (input.passwordRotationCadence === SsidPasswordRotationCadence.Weekly) {
            delete input.passwordRotationDayOfMonthLocal;
          } else if (input.passwordRotationCadence === SsidPasswordRotationCadence.Monthly) {
            delete input.passwordRotationDayOfWeekLocal;
          }
        } else {
          input.passwordRotationCadence = null;
          delete input.passwordRotationHourOfDayLocal;
          delete input.passwordRotationDayOfWeekLocal;
          delete input.passwordRotationDayOfMonthLocal;
        }
      }

      if (!frameProtectionEnabled || !isNOSEnabled80211w) {
        input.encryption80211wProtectedManagementFramesMode = null;
      }

      if (ssid) {
        const updateInput: UpdateSsidInput = input;
        updateSSIDMutation.mutate(
          { uuid: ssid.UUID, input: updateInput },
          {
            onError: (error: ClientError) => {
              const gqlError = getGraphQLError(error);
              setErrorText(gqlError?.message ?? 'Unknown error');
            },
            onSuccess: (response) => {
              queryClient
                .invalidateQueries(makeQueryKey(SSIDsQuery, { networkUUID: network.UUID }))
                .then(() => {
                  successNavigation(response.updateSSID.UUID);
                });
              closeDrawer();
              notify(`Successfully created SSID "${input.ssid}".`, { variant: 'positive' });
            },
          },
        );
      } else {
        createSSIDMutation.mutate(
          { networkUUID: network.UUID, input },
          {
            onError: (error: ClientError) => {
              const gqlError = getGraphQLError(error);
              setErrorText(gqlError?.message ?? 'Unknown error');
            },
            onSuccess: (response) => {
              queryClient
                .invalidateQueries(makeQueryKey(SSIDsQuery, { networkUUID: network.UUID }))
                .then(() => {
                  successNavigation(response.createSSID.UUID);
                });
              closeDrawer();
              notify(`Successfully created SSID "${input.ssid}".`, { variant: 'positive' });
            },
          },
        );
      }
    },
    [
      closeDrawer,
      isNOSEnabled80211ax,
      isNOSEnabled80211r,
      isNOSEnabled80211w,
      isNOSEnabledClientAssociationSteering,
      isNOSEnabledDynamicVLAN,
      isNOSEnabled80211k,
      isNOSEnabled80211v,
      network,
      queryClient,
      ssid,
      successNavigation,
      createSSIDMutation,
      updateSSIDMutation,
    ],
  );

  return (
    <Drawer>
      <Formik<ValidCreateSSIDParams>
        initialValues={{
          ssid: ssid?.ssid ?? '',
          vlanUUID: ssid?.vlan?.UUID ?? defaultVLAN?.UUID ?? '',
          hasPassword: isDefinedAndNotEmpty(ssid?.encryptionProtocol) ?? true,
          encryptionProtocol: ssid?.encryptionProtocol ?? SsidEncryptionProtocol.Wpa2,
          primaryEncryption8021XUUID: ssid?.primaryEncryption8021X?.UUID ?? undefined,
          secondaryEncryption8021XUUID: ssid?.secondaryEncryption8021X?.UUID ?? undefined,
          password: ssid?.password ?? '',
          clientSteeringAssociationSteeringIsEnabled:
            ssid?.clientSteeringAssociationSteeringIsEnabled ?? false,
          clientSteeringPostassociationSteeringIsEnabled:
            ssid?.clientSteeringPostassociationSteeringIsEnabled ?? false,
          is80211axEnabled: ssid?.is80211axEnabled ?? true,
          isBand2GAllowed: ssid?.isBand2GAllowed ?? true,
          isBand5GAllowed: ssid?.isBand5GAllowed ?? true,
          isEnabled: ssid?.isEnabled ?? false,
          isEnabledForAllAccessPoints: ssid?.isEnabledForAllAccessPoints ?? true,
          isGuest: ssid?.isGuest ?? false,
          isHidden: ssid?.isHidden ?? false,
          isIsolateClientsEnabled: ssid?.isIsolateClientsEnabled ?? false,
          enabledAccessPointVirtualDeviceUUIDs: (ssid?.enabledAccessPointVirtualDevices ?? []).map(
            (ap) => ap.UUID,
          ),
          passwordRotationEnabled: isDefinedAndNotEmpty(ssid?.passwordRotationCadence) ?? false,
          passwordRotationCadence: ssid?.passwordRotationCadence ?? undefined,
          passwordRotationDayOfMonthLocal: ssid?.passwordRotationDayOfMonthLocal ?? undefined,
          passwordRotationDayOfWeekLocal: ssid?.passwordRotationDayOfWeekLocal ?? undefined,
          passwordRotationHourOfDayLocal: ssid?.passwordRotationHourOfDayLocal ?? undefined,
          band2GMinimumBasicRateKbps: ssid?.band2GMinimumBasicRateKbps ?? undefined,
          band5GMinimumBasicRateKbps: ssid?.band5GMinimumBasicRateKbps ?? undefined,
          encryption80211wProtectedManagementFramesMode:
            ssid?.encryption80211wProtectedManagementFramesMode ?? null,
          frameProtectionEnabled: isDefinedAndNotEmpty(
            ssid?.encryption80211wProtectedManagementFramesMode ?? false,
          ),
          isRoaming80211rEnabled: ssid?.roaming80211rIsEnabled ?? false,
          isRoaming80211kEnabled: ssid?.roaming80211kIsEnabled ?? false,
          isRoaming80211vEnabled: ssid?.roaming80211vIsEnabled ?? false,
          dynamicVLANEnabled: isDefinedAndNotEmpty(ssid?.dynamicVLANMode) ?? false,
          dynamicVLANMode: ssid?.dynamicVLANMode ?? SsidDynamicVlanMode.Enabled,
          radiusCoaEnabled: ssid?.radiusCoaEnabled ?? false,
          radiusCoaPort: ssid?.radiusCoaPort ?? null,
        }}
        validate={withZodSchema(createSSIDInputSchema)}
        onSubmit={handleSubmit}
      >
        <Form>
          <DrawerHeader
            icon="ssid"
            heading={ssid ? 'Edit SSID' : 'Add SSID'}
            onClose={closeDrawer}
          />
          <DrawerContent>
            <VStack spacing={space(16)}>
              <EnabledField />
              <SSIDField />
              <VLANField isNOSEnabledDynamicVLAN vlans={allowedVLANs} />
              <PasswordField
                nextRotation={null}
                timezone={timezone}
                isNOSEnabledWPA3PSK={isNOSEnabledWPA3PSK}
                isNOSEnabledWPA3Enterprise={isNOSEnabledWPA3Enterprise}
                isNOSEnabledOpenMACAuthRadius={isNOSEnabledOpenMACAuthRadius}
              />
              <Enable80211axField isNOSEnabled={isNOSEnabled80211ax} />
              <RadiusChangeOfAuthorizationField
                isNOSEnabled={isNOSEnabledRadiusChangeOfAuthorization}
              />
              <FastRoamField isNOSEnabled={isNOSEnabled80211r} />
              <FrameProtectionField isNOSEnabled={isNOSEnabled80211w} />
              <ClientIsolationField isNOSEnabled={isNOSEnabledClientIsolation} />
              <ClientSteeringField isNOSEnabled={isNOSEnabledClientAssociationSteering} />
              <Enable80211kField isNOSEnabled={isNOSEnabled80211k} />
              <Enable80211vField isNOSEnabled={isNOSEnabled80211v} />
              <BandsField />
              <AccessPointsField networkUUID={network.UUID} />
              {errorText && (
                <Alert heading="Error creating SSID" copy={errorText} variant="negative" />
              )}
            </VStack>
          </DrawerContent>
          <DrawerFooter
            actions={
              <>
                <Button type="button" onClick={useCloseDrawerCallback()} variant="secondary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={createSSIDMutation.isLoading}
                  loading={createSSIDMutation.isLoading}
                >
                  Save
                </Button>
              </>
            }
          />
        </Form>
      </Formik>
    </Drawer>
  );
}
