import { useGraphQL } from '@meterup/graphql';

import type { NetworksByFilterQueryQuery, NetworksFilter } from '../../../gql/graphql';
import { graphql } from '../../../gql';

const NetworksByFilterQuery = graphql(`
  query NetworksByFilterQuery($filter: NetworksFilter!) {
    networks(filter: $filter) {
      __typename
      UUID
      label
      slug
      companySlug
      company {
        name
        supportTier
      }
      mailingAddress {
        squareFeet
      }
      virtualDevices {
        hardwareDevice {
          deviceType
          deviceModel
        }
      }
      pinnedNOSVersionID
      pendingNOSVersionID
      lastUpgradedAt
      isUpgradeSensitive
      isActive
    }
  }
`);

export type NetworkForFilter = NetworksByFilterQueryQuery['networks'][number];

export function useNetworksByFilter(filter: NetworksFilter) {
  return useGraphQL(NetworksByFilterQuery, { filter }, { suspense: false });
}
