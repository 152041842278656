import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  Section,
  SectionContent,
  SectionHeader,
  Sections,
  space,
  useDialogState,
} from '@meterup/atto';
import { expectDefinedOrThrow, isDefinedAndNotEmpty, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';

import type { TimePeriod } from '../../../../pages/network/insights/clients';
import {
  ClientHardwareWidget,
  WiredConnectionWidget,
  WirelessConnectionWidget,
  WirelessQualityWidget,
} from '../../../../../components/clients';
import ClientActions from '../../../../../components/Insights/Clients/ClientActions';
import { StickyObjectHeader } from '../../../../../components/Object/ObjectHeader';
import LANPingTestDialog from '../../../../../components/PingTest2/LANPingTestDialog';
import { fallbackAccessPointHardwareDeviceQuery } from '../../../../../components/Wireless/utils';
import { paths } from '../../../../../constants';
import { useClientDataByMAC } from '../../../../../hooks/useClientDataByMAC';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../../../../providers/SearchParamsStateProvider';
import {
  clientNameOrNull,
  getClientConnectionStatus,
  isWired,
  isWireless,
  manufacturerIconName,
} from '../../../../../utils/clientLists';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';

export const Meta = () => ({
  path: '/org/:companyName/network/:networkSlug/insights/clients/:macAddress',
  title: 'Client - Clients - Insights',
});

const DEFAULT_LOOKBACK_MINUTES = 60; // arbitrary

export default function ClientSummary2Page() {
  const { macAddress } = Nav.useRegionParams('drawer', paths.drawers.ClientSummary2Page)!;
  const network = useNetwork();
  const companyName = useCurrentCompany();
  const pingTestDialogProps = useDialogState();

  expectDefinedOrThrow(network, new ResourceNotFoundError('network not found'));
  const [currentTimePeriodOrUndefined] = useSearchParamsState<TimePeriod>('timePeriod');
  const lookbackMinutes = useMemo(() => {
    if (!currentTimePeriodOrUndefined) return DEFAULT_LOOKBACK_MINUTES;

    const num = Number(currentTimePeriodOrUndefined);
    if (Number.isNaN(num) || !Number.isInteger(num)) return DEFAULT_LOOKBACK_MINUTES;

    return num;
  }, [currentTimePeriodOrUndefined]);

  const client = useClientDataByMAC(network, {
    filter: { macAddress, lookbackMinutes, excludeMeterHardware: false },
  });

  expectDefinedOrThrow(client, new ResourceNotFoundError('Client not found'));
  const wos2Device = useGraphQL(
    fallbackAccessPointHardwareDeviceQuery,
    { serialNumber: client.apSerialNumber ?? '' },
    { enabled: isDefinedAndNotEmpty(client.apSerialNumber) },
  ).data?.hardwareDevice;

  return (
    <>
      <Drawer>
        <DrawerHeader
          icon="client"
          heading="Client"
          actions={<ClientActions client={client} view="drawer" />}
          onClose={useCloseDrawerCallback()}
        />
        <DrawerContent gutter="none" spacing={space(0)}>
          <StickyObjectHeader
            icon={manufacturerIconName(client)}
            name={clientNameOrNull(client) ?? 'Unnamed'}
            status={getClientConnectionStatus(client)}
            link={
              isWireless(client) &&
              makeLink(paths.pages.ClientInsightsPage, {
                macAddress: client.macAddress!,
                networkSlug: network.slug,
                companyName,
              })
            }
            cta={isWireless(client) && 'View client'}
          />
          <Sections>
            <Section relation="stacked">
              <SectionHeader heading="Metadata" />
              <SectionContent gutter="all">
                <ClientHardwareWidget client={client} />
              </SectionContent>
            </Section>
            {isWired(client) && (
              <WiredConnectionWidget
                relation="stacked"
                client={client}
                openPingTestDialog={pingTestDialogProps.state.open}
              />
            )}
            {isWireless(client) && (
              <>
                <WirelessConnectionWidget
                  relation="stacked"
                  client={client}
                  virtualDeviceUUID={wos2Device?.virtualDeviceUUID}
                  virtualDeviceLabel={wos2Device?.virtualDevice?.label ?? 'Access point'}
                  openPingTestDialog={pingTestDialogProps.state.open}
                />
                <WirelessQualityWidget relation="stacked" client={client} />
              </>
            )}
          </Sections>
        </DrawerContent>
      </Drawer>
      {client.connectedVLAN && (
        <LANPingTestDialog
          state={pingTestDialogProps.state}
          hostIp={client.ip}
          hostName={clientNameOrNull(client) ?? '-'}
          vlan={client.connectedVLAN}
        />
      )}
    </>
  );
}
